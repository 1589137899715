import UserContext from 'contexts/UserContext';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/logoApp.png';
import ForgetPasswordcode from './ForgetPasswordcode';
import ForgetPasswordmail from './ForgetPasswordmail';
import ForgetPasswordPsw from './ForgetPasswordPsw';

export default function ForgetPasswordmain() {
  const [pageNumber, setPageNumber] = useState(0);
  const [email, setEmail] = useState('');
  const { user } = useContext(UserContext);
  const [idUser, setidUser] = useState<string>();
  if (user?.id) return <Redirect to="/overview" />;
  return (
    <div>
      <div className="z-10 absolute w-[100%] h-[100vh] bg-white flex flex-col items-center justify-center">
        <div className="m-8">
          <img src={logo} alt="logo" className="w-14" />
        </div>
        <div className="border-gray-300 border-2 bg-gray-100 drop-shadow-md z-10 w-[470px] 2xl:h-[420px] h-[470px] p-8 rounded-xl flex flex-col justify-center">
          {[
            <ForgetPasswordmail
              nextPage={(mail: string) => {
                setEmail(mail);
                setPageNumber(1);
              }}
            />,
            <ForgetPasswordcode
              setidUser={setidUser}
              email={email}
              nextPage={() => {
                setPageNumber(2);
              }}
            />,
            <ForgetPasswordPsw idUser={idUser || ''} />,
          ].map((el, key) => key === pageNumber && el)}
        </div>
        <p className="mt-8 text-center font-PopinsMedium font-light">
          Do you have any questions,feedback or issues? <br />
          Please <span className="font-bold">Contact us</span>
        </p>
      </div>
    </div>
  );
}
