import { IconType } from 'react-icons/lib';

interface Props {
  Icon: IconType;
  text: string;
  title: string;
  className: string;
}

export default function RatingList({ Icon, text, title, className }: Props) {
  return (
    <div className="w-1/2">
      <div className="flex items-center xl:flex-col">
        <div className={`${className} xl:hidden flex items-center mr-4 w-8 h-8 p-2 justify-center rounded-2xl`}>
          <Icon />
        </div>
        <div className="flex flex-col gap-4 xl:items-center">
          <h1 className="font-PopinsMedium font-semibold text-sm text-gray-500 xl:flex">
            <div className={`${className}  hidden xl:flex items-center mr-4 w-8 h-8 p-2 justify-center rounded-2xl`}>
              <Icon />
            </div>
            {title}
          </h1>
          <p className=" text-4xl font-semibold font-PopinsMedium">{text}</p>
        </div>
      </div>
    </div>
  );
}
