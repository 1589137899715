import Button from 'components/form/Button';
import Password from 'components/icons/Password';
import Input from 'components/ui/Inputs/Input';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
// import Input from 'components/ux/Input';
import { useResetPassword, useVerifToken } from 'requests/auth';
import { useUpdateUser } from 'requests/Users/request';
import { decodeUri } from 'utils/url';
import * as Yup from 'yup';

const RenewPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmepassword: '',
    },
    initialErrors: {
      password: 'Les deux mot de passe doit etre identique',
      confirmepassword: 'Les deux mot de passe doit etre identique',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Votre adresse e-mail doit être valide (exemple@domain.com)')
        .required('champ obligatoire'),
      password: Yup.string().required('champ obligatoir'),
      confirmepassword: Yup.string().required('champ obligatoir'),
    }),
    onSubmit: () => {
      // console.log({ values });
    },
  });

  useEffect(() => {
    if (formik.values.password !== formik?.values?.confirmepassword) {
      formik.setErrors({
        password: 'Les deux mot de passe doit etre identique',
        confirmepassword: 'Les deux mot de passe doit etre identique',
      });
    }
  });

  const history = useHistory();
  const { search } = useLocation();
  const query = decodeUri(search);
  const [verifToken] = useVerifToken();
  const [user, setuser] = useState<{ id: string; first_name: string; last_name: string }>();

  // const [updateUser] = useUpdateOwner({ fetchPolicy: 'no-cache' });
  const [expired, setexpired] = useState(false);
  const [resetPassword] = useResetPassword();

  const [updateUser] = useUpdateUser();

  const renewPassword = () => {
    if (user?.id && formik.values.password === formik?.values?.confirmepassword) {
      updateUser({
        variables: { id: user?.id, password: formik.values.password },
      }).then(({ data }) => {
        if (data?.updateUser?.id) {
          toast.success('Mise a jour avec succès');
        } else {
          toast.error('Erreur de mise a jour');
        }
      });
    }
  };

  useEffect(() => {
    if (query.t) {
      const token: any = query.t!;
      if (token) {
        verifToken({ variables: { token } }).then(({ data }) => {
          if (data?.verifToken?.id) {
            setuser(data?.verifToken);
          } else {
            setexpired(true);
            toast.error('Session Expirée');
          }
        });
      }
    }
  }, []);

  return (
    <form onSubmit={formik?.handleSubmit}>
      <div className=" w-full h-screen m-auto flex flex-col justify-center items-center -ml-10 ">
        <p className="w-auto text-34 font-CalibreSemiBold">
          Bonjour {user?.first_name} {user?.last_name}
        </p>
        <div className="flex flex-col justify-center items-center min-h-290">
          {expired && (
            <div className="text-blue text-30 font-CalibreSemiBold mb-16  lg:text-3xl	md:text-22">
              Votre session est expirés .
            </div>
          )}
          {!expired && (
            <>
              <div className="text-blue text-30 font-CalibreSemiBold mb-16  lg:text-3xl	md:text-22">
                Saisir un nouveau mot de passe
              </div>
              <Input
                placeholder="Ecrivez votre nouveau mot de passe"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="password"
                type="password"
                // IconShowPassword
                error={formik.errors.password}
                iconLeft={<Password width="15px" fill="#0B0329" />}
                handleError
              />
              <Input
                placeholder="Confirmez votre nouveau mot de passe"
                value={formik.values.confirmepassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="confirmepassword"
                type="password"
                // IconShowPassword
                error={formik.errors.password}
                iconLeft={<Password width="15px" fill="#0B0329" />}
                handleError
              />
            </>
          )}
          <Button
            onClick={() => {
              if (expired) {
                const token: any = query.t!;
                resetPassword({ variables: { token } }).then(() => {
                  toast.success('Verifier Votre Email');
                  history.push('/login');
                });
              } else {
                renewPassword();
              }
            }}
            className="text-26 lg:text-22 text-grey font-CalibreSemiBold bg-black text-white lg:mt-0 w-full md:h-12 md:text-lg"
          >
            {' '}
            {expired ? 'Renvoyer Email' : 'Confirmer'}
          </Button>
        </div>
      </div>
    </form>
  );
};
export default RenewPassword;
