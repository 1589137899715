import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import OnboardingSteps from 'components/ui/Pagination/OnboardingSteps';
import { useFormik } from 'formik';
import React from 'react';
import { AiOutlineBarChart } from 'react-icons/ai';
import { CgBriefcase } from 'react-icons/cg';
import { HiOutlineHashtag } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { RiBankLine } from 'react-icons/ri';
import * as Yup from 'yup';

interface Form {
  structure: string;
  tax: string;
  iban: string;
  businessName: string;
  businessAdress: string;
}

interface Props {
  setNext: (obj: any) => void;
  setPageNumber: (x: number) => void;
  form: Form;
}

export default function OnboardingPage3Layout({ setNext, form, setPageNumber }: Props) {
  const formik = useFormik({
    initialValues: {
      structure: form.structure,
      tax: form.tax,
      iban: form.iban,
      businessName: form.businessName,
      businessAdress: form.businessAdress,
    },
    validationSchema: Yup.object({
      structure: Yup.string().required('This field is required'),
      tax: Yup.string().required('This field is required'),
      iban: Yup.string().required('This field is required'),
      businessName: Yup.string().required('This field is required'),
      businessAdress: Yup.string().required('This field is required'),
    }),
    onSubmit: (values) => {
      setNext(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center mb-8">
        <OnboardingSteps setPageNumber={setPageNumber} pageNumber={1} title="Business info" />
        <div className="grid grid-rows-3 grid-cols-2 2xl:w-[80%] w-[70%] gap-x-8 gap-y-4 mb-6 md:flex md:flex-col md:h-[40vh] md:overflow-y-scroll md:overflow-x-hidden">
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.structure && formik.touched?.structure ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your Business structure"
              type="text"
              label="Business structure"
              Icon={AiOutlineBarChart}
              onChange={formik.handleChange}
              value={formik.values.structure}
              id="structure"
              error={formik.errors?.structure}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.businessName && formik.touched?.businessName ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your Legal business name"
              type="text"
              label="Legal business name"
              Icon={CgBriefcase}
              onChange={formik.handleChange}
              value={formik.values.businessName}
              id="businessName"
              error={formik.errors?.businessName}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${formik.errors?.tax && formik.touched?.tax ? 'border-red-500' : 'border-gray-300'}`}
              onFocusClassName="border-black"
              placeholder="Enter your Tax id number"
              type="number"
              label="Tax id number"
              Icon={HiOutlineHashtag}
              onChange={formik.handleChange}
              value={formik.values.tax}
              id="tax"
              error={formik.errors?.tax}
            />
          </div>

          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.businessAdress && formik.touched?.businessAdress ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your Registered adress"
              type="text"
              label="Registered adress"
              Icon={IoLocationOutline}
              onChange={formik.handleChange}
              value={formik.values.businessAdress}
              id="businessAdress"
              error={formik.errors?.businessAdress}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.iban && formik.touched?.iban ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your IBAN"
              type="number"
              label="IBAN to which we should wire the payment"
              Icon={RiBankLine}
              onChange={formik.handleChange}
              value={formik.values.iban}
              id="iban"
              error={formik.errors?.iban}
            />
          </div>
        </div>
        <OnboardingButton type="submit" className="w-[30%] h-[40px]">
          Next
        </OnboardingButton>
      </div>
    </form>
  );
}
