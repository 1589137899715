import Button from 'components/form/Button';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetTable } from 'requests/Tables/request';
import CardTable from './CardTable';

export default function TableList() {
  const { idPOS } = useParams<any>();
  const history = useHistory();
  const { data } = useGetTable({ variables: { idPointSale: idPOS || '' } });

  return (
    <div>
      <div className="flex flex-row-reverse justify-between w-full items-center px-10">
        Point sale id : {idPOS}
        <Button className="text-black" onClick={() => history.goBack()}>
          Return
        </Button>
      </div>
      <div>
        <div className="grid grid-cols-3 gap-3 p-2 ">
          {data?.Tables?.data?.map(({ id, name, qr_code, status }) => (
            <CardTable idPOS={id} logo={qr_code} name={name} status={status} />
          ))}
        </div>
      </div>
    </div>
  );
}
