import OrderMaker from 'components/ui/CardCaisse/OrderMaker';
import TableList from 'components/ui/CardCaisse/TableList';
import Route from 'components/ui/Route/Route';
import OnboardingMainLayout from 'containers/Onboarding/OnboardingMainLayout';
import PaymentLayout from 'containers/Payment/PaymentLayout';
import RestaurantDetails from 'containers/RestaurantDetails/RestaurantDetails';
import TicketLayout from 'containers/Ticket/TicketLayout';
import UserContext from 'contexts/UserContext';
import { NotificationContext, NotificationsContext } from 'contexts/NotificationContext';
import { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { User } from 'requests/types';
import startup from 'utils/startup';
import io from 'socket.io-client';
import SocketContext from 'contexts/scoketContext';
import Accounting from './Accounting/Accounting';
import AppDesign from './AppDesign/AppDesign';
import Caisse from './Caisse/Caisse';
import ContactUs from './ContactUs/ContactUs';
import EmptyPage from './EmptyPage/EmptyPage';
import ForgetPasswordmain from './ForgetPassword/ForgetPasswordmain';
import LoginContainer from './Login/LoginContainer';
import MenuList from './Menu/MenuList';
import MyRestaurant from './MyRestaurant/MyRestaurant';
import NotFound from './NotFound/NotFound';
import OurContact from './OurContact/OurContact';
import Overview from './Overview/Overview';
import PosInfo from './POS/PosInfo';
import Profile from './Profile/Profile';
import QRcodes from './QR-codes/QRcodes';
import Rating from './Rating/Rating';
import RenewPassword from './RenewPassword/RenewPassword';
import Team from './Team/Team';
import Waiters from './Waiters/Waiters';

const RootContainer = () => {
  const [user, setUser] = useState(null as User | null);
  const [startupEnded, setStartupEnded] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [, setLastOrderCar] = useState('');
  const [socket, setSocket] = useState(null as any | null);
  const [notifications, setNotifications] = useState([] as any | null);
  const [notification, setNotification] = useState(null as any | null);
  const [newNotification, setNewNotification] = useState(false);
  const location = useLocation();
  useEffect(() => {
    startup().then((u) => {
      setUser(u);
      setStartupEnded(true);
    });
  }, []);
  useEffect(() => {
    if (!user) {
      return;
    }
    const socketObject = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3005', {
      path: '/rooms',
      query: { userId: user.id },
    });
    setSocket(socketObject);
    setNotification({ type: 'success', message: 'Connected to the server', isRead: true });
    socketObject.on('connect', () => {
      setIsConnected(true);
    });

    socketObject.on('disconnect', () => {
      setIsConnected(false);
    });

    socketObject.on('order_car', () => {
      setLastOrderCar(new Date().toISOString());
    });
    socketObject.on('pay_cash', (payload: any) => {
      console.log('pay cash');
      console.log(payload);
      setNotification({ ...payload });
      setNewNotification(true);
    });
    socketObject.on('car_ordered', (payload: any) => {
      setNotification({ ...payload });
      setNewNotification(true);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      socketObject.off('connect');
      socketObject.off('disconnect');
      socketObject.off('pong');
      socketObject.off('car_ordered');
      socketObject.off('pay_cash');
    };
  }, [isConnected, user]);

  useEffect(() => {
    if (newNotification) {
      setNotifications([{ ...notification }, ...notifications]);
      setNewNotification(false);
    }
  }, [notification && newNotification]);

  useEffect(() => {
    document.title = `Quickbill - ${location.pathname.replace('/', ' ').replace('-', ' ')}`;
  }, [location]);

  if (!startupEnded) return <div />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <SocketContext.Provider value={{ socket, setSocket }}>
        <NotificationContext.Provider value={{ notification, setNotification }}>
          <NotificationsContext.Provider value={{ notifications, setNotifications }}>
            {' '}
            <ToastContainer />
            <Switch>
              <Route dashboardLayout path="/" exact protected component={Overview} />

              <Route path="/caisse" exact protected component={Caisse} />
              <Route path="/tables/:idPOS" exact protected component={TableList} />
              <Route path="/orderMaker/:idTable" exact protected component={OrderMaker} />

              <Route header={false} path="/login" exact component={LoginContainer} />
              <Route header={false} path="/forgetpassword" exact component={ForgetPasswordmain} />
              <Route header={false} path="/RenewPassword" exact component={RenewPassword} />
              <Route dashboardLayout path="/emptypage" exact component={EmptyPage} />
              <Route header={false} path="/contactus" exact component={ContactUs} />
              <Route exact path="/onboarding" firstcnx component={OnboardingMainLayout} />
              <Route dashboardLayout protected path="/ticket" exact component={TicketLayout} />
              <Route dashboardLayout protected path="/payment" exact component={PaymentLayout} />
              <Route dashboardLayout protected path="/menus" exact component={MenuList} />
              <Route dashboardLayout protected path="/my-staff" exact component={Waiters} />
              <Route dashboardLayout protected path="/restaurant-details" exact component={RestaurantDetails} />
              <Route dashboardLayout protected path="/menu" exact component={MenuList} />
              <Route dashboardLayout protected path="/app-design" exact component={AppDesign} />
              <Route dashboardLayout protected path="/QR-codes" exact component={QRcodes} />
              <Route dashboardLayout protected path="/POS" exact component={PosInfo} />
              <Route dashboardLayout protected path="/rating" exact component={Rating} />
              <Route dashboardLayout protected path="/accounting" exact component={Accounting} />
              <Route dashboardLayout protected path="/overview" exact component={Overview} />
              <Route dashboardLayout protected path="/team" exact component={Team} />
              <Route dashboardLayout protected path="/myrestaurant" exact component={MyRestaurant} />
              <Route dashboardLayout protected path="/ourcontact" exact component={OurContact} />
              <Route dashboardLayout protected path="/profile" exact component={Profile} />
              <Route dashboardLayout protected path="/notfound" exact component={NotFound} />
            </Switch>
          </NotificationsContext.Provider>
        </NotificationContext.Provider>
      </SocketContext.Provider>
    </UserContext.Provider>
  );
};

export default RootContainer;
