import Button from 'components/form/Button';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddOrder, useGetMenuItems } from 'requests/MenuItems/request';
import { MenuItem } from 'requests/MenuItems/types';
import { useGetOrderByTable } from 'requests/Tickets/request';

// const ITEMS = [
//   { id: '1', label: 'Burger', price: '30$' },
//   { id: '2', label: 'Pizza', price: '50$' },
//   { id: '3', label: 'Salade', price: '20$' },
//   { id: '4', label: 'Fries', price: '10$' },
//   { id: '5', label: 'Spaghetti', price: '70$' },
// ];

export default function OrderMaker() {
  const { idTable } = useParams<any>();
  const [orders, setorders] = useState<MenuItem[]>([]);
  const [TableStatus, setTableStatus] = useState('CLOSED');
  const history = useHistory();

  const { data } = useGetMenuItems();
  const [MakeOrder] = useAddOrder();
  const { data: Ticketitems, refetch } = useGetOrderByTable({
    variables: { table_id: idTable, status: 'opened' },
    fetchPolicy: 'no-cache',
    skip: !idTable,
  });

  useEffect(() => {
    setorders(Ticketitems?.getOrderByTable?.items_id.map((e) => ({ ...e, qte: e.quantity, id: e?.item_id?.id })) || []);
    setTableStatus(Ticketitems?.getOrderByTable?.status || '');
  }, [Ticketitems]);

  const handleOrder = () => {
    const order = {
      orders: orders.map((e: any) => ({
        quantity: e.qte,
        price: parseInt(e.price, 10),
        label: e?.label,
        image: e?.image,
        MenuItem: e?.id,
      })),
      table_id: idTable,
    };

    MakeOrder({ variables: order }).then((result) => {
      if (result.errors) {
        toast.error(result.errors);
      } else {
        toast.success(result?.data?.addItemToTicket);
        refetch();
        setTableStatus('opened');

        // setorders([]);
      }
    });
  };

  const handleStatus = () => {
    MakeOrder({ variables: { status: 'CLOSED', table_id: idTable } }).then((result) => {
      if (result.errors) {
        toast.error(result.errors);
      } else {
        toast.success(result?.data?.addItemToTicket);
        setTableStatus('CLOSED');
        setorders([]);
      }
    });
  };

  const handleAdd = (item: any) => {
    if (orders.find((e: any) => e.id === item.id)) {
      setorders((old: any) => old.map((c: any) => ({ ...c, qte: c.id === item.id ? c.qte + 1 : c.qte })));
    } else {
      setorders((old: any) => [{ ...item, qte: 1 }, ...old]);
    }
  };

  const handleRemove = (id: any) => {
    if (orders.find((e: any) => e.id === id && e.qte > 1)) {
      setorders((old: any) => old.map((c: any) => ({ ...c, qte: c.id === id ? c.qte - 1 : c.qte })));
    } else {
      setorders((old: any) => old.filter((current: any) => current.id !== id));
    }
  };

  return (
    <div className="w-full h-[100vh] flex flex-col justify-center">
      <div className="flex h-[500px]  gap-4 py-4 mx-2 ">
        <div className="flex-[0.3] overflow-y-auto border-2 border-gray-400">
          {data?.MenuItems?.data.map((item) => (
            <div className="flex items-center justify-between px-10 p-5 gap-4 shadow-sm">
              <img alt="dfd" src={item?.image} className=" w-20 rounded-md h-20" />
              <p className="flex-1">{item?.label}</p>
              <p className="flex-1">{item?.price}</p>
              <div
                onClick={() => handleAdd(item)}
                className="w-[50px] h-[50px] flex items-center justify-center rounded-md bg-green-600 hover:scale-110 cursor-pointer "
              >
                <MdAdd className="text-3xl  text-white " />
              </div>
            </div>
          ))}
        </div>
        <div className="flex-[0.7] flex flex-col justify-between border-2 border-gray-400">
          <div className="flex-[0.9] overflow-y-auto">
            <ul>
              {orders.map((e: any) => (
                <li className="flex items-center justify-between p-6 shadow-sm">
                  <div className="flex items-center gap-4">
                    <p className="flex gap-2">
                      <span>X</span>
                      {e.qte}
                    </p>
                    <p>{e?.label}</p>
                  </div>
                  <div className="flex items-center gap-4">
                    <p>{parseInt(e?.price, 10) * parseInt(e.qte, 10)} $</p>
                    <div
                      onClick={() => handleRemove(e?.id)}
                      className="w-[50px] h-[50px] flex items-center justify-center rounded-md bg-red-600 hover:scale-110 cursor-pointer "
                    >
                      <MdRemove className="text-3xl text-white " />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-[0.1] flex justify-between items-center py-4 px-10 text-3xl">
            <div className="flex items-center gap-2">
              <p className="text-sm">
                Table id : <span className="text-black font-extrabold">{idTable}</span>
              </p>
              {TableStatus && (
                <>
                  {TableStatus === 'opened' ? (
                    <p className="text-xs px-2 py-1 bg-yellow-400 text-white rounded-3xl">{TableStatus}</p>
                  ) : (
                    <p className="text-xs px-2 py-1 bg-red-400 text-white rounded-3xl">{TableStatus}</p>
                  )}
                </>
              )}{' '}
            </div>
            <p>
              TOTAL :{' '}
              <span className="text-black font-extrabold">
                {orders.reduce((t: any, e: any) => t + parseInt(e.price, 10) * e?.qte, 0)} $
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 absolute bottom-6 h-[50px] overflow-hidden right-4 ">
        <Button onClick={() => history.goBack()} className="text-black  hover:underline">
          Return
        </Button>
        <div className={`w-[0px] duration-700 overflow-hidden ${TableStatus === 'opened' ? '!w-[141px] ' : ''}`}>
          <Button
            disabled={TableStatus !== 'opened'}
            onClick={handleStatus}
            className={`text-white cursor-pointer duration-300 bg-red-700 border-2 border-red-700 hover:border-red-700 hover:text-red-700 hover:bg-white overflow-hidden `}
          >
            Close Table
          </Button>
        </div>
        <Button
          disabled={!orders.length}
          onClick={handleOrder}
          className={`text-white cursor-pointer duration-300 ${
            TableStatus === 'opened'
              ? 'bg-yellow-400 border-2 border-yellow-400 hover:border-yellow-400 hover:text-yellow-400'
              : 'bg-green-700 border-2 border-green-700 hover:border-green-700 hover:text-green-700'
          } hover:bg-white`}
        >
          {TableStatus === 'opened' ? 'Update Order' : 'Confirm Order'}
        </Button>
      </div>
    </div>
  );
}
