import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import Modal from 'components/ui/Modal/Modal';
import UserContext from 'contexts/UserContext';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { MdOutlineDescription, MdOutlineFormatListBulleted } from 'react-icons/md';
import { useCreateMenu } from 'requests/Menus/request';
import * as Yup from 'yup';

interface Props {
  show: boolean;
  setShow: (v: boolean) => void;
  refetch: () => void;
}

export default function ModalCreateMenu({ show, setShow, refetch }: Props) {
  const [createMenu, { loading }] = useCreateMenu();
  const { user } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      description: '',
      category: '',
      image: '',
      visible: false,
      pointSale_id: user?.idPOS,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      description: Yup.string().required('Description required'),
      category: Yup.string().required('Category required'),
      image: Yup.string().required('image required'),
    }),
    onSubmit: (values) => {
      createMenu({ variables: values }).then(({ data }) => {
        if (data?.createMenu?.id) {
          setShow(false);
          formik?.resetForm();
          refetch();
        }
      });
    },
  });

  const handleCancel = () => {
    setShow(false);
    formik?.resetForm();
  };
  return (
    <div>
      <Modal onClose={handleCancel} open={show}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div>
              <h1 className="text-2xl">Add a new menu</h1>
            </div>
            <div className="flex flex-col gap-4 my-4">
              <InputBtn
                className={`border-2 ${
                  formik.errors?.description && formik.touched?.description ? 'border-red-500' : 'border-gray-300'
                }`}
                error={formik.errors.description}
                onFocusClassName="border-black"
                placeholder="Enter your Description"
                type="text"
                label="Description"
                Icon={MdOutlineDescription}
                onChange={formik.handleChange}
                value={formik.values.description}
                id="description"
              />
              <InputBtn
                className={`border-2 ${
                  formik.errors?.category && formik.touched?.category ? 'border-red-500' : 'border-gray-300'
                }`}
                onFocusClassName="border-black"
                placeholder="Choose your category"
                type="text"
                error={formik.errors.category}
                label="Category"
                Icon={MdOutlineFormatListBulleted}
                onChange={formik.handleChange}
                value={formik.values.category}
                id="category"
              />
              <div>
                <OnboadringUploadBtn
                  accept=".jpg,.pdf"
                  placeholder="Upload your menu"
                  text={
                    formik.values.image !== undefined
                      ? (formik.values.image as any)?.filename
                      : "The file must be JPG or PDF and don't exeed 5MO"
                  }
                  onChange={(event) => formik?.setFieldValue(event?.target?.id, event?.target?.files[0])}
                  id="image"
                  className={formik?.errors?.image && formik?.touched?.image ? 'border-red-500' : ''}
                />
                {formik?.errors?.image && formik.touched?.image ? (
                  <p className="fixed text-xs mx-2 font-PopinsMedium  text-red-500">{formik.errors?.image}</p>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end">
              <CustomBtn onClick={handleCancel} type="button" text="Cancel" />
              <CustomBtn
                loading={loading}
                type="submit"
                text="Add"
                loadingColor="white"
                className="!bg-warmGray-800 !text-white hover:border-none"
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
