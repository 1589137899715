import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import MenuGuides from 'components/ui/MenusGuide/MenuGuides';
import Title from 'components/ui/Text/Title';
import { AiOutlineCloudDownload, AiOutlineLineChart, AiOutlineStar } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { useGetMenus } from 'requests/Menus/request';
import { useTranslation } from 'react-i18next';
import { useGetTickets } from 'requests/Tickets/request';
import { useContext } from 'react';
import UserContext from 'contexts/UserContext';

export default function Accounting() {
  const { user } = useContext(UserContext);

  const { data } = useGetMenus();
  const { data: tickets, loading } = useGetTickets({
    fetchPolicy: 'no-cache',
    variables: { id_pointSale: user?.idPOS, status: 'CLOSED' },
    skip: !user?.idPOS,
  });
  const CurrencyFormatter = new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'AED' });
  const numRating = tickets?.Orders?.data?.length || 1;
  const sum = tickets?.Orders?.data.reduce((t, r) => t + r?.rating, 0) || 0;
  const grossRevenue = tickets?.Orders?.data.reduce((t, r) => t + Number(r?.total_amount), 0) || 0;
  const taxes = grossRevenue * 0.05;
  const tips = tickets?.Orders?.data.reduce((t, r) => t + Number(r?.tip_amount), 0) || 0;
  const { t } = useTranslation();

  const ACCOUNTING_LIST = [
    {
      Icon: AiOutlineLineChart,
      title: t('accounting.section1.kpis.KPI_4'),
      text: `${(sum / numRating).toFixed(2)}/5`,
      className: 'bg-blueLight',
    },
    {
      Icon: AiOutlineStar,
      title: t('accounting.section1.kpis.KPI_5'),
      text: `${numRating}`,
      className: 'bg-[#FFBC9A]',
    },
    {
      Icon: FiUsers,
      title: t('accounting.section1.kpis.KPI_6'),
      text: `${numRating}`,
      className: 'bg-[#CABDFF]',
    },
  ];

  return (
    <div className="m-8">
      <p className="text-warmGray-800 text-2xl">{t('accounting.pageTitle')}</p>

      <div className="flex flex-col gap-6">
        <Block className="mt-4">
          <div className="flex justify-between">
            <Title title="Revenues" className="before:bg-pink" />
            <div className="flex">buttons placement</div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex justify-around bg-gray-100 rounded-xl p-8 xl:flex-col xl:items-center xl:gap-4">
              <div className="flex flex-col text-center">
                <p className="text-gray-400 font-semibold">{t('accounting.section1.kpis.KPI_1')}</p>
                <h1 className="font-semibold text-4xl">{CurrencyFormatter.format(grossRevenue)}</h1>
              </div>
              <div className="flex flex-col text-center">
                <p className="text-gray-400 font-semibold">{t('accounting.section1.kpis.KPI_2')}</p>
                <h1 className="font-semibold text-4xl">{CurrencyFormatter.format(taxes)}</h1>
              </div>
              <div className="flex flex-col text-center">
                <p className="text-gray-400 font-semibold">{t('accounting.section1.kpis.KPI_3')}</p>
                <h1 className="font-semibold text-4xl">{CurrencyFormatter.format(tips)}</h1>
              </div>
            </div>
            <div className="flex gap-10 justify-around xl:flex-col  xl:items-center xl:gap-4">
              {loading ? '...' : ACCOUNTING_LIST.map((items) => <MenuGuides key={items.text} {...items} />)}
            </div>
          </div>
        </Block>
        <Block>
          <div>
            <div className="flex justify-between items-center">
              <Title title="List of transfers" className="before:bg-[#CABDFF]" />
              <CustomBtn
                text={t('accounting.section2.button').toString()}
                className="!p-4"
                Icon={AiOutlineCloudDownload}
              />
            </div>
            <List<any>
              canUpdate={false}
              canCreate={false}
              canDelete={false}
              totalPages={0}
              onPageChange={() => {}}
              currentPage={Number(/* search.page */) || 1}
              data={data?.Menus.data || []}
              headers={[
                {
                  title: t('accounting.section2.table.column1').toString(),
                  dataIndex: '',
                  key: 'amount',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('accounting.section2.table.column2').toString(),
                  dataIndex: '',
                  key: 'status',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('accounting.section2.table.column3').toString(),
                  dataIndex: '',
                  key: 'date',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
              ]}
            />
          </div>
        </Block>
      </div>
    </div>
  );
}
