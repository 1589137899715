import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import DatePickerBtn from 'components/ui/Buttons/DatePickerBtn';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import moment from 'moment';
import { useContext, useState } from 'react';
import { AiOutlineStar } from 'react-icons/ai';
import { BiLineChart } from 'react-icons/bi';
import { useGetTickets } from 'requests/Tickets/request';
import { useTranslation } from 'react-i18next';
import RatingList from './RatingList';

export default function Rating() {
  const [date, setDate] = useState<Date | undefined>();
  const { user } = useContext(UserContext);
  const { data: tickets, loading } = useGetTickets({
    fetchPolicy: 'no-cache',
    variables: { id_pointSale: user?.idPOS, status: 'CLOSED' },
    skip: !user?.idPOS,
  });

  const numRating = tickets?.Orders?.data?.length || 1;
  const sum = tickets?.Orders?.data.reduce((t, r) => t + r?.rating, 0) || 0;
  const { t } = useTranslation();
  const RATING_NUMBERS = [
    {
      Icon: BiLineChart,
      title: t('rating.section1.kpis.KPI_1'),
      text: `${(sum / numRating).toFixed(2)}/5`,
      className: 'bg-pink',
    },
    {
      Icon: AiOutlineStar,
      title: t('rating.section1.kpis.KPI_2'),
      text: `${numRating}`,
      className: 'bg-purple',
    },
  ];

  return (
    <div className="m-8">
      <p className="text-warmGray-800 text-2xl">{t('rating.pageTitle')}</p>
      <div className="flex flex-col gap-6">
        <Block className="mt-4">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <Title title={t('rating.section1.title')} className="before:bg-[#b1e5fe]" />
              <DatePickerBtn placeholder={t('rating.filters.item1').toString()} date={date} setDate={setDate} />
            </div>
            <div className="bg-gray-200 border rounded-xl flex justify-around p-8">
              {RATING_NUMBERS.map((items) => {
                return <RatingList key={items.title} {...items} />;
              })}
            </div>
          </div>
        </Block>
        <Block>
          <div>
            <Title title={t('rating.section2.title')} className="before:bg-orange mb-4" />
            <List<any>
              loading={loading}
              canUpdate={false}
              canCreate={false}
              canDelete={false}
              totalPages={0}
              onPageChange={() => {}}
              currentPage={Number(/* search.page */) || 1}
              data={tickets?.Orders?.data || []}
              headers={[
                {
                  title: t('rating.section2.table.column1').toString(),
                  dataIndex: 'id',
                  key: 'id',
                  render: (value, { id }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`#${id.slice(
                      id.length - 6,
                    )}`}</p>
                  ),
                },
                {
                  title: t('rating.section2.table.column2').toString(),
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (_, { createdAt }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`${moment(
                      createdAt,
                    ).format('ddd, MMMM Do - h:mm a')}`}</p>
                  ),
                },
                {
                  title: t('rating.section2.table.column3').toString(),
                  dataIndex: 'waiter_id',
                  key: 'waiter_id',
                  render: (_, { waiter_id }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                      {waiter_id?.first_name || '-----'}
                    </p>
                  ),
                },
                {
                  title: t('rating.section2.table.column4').toString(),
                  dataIndex: 'tip_amount',
                  key: 'tip_amount',
                  render: (value, { tip_amount }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{tip_amount || 0} $</p>
                  ),
                },
                {
                  title: t('rating.section2.table.column5').toString(),
                  dataIndex: 'comment',
                  key: 'comment',
                  render: (_, { comment }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{comment}</p>
                  ),
                },
                {
                  title: t('rating.section2.table.column6').toString(),
                  dataIndex: 'rating',
                  key: 'rating',
                  render: (value, { rating }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden flex items-center">
                      {[...new Array(rating)].map(() => (
                        <AiOutlineStar fill="gold" />
                      ))}
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </Block>
      </div>
    </div>
  );
}
