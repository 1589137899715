import { IconType } from 'react-icons/lib';

interface Props {
  placeholder: string;
  Icon: IconType;
  setsearch?: (v: string) => void;
}

export default function Search({ placeholder, Icon, setsearch }: Props) {
  return (
    <div className="relative">
      <Icon className="absolute top-[15px] left-4 cursor-pointer" />
      <input
        onChange={(e) => setsearch && setsearch(e.target.value)}
        className="bg-[#8080801e] rounded-xl h-12 pl-10 focus:border-none placeholder-black outline-none"
        placeholder={placeholder}
      />
    </div>
  );
}
Search.defaultProps = {
  setsearch: () => {},
};
