import { QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalQuery } from 'hooks/apollo';
import { Ticket } from './types';

export const useGetTickets = (options: QueryHookOptions<{ Orders: { data: Ticket[] } }, {}> = {}) => {
  const GET_TICKETS_QUERY = gql`
    query Orders($id_pointSale: ID, $status: String) {
      Orders(id_pointSale: $id_pointSale, status: $status) {
        data {
          id
          total_amount
          total_amount_payed
          tip_amount
          status
          rating
          comment
          table_id {
            id
          }
          createdAt
          items_id {
            item_id {
              id
            }
            quantity
            label
            price
            image
          }
          waiter_id {
            id
            first_name
            last_name
          }
        }
      }
    }
  `;

  return useLocalQuery(GET_TICKETS_QUERY, options);
};

export const useGetOrderByTable = (
  options: QueryHookOptions<{ getOrderByTable: Ticket }, { table_id: string; status?: string }> = {},
) => {
  const GET_TICKETS_QUERY = gql`
    query getOrderByTable($table_id: ID, $status: String) {
      getOrderByTable(table_id: $table_id, status: $status) {
        id
        status
        tip_amount
        table_id {
          id
          status
        }
        items_id {
          item_id {
            id
          }
          quantity
          label
          price
          image
        }
      }
    }
  `;

  return useLocalQuery(GET_TICKETS_QUERY, options);
};
