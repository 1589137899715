import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export default function BlockGray({ children, className }: Props) {
  return <div className={`mt-6 bg-gray-100 p-8 relative rounded-xl ${className} `}>{children}</div>;
}

BlockGray.defaultProps = {
  className: '',
};
