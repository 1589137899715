import { IconType } from 'react-icons/lib';
import Loading from '../Loading';

interface Props {
  Icon?: IconType;
  text?: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;
  loadingColor?: string;
}

export default function CustomBtn({
  Icon,
  loading,
  text,
  onClick,
  className,
  type,
  disabled,
  loadingColor = 'black',
}: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={` flex justify-between  items-center min-w-auto m-2 p-4 rounded-2xl bg-gray-200 ${
        disabled && '!bg-gray-500 cursor-not-allowed'
      } hover:border-gray-400 text-black border border-[transparent] duration-500 ease-in-out  ${className} ${
        Icon && 'px-8'
      }`}
    >
      {Icon && (
        <div className=" flex justify-center">
          <Icon className="text-[20px]" />
        </div>
      )}
      {text && (
        <p
          className={`flex-1 duration-200 font-PopinsMedium px-4 flex items-center    ${
            Icon ? 'text-left' : 'text-center'
          }`}
        >
          {loading ? <Loading color={loadingColor} size="sm" /> : text}
        </p>
      )}
    </button>
  );
}
CustomBtn.defaultProps = {
  text: undefined,
  Icon: undefined,
  onClick: () => {},
  className: '',
};
