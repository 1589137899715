import svg from 'assets/svg/time.svg';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="h-[100%] flex items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <img src={svg} alt="" className="w-[60%]" />
        <p className="font-semibold text-2xl">Oops! This page is still empty !</p>
        <p className="text-center">
          Check User part to start adding your team 
        </p>
        <Link to={{ pathname: '/team' }}>
          <CustomBtn text="Page users" className="!bg-gray-800 !text-white hover:border-none" />
        </Link>
      </div>
    </div>
  );
}
