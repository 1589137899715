import Layout from 'components/layout/Layout';
import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { Redirect, Route as BaseRoute, RouteProps as BaseRouteProps, useLocation } from 'react-router-dom';
import { encodeUri } from 'utils/url';
import classes from './route.module.scss';

export interface RouteProps extends BaseRouteProps {
  protected?: boolean;
  firstcnx?: boolean;
  header?: boolean;
  dashboardLayout?: boolean;
}

const Route = ({ protected: protectedProp, header, dashboardLayout, firstcnx, ...rest }: RouteProps) => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const first = localStorage.getItem('first_cnx');

  if (protectedProp && !user) {
    return <Redirect to={{ pathname: '/login', search: encodeUri({ from: location.pathname + location.search }) }} />;
  }

  if (!first && !firstcnx && user?.first_cnx && user?.role !== 'admin') {
    return <Redirect to="/onboarding" />;
  }

  return (
    <div className={`${classes.container} flex`}>
      {dashboardLayout ? (
        <Layout>
          <BaseRoute {...rest} />
        </Layout>
      ) : (
        <BaseRoute {...rest} />
      )}
    </div>
  );
};

Route.defaultProps = {
  header: true,
};

export default Route;
