import React from 'react';

interface OnboardingButtonInterface {
  children: React.ReactElement | string;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const OnboardingButton = ({ children, className, onClick, type }: OnboardingButtonInterface) => (
  <button
    type={type}
    onClick={onClick}
    className={`px-4 py-6 text-white hover:bg-gray-800  bg-black rounded-xl font-thin ${className} font-PopinsMedium cursor-pointer text-center flex items-center justify-center`}
  >
    {children}
  </button>
);

OnboardingButton.defaultProps = {
  className: '',
  onClick: () => {},
  type: 'button',
};
export default OnboardingButton;
