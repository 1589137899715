import Block from 'components/ui/Blocks/Block';
import DropDownBtn from 'components/ui/Buttons/DropDownBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Title from 'components/ui/Text/Title';
import { Switcher } from 'containers/Menu/MenuList';
import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { BiPhoneCall } from 'react-icons/bi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GrMapLocation } from 'react-icons/gr';
import { HiOutlineMap } from 'react-icons/hi';
import { IoRestaurantOutline } from 'react-icons/io5';
import { MdOutlinePlace } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useGetPointSale, useUpdatePointOption } from 'requests/PointSale/request';
import { useTranslation } from 'react-i18next';

export default function RestaurantDetails() {
  const { user } = useContext(UserContext);
  const { data, loading, refetch } = useGetPointSale({ variables: { id: user?.idPOS } });
  // const [currency, setcurrency] = useState(data?.getPointSale?.currency);
  const [updatePointOption] = useUpdatePointOption();
  const { t } = useTranslation();
  const handeStausUpdate = (update: Record<string, string>) => {
    return new Promise((resolve, reject) => {
      if (user?.idPOS) {
        updatePointOption({ variables: { id: user?.idPOS, ...update } }).then(({ errors }) => {
          if (errors) toast.error(`${errors}`);
          resolve(true);
        });
      } else {
        toast.error('Please you need to be logged in to update your restaurant');
        reject(new Error('Please you need to be logged in to update your restaurant'));
      }
    });
  };
  return (
    <div className="m-8">
      <Block loading={loading}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <Title title={t('restaurantDetails.section1.title')} className="before:bg-[#FFBC9A]" />
            <div className="flex w-full gap-6">
              <InputBtn
                label={t('restaurantDetails.section1.input1.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section1.input1.placeholder')}
                type="text"
                Icon={IoRestaurantOutline}
                value={data?.getPointSale?.legal_business_name}
              />
              <InputBtn
                label={t('restaurantDetails.section1.input2.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section.input2.placeholder')}
                type="text"
                Icon={BiPhoneCall}
                value={data?.getPointSale?.iban}
              />
            </div>
            <div className="grid grid-cols-2 gap-6 w-1/2 pr-3">
              <DropDownBtn
                label={t('restaurantDetails.section1.input3.label')}
                Icon={MdOutlinePlace}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section1.input3.placeholder')}
                onChange={(el) => handeStausUpdate({ currency: el.label }).then(() => refetch())}
                options={[
                  { value: `${Date.now()}`, label: 'USD' },
                  { value: `${Date.now()}`, label: 'EURO' },
                  { value: `${Date.now()}`, label: 'AED' },
                ]}
                value={
                  data?.getPointSale?.currency
                    ? { value: data?.getPointSale?.currency, label: data?.getPointSale?.currency }
                    : { value: "'Not Specified'", label: "'Not Specified'" }
                }
              />
              {false && (
                <DropDownBtn
                  label="Tip option"
                  Icon={BsCurrencyDollar}
                  className="bg-gray-100"
                  onFocusClassName="!border-black border-2"
                  placeholder=""
                  value={data?.getPointSale?.tips ? { value: 'Yes', label: 'Yes' } : { value: 'No', label: 'No' }}
                  options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                  ]}
                />
              )}
            </div>
          </div>
          <div className="w-full flex flex-col gap-4">
            <Title title={t('restaurantDetails.section2.title')} className="before:bg-blueLight" />
            <div className="flex items-stretch gap-6">
              <InputBtn
                label={t('restaurantDetails.section2.input1.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section2.input1.placeholder')}
                type="text"
                Icon={MdOutlinePlace}
                value={data?.getPointSale?.registred_address}
              />
              <InputBtn
                label={t('restaurantDetails.section2.input2.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section2.input2.placeholder')}
                type="text"
                Icon={HiOutlineMap}
                value={data?.getPointSale?.tax_id_number}
              />
              <InputBtn
                label={t('restaurantDetails.section2.input3.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section2.input3.placeholder')}
                type="text"
                Icon={GrMapLocation}
                value={data?.getPointSale?.business_structure}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-4">
            <Title title={t('restaurantDetails.section3.title')} className="before:bg-purple" />
            <div className="flex items-stretch gap-6">
              <InputBtn
                label={t('restaurantDetails.section3.input1.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section3.input1.placeholder')}
                type="number"
                Icon={MdOutlinePlace}
                value={data?.getPointSale?.tax_id_number}
              />
              <InputBtn
                label={t('restaurantDetails.section3.input2.label')}
                className="bg-gray-100"
                placeholder={t('restaurantDetails.section3.input2.placeholder')}
                type="number"
                Icon={MdOutlinePlace}
                value={data?.getPointSale?.tax_id_number}
              />
            </div>
          </div>
          <div>
            <div className="flex gap-8">
              <Title title={t('restaurantDetails.section4.title')} className="before:bg-pink" />
              <Switcher
                label={t('restaurantDetails.section4.item1').toString()}
                onChange={(status) => handeStausUpdate({ tips: `${status}` })}
                isVisible={data?.getPointSale?.tips || false}
              />
              <Switcher
                label={t('restaurantDetails.section4.item2').toString()}
                onChange={(status) => handeStausUpdate({ valet: `${status}` })}
                isVisible={Boolean(data?.getPointSale?.transportation?.valet)}
              />
              <Switcher
                label={t('restaurantDetails.section4.item3').toString()}
                onChange={(status) => handeStausUpdate({ careem: `${status}` })}
                isVisible={Boolean(data?.getPointSale?.transportation?.careem)}
              />
              <Switcher
                label={t('restaurantDetails.section4.item4').toString()}
                onChange={(status) => handeStausUpdate({ taxi: `${status}` })}
                isVisible={Boolean(data?.getPointSale?.transportation?.taxi)}
              />
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
}

RestaurantDetails.defaultProps = {
  userID: '',
};
