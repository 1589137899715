import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { IData, PointSale, UpdateOptions } from 'requests/types';

export const useCreatPointSale = (options: MutationHookOptions<{ pointSale: PointSale<string> }> = {}) => {
  const USER_UPDATE = gql`
    mutation createPointSale(
      $Point_type: String
      $name: String
      $legal_business_name: String
      $business_structure: String
      $tax_id_number: String
      $registred_address: String
      $iban: String
      $idOwner: ID
      $idManagers: [ID]
      $idWaiters: [ID]
      $pos_informations: PointSalePos_informationsInput
      $menu_qr_code: String
    ) {
      createPointSale(
        Point_type: $Point_type
        name: $name
        legal_business_name: $legal_business_name
        business_structure: $business_structure
        tax_id_number: $tax_id_number
        registred_address: $registred_address
        iban: $iban
        idOwner: $idOwner
        idManagers: $idManagers
        idWaiters: $idWaiters
        pos_informations: $pos_informations
        menu_qr_code: $menu_qr_code
      ) {
        id
        point_sale_logo
      }
    }
  `;
  return useLocalMutation(USER_UPDATE, options);
};

// Get Menus
export const useGetPointSale = (options: QueryHookOptions<{ getPointSale: PointSale<string> }, {}> = {}) => {
  const menuutation = gql`
    query getPointSale($idOwner: ID, $id: ID) {
      getPointSale(idOwner: $idOwner, id: $id) {
        id
        Point_type
        name
        point_sale_logo
        legal_business_name
        business_structure
        tax_id_number
        registred_address
        iban
        idManagers {
          id
        }
        idWaiters {
          id
        }
        pos_informations {
          pos_brand
          email
          password
          table_plan
        }
        transportation {
          valet
          taxi
          careem
        }
        appDesign {
          button_name
          button_link
        }
        tips
        currency
        menu_qr_code
      }
    }
  `;

  return useLocalQuery(menuutation, options);
};

export const useGetPointSales = (
  options: QueryHookOptions<{ PointSales: IData<PointSale<string>> }, { idOwner?: string }> = {},
) => {
  const menuutation = gql`
    query PointSales($idOwner: ID) {
      PointSales(idOwner: $idOwner) {
        data {
          id
          name
          legal_business_name
          point_sale_logo
          registred_address
          idOwner {
            id
            first_name
            last_name
            phone_number
          }
          currency
        }
      }
    }
  `;

  return useLocalQuery(menuutation, options);
};

export const useGetPointSaleBySayed = (
  options: QueryHookOptions<
    { getPointBySayed: { menus: string[]; pointOfSale: PointSale<string> } },
    { id: string; idOwner: string; idTable: string }
  > = {},
) => {
  const sayedmutation = gql`
    query getPointBySayed($id: ID, $idTable: ID, $idOwner: ID) {
      getPointBySayed(id: $id, idTable: $idTable, idOwner: $idOwner) {
        menus
        pointOfSale {
          id
          Point_type
          name
          point_sale_logo
        }
      }
    }
  `;

  return useLocalQuery(sayedmutation, options);
};

export const useUpdatePoint = (
  options: MutationHookOptions<{ updatePointSale: PointSale<any> }, PointSale<string>> = {},
) => {
  const USER_UPDATE = gql`
    mutation updatePointSale(
      $id: ID!
      $Point_type: String
      $name: String
      $point_sale_logo: Upload
      $legal_business_name: String
      $business_structure: String
      $tax_id_number: String
      $registred_address: String
      $iban: String
      $idOwner: ID
      $idManagers: [ID]
      $idWaiters: [ID]
      $pos_informations: PointSalePos_informationsInput
      $appDesign: PointSalePos_appDesignType_Input
      $menu_qr_code: String
    ) {
      updatePointSale(
        id: $id
        Point_type: $Point_type
        name: $name
        point_sale_logo: $point_sale_logo
        legal_business_name: $legal_business_name
        business_structure: $business_structure
        tax_id_number: $tax_id_number
        registred_address: $registred_address
        iban: $iban
        idOwner: $idOwner
        idManagers: $idManagers
        idWaiters: $idWaiters
        pos_informations: $pos_informations
        appDesign: $appDesign
        menu_qr_code: $menu_qr_code
      ) {
        id
        point_sale_logo
      }
    }
  `;
  return useLocalMutation(USER_UPDATE, options);
};

export const useUpdatePointOption = (
  options: MutationHookOptions<{ updatePointSaleOption: string }, UpdateOptions> = {},
) => {
  const MUTATION = gql`
    mutation updatePointSaleOption(
      $id: ID!
      $taxi: String
      $valet: String
      $tips: String
      $careem: String
      $currency: String
    ) {
      updatePointSaleOption(id: $id, taxi: $taxi, valet: $valet, tips: $tips, careem: $careem, currency: $currency)
    }
  `;
  return useLocalMutation(MUTATION, options);
};
