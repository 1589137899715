import React from 'react';

interface Props {
  pageNumber: number;
  title: string;
  setPageNumber: (x: number) => void;
}
export default function OnboardingSteps({ pageNumber, title, setPageNumber }: Props) {
  return (
    <div className="flex flex-col items-center">
      <h1 className="mb-0 text-center font-PopinsMedium text-3xl">Welcccome!</h1>
      <div className="flex justify-center my-0">
        <div
          className={
            pageNumber >= 0
              ? 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer bg-[#FFD88D]'
              : 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer border-2 border-trueGray-400 text-gray-400'
          }
          onClick={() => (pageNumber >= 1 ? setPageNumber(1) : null)}
        >
          1
        </div>
        <div
          className={`${
            pageNumber >= 0 ? 'border-gray-500' : 'border-gray-300'
          } ml-3 border-t-2 translate-y-2/4 w-[75px] h-8`}
        />
        <div
          className={`${
            pageNumber >= 1 ? 'border-gray-500' : 'border-gray-300'
          } mr-3 border-t-2 translate-y-2/4 w-[75px] h-8`}
        />
        <div
          className={
            pageNumber >= 1
              ? 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer bg-[#B1E5FE]'
              : 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer border-2 border-trueGray-400 text-gray-400'
          }
          onClick={() => (pageNumber >= 2 ? setPageNumber(2) : null)}
        >
          2
        </div>
        <div
          className={`${
            pageNumber >= 1 ? 'border-gray-500' : 'border-gray-300'
          } ml-3 border-t-2 translate-y-2/4 w-[75px] h-8`}
        />
        <div
          className={`${
            pageNumber >= 2 ? 'border-gray-500' : 'border-gray-300'
          } mr-3 border-t-2 translate-y-2/4 w-[75px] h-8`}
        />
        <div
          className={
            pageNumber >= 2
              ? 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer bg-[#CABDFF]'
              : 'flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium cursor-pointer border-2 border-trueGray-400 text-gray-400'
          }
        >
          3
        </div>
      </div>
      <h1 className="mt-2 text-center font-PopinsMedium text-xl">{title}</h1>
      <h1 className="my-2 text-center font-PopinsMedium text-sm text-gray-500">You can Always change them later.</h1>
    </div>
  );
}
