import * as React from 'react';

function Password({ fill = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.558 19.739" {...props}>
      <defs>
        <style> {`.prefix__a_password{fill:${fill}}`} </style>
      </defs>
      <path
        className="prefix__a_password"
        d="M99.813,154.229h-1.4v-1.5c0-2.77-2.634-5.023-5.871-5.023s-5.861,2.253-5.861,5.023v1.5H85.274a1.878,1.878,0,0,0-2,1.707v9.777a1.881,1.881,0,0,0,2,1.727h14.54a1.888,1.888,0,0,0,2.019-1.727v-9.777A1.885,1.885,0,0,0,99.813,154.229Zm-10.48-1.5a3.251,3.251,0,0,1,6.425,0v1.5H89.333Zm4.3,10.189v2.494a.21.21,0,0,1-.229.187H92a.2.2,0,0,1-.211-.187v-2.494a2.234,2.234,0,0,1-1.642-2.046,2.592,2.592,0,0,1,5.122,0A2.234,2.234,0,0,1,93.63,162.914Z"
        transform="translate(-83.275 -147.701)"
      />
    </svg>
  );
}

export default Password;
