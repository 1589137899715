import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Modal from 'components/ui/Modal/Modal';
import Title from 'components/ui/Text/Title';
import { useFormik } from 'formik';
import React from 'react';
import { BiUser } from 'react-icons/bi';
import { MdOutlineMail, MdOutlinePhoneInTalk } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetUser } from 'requests/Users/request';
import { decodeUri } from 'utils/url';
import * as yup from 'yup';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

interface Props {
  show: boolean;
  loading: boolean;
  error: any;
  setShow: (v: boolean) => void;
  onUpdate: (v: any) => void;
}

export default function ModalUpdateUser({ setShow, show, onUpdate, loading, error }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { waiterId = '' } = decodeUri(location?.search);
  const { data } = useGetUser({ variables: { id: waiterId }, fetchPolicy: 'no-cache' });

  const loginSchema = yup.object({
    email: yup
      .string()
      .email('Your E-mail should be valide (example@domain.com)')
      .required('Veuillez renseigner votre adresse e-mail'),
    first_name: yup.string().required('Please enter your firstname'),
    last_name: yup.string().required('Please enter your lastname'),
    phone_number: yup.string().required('This field is required'),
    role: yup.string().required('Please enter your role'),
  });
  const formik = useFormik({
    initialValues: {
      email: data?.getSingleUser?.email,
      first_name: data?.getSingleUser?.first_name,
      last_name: data?.getSingleUser?.last_name,
      phone_number: data?.getSingleUser?.phone_number,
      role: data?.getSingleUser?.role,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      onUpdate({ id: waiterId, ...values });
    },
  });

  return (
    <Modal
      className=" 3xl:w-[746px] 2xl:w-[600px]"
      onClose={() => {
        setShow(!show);
        history.replace({ search: `` });
      }}
      open={show}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-[20px]">
          <Title title="Update user" className="before:bg-[#FFBC9A]" />
          <div className="flex items-stretch justify-center gap-4 ">
            <InputBtn
              name="first_name"
              className=" border border-gray-300 "
              label="Name"
              placeholder="Name"
              type="text"
              Icon={BiUser}
              id="first_name"
              onChange={formik.handleChange}
              error={formik?.errors?.first_name}
              value={formik?.values?.first_name}
            />
            <InputBtn
              name="last_name"
              className=" border border-gray-300 "
              label="Lastname"
              placeholder="Lastname"
              type="text"
              id="last_name"
              Icon={BiUser}
              onChange={formik.handleChange}
              error={formik?.errors?.last_name}
              value={formik?.values?.last_name}
            />
          </div>

          <InputBtn
            name="email"
            id="email"
            className=" border border-gray-300 "
            label="Mail address"
            placeholder="Mail address"
            type="text"
            Icon={MdOutlineMail}
            onChange={formik.handleChange}
            error={formik?.errors?.email}
            value={formik?.values?.email}
          />
          <InputBtn
            name="phone_number"
            id="phone_number"
            className=" border border-gray-300"
            label="Phone number"
            placeholder="Your Phone number"
            type="phone"
            Icon={MdOutlinePhoneInTalk}
            onChange={(el) => (el.length ? formik.setFieldValue('phone_number', el) : null)}
            error={formik?.errors?.phone_number}
            value={formik?.values?.phone_number}
          />
          <InputBtn
            id="role"
            className=" border border-gray-300"
            label="Role"
            placeholder="Your Role"
            type="text"
            Icon={MdOutlinePhoneInTalk}
            value={formik?.values?.role}
          />
          <div className="flex justify-end relative ">
            <CustomBtn type="button" onClick={() => setShow(false)} text="Cancel" />
            <CustomBtn
              loading={loading}
              text="update"
              loadingColor="white"
              type="submit"
              className="!bg-warmGray-800 !text-white hover:border-none !text-white"
            />
            {error ? (
              <p className="bottom-0 left-0 absolute text-lg my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                {error?.message}
              </p>
            ) : null}
          </div>
        </div>
      </form>
    </Modal>
  );
}
