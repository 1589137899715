/* eslint-disable no-unused-vars */
import NavButton from 'components/ui/Buttons/NavButton';
import OnboardingPagination from 'components/ui/Pagination/OnboardingPagination';
import UserContext from 'contexts/UserContext';
import localforage from 'localforage';
import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useCreatPointSale } from 'requests/PointSale/request';
import { useUpdateUser } from 'requests/Users/request';
import logo from '../../assets/logo2.png';
import OnboardingPage1Layout from './OnboardingPage1Layout';
import OnboardingPage2Layout from './OnboardingPage2Layout';
import OnboardingPage3Layout from './OnboardingPage3Layout';
import OnboardingPage4Layout from './OnboardingPage4Layout';

const stringToDate = (_date: string, _format: string, _delimiter: string) => {
  const formatLowerCase = _format.toLowerCase();
  const formatItems = formatLowerCase.split(_delimiter);
  const dateItems = _date.split(_delimiter);
  const monthIndex = formatItems.indexOf('mm');
  const dayIndex = formatItems.indexOf('dd');
  const yearIndex = formatItems.indexOf('yyyy');

  let month = parseInt(dateItems[monthIndex], 10);
  month -= 1;
  const formatedDate = new Date(parseInt(dateItems[yearIndex], 10), month, parseInt(dateItems[dayIndex], 10));
  return formatedDate;
};

export const logout = () => {
  localStorage.clear();
  localforage.clear();
  window.location.reload();
};

export default function OnboardingMainLayout() {
  const history = useHistory();
  const [updateUser] = useUpdateUser();
  const { user, setUser } = useContext(UserContext);
  const [creatPointSale] = useCreatPointSale();
  const [pageNumber, setPageNumber] = useState(0);

  const [form1, setForm1] = useState({
    name: '',
    phone: '',
    job: '',
    birth: '',
    adress: '',
    adress_pdf: '',
    identity_card_pdf: '',
    identity_card: '',
  });
  const [form2, setForm2] = useState({
    structure: '',
    tax: '',
    iban: '',
    businessName: '',
    businessAdress: '',
  });
  const [form3, setForm3] = useState({
    brand: '',
    version: '',
    posLogin: '',
    posPsw: '',
    tablePlan: undefined,
  });

  if (!user?.first_cnx) {
    return <Redirect to="/" />;
  }
  const submitForm1 = (obj: any) => {
    setPageNumber(2);
    setForm1(obj);
  };

  const submitForm2 = (obj: any) => {
    setPageNumber(3);
    setForm2(obj);
  };

  const submitForm3 = async (obj: any) => {
    await setForm3(obj);
    updateUser({
      variables: {
        id: user?.id,
        first_name: form1.name,
        job_title: form1.job,
        date_of_birth: stringToDate(form1.birth, 'dd/MM/yyyy', '/'),
        phone_number: form1.phone.toString(),
        address: form1.adress,
        address_pdf: form1.adress_pdf,
        identity_card_pdf: form1.identity_card_pdf,
        identity_card: form1.identity_card.toString(),
        first_cnx: false,
      },
    }).then(() => {
      creatPointSale({
        variables: {
          legal_business_name: form2.businessName,
          business_structure: form2.structure,
          tax_id_number: form2.tax.toString(),
          registred_address: form2.businessAdress,
          iban: form2.iban.toString(),
          idOwner: user?.id,
          pos_informations: {
            pos_brand: obj.brand,
            email: obj.posLogin,
            password: obj.posPsw,
            table_plan: obj.posLogin,
            // table_plan: form3.tablePlan
          },
        },
      }).then(() => {
        setUser({ ...user, first_cnx: false });
        localStorage.setItem('first_cnx', `${Date.now()}`);
        history.push('/overview');
      });
    });
  };

  // useEffect(() => {
  //   if (logoutData) {
  //     alert('hay');
  //     setUser(null);
  //     localforage.clear();
  //     localStorage.removeItem('first_cnx');
  //   }
  // }, [logoutData]);

  return (
    <div>
      <div className="z-10 absolute w-[100%] h-[100vh] bg-white flex flex-col items-center py-8">
        <div className="mb-2">
          <img src={logo} alt="logo" className="w-[6rem]" />
        </div>
        <div className="absolute top-5 right-5" onClick={() => logout()}>
          <NavButton
            className=" hover:underline  md:flex md:!text-white text-sm  lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium lg:hover:bg-gray-50 lg:p-4 lg:rounded-lg lg:transition lg:delay-50 lg:ease-in-out"
            key="Disconnect"
            path="/"
            text="Disconnect"
          />
        </div>
        <div className="border-gray-300 border-2 bg-gray-100 drop-shadow-md z-10  w-[75%] h-[100%] p-3 rounded-xl overflow-y-auto flex flex-col justify-center">
          {[
            <OnboardingPage1Layout setNext={() => setPageNumber(1)} />,
            <OnboardingPage2Layout
              setPageNumber={(x: number) => setPageNumber(x)}
              form={form1}
              setNext={submitForm1}
            />,
            <OnboardingPage3Layout
              setPageNumber={(x: number) => setPageNumber(x)}
              form={form2}
              setNext={submitForm2}
            />,
            <OnboardingPage4Layout
              setPageNumber={(x: number) => setPageNumber(x)}
              form={form3}
              setNext={submitForm3}
            />,
          ].map((el, key) => key === pageNumber && el)}

          <OnboardingPagination pagesNumber={4} currentPage={pageNumber} />
        </div>
      </div>
    </div>
  );
}
