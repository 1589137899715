import { gql, MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { MenuItem } from './types';

export const useGetMenuItems = (options: QueryHookOptions<{ MenuItems: { data: MenuItem[] } }, {}> = {}) => {
  const QUERY = gql`
    {
      MenuItems {
        data {
          id
          label
          price
          image
        }
      }
    }
  `;

  return useLocalQuery(QUERY, options);
};

export const useAddOrder = (options: MutationHookOptions<{ addItemToTicket: string }, {}> = {}) => {
  const QUERY = gql`
    mutation addItemToTicket($table_id: ID, $orders: [TicketItem], $status: String) {
      addItemToTicket(table_id: $table_id, orders: $orders, status: $status)
    }
  `;

  return useLocalMutation(QUERY, options);
};
