import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import Loading from 'components/ui/Loading';
import OnboardingSteps from 'components/ui/Pagination/OnboardingSteps';
import { useFormik } from 'formik';
import React from 'react';
import { IoMdCopy } from 'react-icons/io';
import { IoLogInOutline } from 'react-icons/io5';
import { MdLabelOutline } from 'react-icons/md';
import { VscKey } from 'react-icons/vsc';
import * as Yup from 'yup';

interface Form {
  brand: string;
  version: string;
  posLogin: string;
  posPsw: string;
  tablePlan: File | undefined;
}

interface Props {
  setNext: (obj: any) => void;
  setPageNumber: (x: number) => void;
  form: Form;
}

export default function OnboardingPage4Layout({ setNext, form, setPageNumber }: Props) {
  const formik = useFormik({
    initialValues: {
      brand: form.brand,
      version: form.version,
      posLogin: form.posLogin,
      posPsw: form.posPsw,
      tablePlan: form.tablePlan,
    },
    validationSchema: Yup.object({
      brand: Yup.string().required('This field is required'),
      version: Yup.string().required('This field is required'),
      posLogin: Yup.string().required('This field is required'),
      posPsw: Yup.string().required('This field is required'),
      tablePlan: Yup.mixed().required('This field is required'),
    }),
    onSubmit: (values) => {
      setNext(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center mb-8">
        <OnboardingSteps setPageNumber={setPageNumber} pageNumber={2} title="Business info" />
        <div className="grid grid-rows-3 grid-cols-2 2xl:w-[80%] w-[70%] gap-x-8 gap-y-4 mb-6 md:flex md:flex-col md:h-[40vh] md:overflow-y-scroll md:overflow-x-hidden ">
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.brand && formik.touched?.brand ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter the brand of the POS"
              type="text"
              label="Brand of the POS"
              Icon={MdLabelOutline}
              onChange={formik.handleChange}
              value={formik.values.brand}
              id="brand"
              error={formik.errors?.brand}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.version && formik.touched?.version ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter the version of the POS"
              type="text"
              label="Version of the POS"
              Icon={IoMdCopy}
              onChange={formik.handleChange}
              value={formik.values.version}
              id="version"
              error={formik.errors?.version}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.posLogin && formik.touched?.posLogin ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter the login credentials of the POS"
              type="text"
              label="Login credentials of the POS"
              Icon={IoLogInOutline}
              onChange={formik.handleChange}
              value={formik.values.posLogin}
              id="posLogin"
              error={formik.errors?.posLogin}
            />
          </div>

          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.posPsw && formik.touched?.posPsw ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter the password of the POS"
              type="password"
              label="Password of the POS"
              Icon={VscKey}
              onChange={formik.handleChange}
              value={formik.values.posPsw}
              id="posPsw"
              error={formik.errors?.posPsw}
            />
          </div>
          <div>
            <OnboadringUploadBtn
              accept=".jpg,.pdf"
              placeholder="Upload your table plan"
              text={
                formik.values.tablePlan?.name !== undefined
                  ? formik.values.tablePlan.name
                  : "The file must be JPG or PDF and don't exeed 5MO"
              }
              onChange={(event) => formik.setFieldValue(event.target.id, event.target.files[0])}
              id="tablePlan"
              className={formik.errors?.tablePlan && formik.touched?.tablePlan ? 'border-red-500' : 'border-gray-400'}
            />
            {formik.errors?.tablePlan && formik.touched?.tablePlan ? (
              <p className="fixed text-xs my-1 font-PopinsMedium -translate-y-1/2 text-red-500">
                {formik.errors?.tablePlan}
              </p>
            ) : null}
          </div>
        </div>
        <OnboardingButton type="submit" className="w-[30%] h-[40px]">
          {formik.isSubmitting ? <Loading size="sm" color="white" /> : 'Launch'}
        </OnboardingButton>
      </div>
    </form>
  );
}
