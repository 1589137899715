/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Close from 'components/icons/Close';
import PreviewIcon from 'components/icons/Preview';
import React, { useState } from 'react';
import { VscFilePdf } from 'react-icons/vsc';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function ImagePreview({ ...props }) {
  const { src = '', onHoverText = '', width, height, ...rest } = props;
  const [preview, setpreview] = useState(false);
  const [open, setopen] = useState(false);
  const [, setNumPages] = useState(1);

  function onDocumentLoadSuccess(numPage: any) {
    setNumPages(numPage);
  }

  return (
    <div
      className="relative   "
      onMouseEnter={() => (!src?.includes('.pdf') ? setpreview(true) : null)}
      onMouseLeave={() => setpreview(false)}
    >
      {preview ? (
        <div
          className=" absolute w-[100%] m-auto h-full flex items-center justify-center rounded-xl"
          style={{ boxShadow: 'inset 9px 15px 48px 50px #0000000a' }}
        >
          <div className=" cursor-pointer  flex justify-center items-center">
            {!src?.includes('.pdf') && (
              <PreviewIcon
                fill="#white"
                onClick={() => {
                  setopen(true);
                  setpreview(false);
                }}
                className=" cursor-pointer hover:h-8"
                height={25}
              />
            )}
            {/* eslint-disable-next-line */}
            <p
              onClick={() => {
                setopen(true);
                setpreview(false);
              }}
              className="ml-1 text-sm underline cursor-pointer text-black font-bold"
            >
              {onHoverText}
            </p>
          </div>
        </div>
      ) : null}
      {open && (
        <div className="fixed !p-[20%]  bg-[#000000bf]   flex justify-center items-center h-full w-full top-0 right-0 z-[9999950]">
          <Close
            fill="white"
            onClick={() => setopen(false)}
            className="absolute top-8 right-8 cursor-pointer hover:h-8 text-white"
            height={25}
          />
          {src?.includes('.pdf') ? (
            <Document className="scale-75" file={src} onLoadSuccess={onDocumentLoadSuccess}>
              <Page height={842} pageNumber={1} />
            </Document>
          ) : (
            <img
              src={src}
              alt="preview"
              className="  hover:underline p-10 4xl:p-48 3xl:p-48 xl:p-48 lg:p-0  md:p-0  lg:w-full w-auto h-auto"
            />
          )}
        </div>
      )}
      {src?.includes('.pdf') ? (
        <p
          onClick={() => {
            setopen(true);
          }}
          className="hover:underline cursor-pointer flex items-center gap-3 z-50"
        >
          <VscFilePdf className="h-[20]" />
          <span
            onClick={() => {
              setopen(true);
            }}
            className="hover:underline cursor-pointer text-sm"
          >
            preview PDF
          </span>
        </p>
      ) : (
        <img
          width={width}
          height={height}
          src={src}
          className={`underline z-50  ${rest.className}`}
          alt="Click to preview"
          {...rest}
        />
      )}
    </div>
  );
}
