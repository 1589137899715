import React, { ReactNode } from 'react';
import Loading from '../Loading';

interface Props {
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

export default function Block({ children, className, loading }: Props) {
  return !loading ? (
    <div className={` bg-trueGray-50 p-8 relative rounded-xl ${className} `}>{children}</div>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <Loading />
    </div>
  );
}

Block.defaultProps = {
  className: '',
};
