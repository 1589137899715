import { IconType } from 'react-icons/lib';

interface Props {
  Icon: IconType;
  text: string;
  title: string;
  className: string;
}

export default function MenuGuides({ Icon, text, title, className }: Props) {
  return (
    <div className="flex gap-4 xl:w-[250px]">
      <div className={`${className} flex items-center p-4 justify-center rounded-xl h-[fit-content]`}>
        <Icon />
      </div>
      <div className="flex flex-col w-[max-content] max-w-[215px] xl:text-center ">
        <h1 className="text-xl font-PopinsMedium font-semibold">{title}</h1>
        <p className="font-PopinsMedium text-gray-400 max-w-[18ch]">{text}</p>
      </div>
    </div>
  );
}
