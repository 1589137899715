import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import { useFormik } from 'formik';
import React from 'react';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateUser } from 'requests/Users/request';
import * as yup from 'yup';

interface Props {
  // email?: string;
  idUser: string;
}

export default function ForgetPasswordPsw({ idUser }: Props) {
  const history = useHistory();

  const [updateUser] = useUpdateUser();

  const loginSchema = yup.object({
    password: yup.string().required('Password is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if (idUser && values.password === values?.passwordConfirmation) {
        updateUser({
          variables: { id: idUser, password: values.password },
        }).then(({ data }) => {
          if (data?.updateUser?.id) {
            toast.success('Mise a jour avec succès');
            history.push('/login');
          } else {
            toast.error('Erreur de mise a jour');
          }
        });
      } else {
        toast.error('Missing Params');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center">
        <h1 className="mb-4 text-center font-PopinsMedium text-3xl">Create new password</h1>
        <p className="mb-8 font-PopinsMedium text-gray-500 relative text-center">
          Your new password must be different from previously used password.
        </p>
        <div className=" flex flex-col gap-8">
          <div className=" flex flex-col gap-4">
            <div>
              <InputBtn
                className={`border-2 ${
                  formik.errors?.password && formik.touched?.password ? 'border-red-500' : 'border-gray-300'
                }`}
                onFocusClassName="border-black"
                placeholder="Enter your new password"
                type="password"
                label="New password"
                Icon={RiLockPasswordLine}
                onChange={formik.handleChange}
                value={formik.values.password}
                id="password"
              />
              {formik.errors?.password ? (
                <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                  {formik.errors?.password}
                </p>
              ) : null}
            </div>
            <div>
              <InputBtn
                className={`border-2 ${
                  formik.errors?.passwordConfirmation &&
                  formik.touched?.passwordConfirmation &&
                  !(formik.errors?.password && formik.touched?.password)
                    ? 'border-red-500'
                    : 'border-gray-300'
                }`}
                onFocusClassName="border-black"
                placeholder="Enter your password again"
                type="password"
                label="Confirm password"
                Icon={RiLockPasswordLine}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirmation}
                id="passwordConfirmation"
              />
              {formik.errors?.passwordConfirmation && !(formik.errors?.password && formik.touched?.password) ? (
                <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                  {formik.errors?.passwordConfirmation}
                </p>
              ) : null}
            </div>
          </div>
          <OnboardingButton type="submit" className="w-[100%] h-[40px]">
            Save
          </OnboardingButton>
        </div>
      </div>
    </form>
  );
}
