interface Props {
  title: string;
  className?: string;
}

export default function Title({ title, className = '' }: Props) {
  return (
    <div>
      <h1
        className={`before:block before:absolute ${className} before:w-1 before:h-6  before:rounded-md before:left-4 font-PopinsMedium text-2xl`}
      >
        {title}
      </h1>
    </div>
  );
}

Title.defaultProps = {
  className: '',
};
