import DelteSVG from 'assets/svg/delete.svg';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Modal from 'components/ui/Modal/Modal';
import React from 'react';

interface Props {
  show: boolean;
  loading?: boolean;
  called?: boolean;
  setShow: (v: boolean) => void;
  onConfirm: () => void;
  onClose?: () => void;
  text: string;
  title?: string;
}

export default function ModalConfirmation({ show, setShow, called, loading, onConfirm, text, title, onClose }: Props) {
  return (
    <div className="">
      <Modal
        className="h-[630px] w-[764px]"
        onClose={() => {
          if (onClose) {
            onClose();
          }
          setShow(!show);
        }}
        open={show}
      >
        <div className="flex flex-col gap-3 items-center justify-center min-h-[200px]">
          <img alt="fsdfs" src={DelteSVG} />
          <p className="text-3xl">{title}</p>
          <p className="text-xl text-gray-500">{text}</p>
        </div>
        <div className="flex justify-end absolute right-6 bottom-6">
          <CustomBtn
            text="Cancel"
            onClick={() => {
              if (onClose) {
                onClose();
              }
              setShow(!show);
            }}
          />
          <CustomBtn
            onClick={onConfirm}
            loading={loading || called}
            loadingColor="white"
            type="submit"
            text="Confirm"
            className="!bg-warmGray-800 !text-white hover:border-none"
          />
        </div>
      </Modal>
    </div>
  );
}
