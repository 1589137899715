import CardCaisse from 'components/ui/CardCaisse/CardCaisse';
import { logout } from 'containers/Onboarding/OnboardingMainLayout';
import React from 'react';
import { useGetPointSales } from 'requests/PointSale/request';

export default function Caisse() {
  const { data } = useGetPointSales({ variables: {} });

  return (
    <div>
      <div className="hover:underline cursor-pointer absolute right-5 top-3 " onClick={() => logout()}>
        disconect
      </div>
      <div className="w-full flex items-center justify-center gap-4 py-3">
        <h1 className="text-3xl">Choose you restaurant</h1>
      </div>
      <div className="grid grid-cols-3 gap-3 p-2 ">
        {data?.PointSales?.data?.map(
          ({ id, legal_business_name, registred_address, point_sale_logo, idOwner, currency }) => (
            <CardCaisse
              idPOS={id}
              logo={point_sale_logo}
              name={legal_business_name}
              address={registred_address}
              city={registred_address}
              currency={currency}
              tel={idOwner?.phone_number || ''}
            />
          ),
        )}
      </div>
    </div>
  );
}
