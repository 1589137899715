import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { Menu, UpdateMenuArgs } from './types';

// Get Menus
export const useGetMenus = (options: QueryHookOptions<{ Menus: { data: Menu[] } }, {}> = {}) => {
  const menuutation = gql`
    query Menus($pointSale_id: ID) {
      Menus(pointSale_id: $pointSale_id) {
        data {
          id
          image
          description
          category
          visible
          order
        }
      }
    }
  `;

  return useLocalQuery(menuutation, options);
};

// Get Menu
export const useGetMenu = (options: QueryHookOptions<{ Menu: Menu }, { id: string }> = {}) => {
  const menuutation = gql`
    query Menu($id: ID!) {
      Menu(id: $id) {
        id
        image
        description
        category
        visible
        order
      }
    }
  `;

  return useLocalQuery(menuutation, options);
};

export const useUpdateMenu = (options: MutationHookOptions<{ updateMenu: string }, UpdateMenuArgs> = {}) => {
  const menuutation = gql`
    mutation updateMenu(
      $id: ID!
      $image: String
      $newimage: Upload
      $description: String
      $category: String
      $visible: Boolean
      $order: Int
    ) {
      updateMenu(
        id: $id
        image: $image
        newimage: $newimage
        description: $description
        category: $category
        visible: $visible
        order: $order
      ) {
        id
        image
        description
        category
        visible
        order
      }
    }
  `;

  return useLocalMutation(menuutation, options);
};

export const useCreateMenu = (options: MutationHookOptions<{ createMenu: Menu }> = {}) => {
  const menuutation = gql`
    mutation createMenu(
      $image: Upload
      $description: String
      $category: String
      $visible: Boolean
      $order: Int
      $pointSale_id: ID
    ) {
      createMenu(
        image: $image
        description: $description
        category: $category
        visible: $visible
        order: $order
        pointSale_id: $pointSale_id
      ) {
        id
        image
        description
        category
        visible
        order
      }
    }
  `;

  return useLocalMutation(menuutation, options);
};

export const useDeleteMenu = (options: MutationHookOptions<{ removeMenu: string }, { id: string }> = {}) => {
  const menuutation = gql`
    mutation removeMenu($id: ID!) {
      removeMenu(id: $id)
    }
  `;

  return useLocalMutation(menuutation, options);
};
