/* eslint-disable no-nested-ternary */
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import ImagePreview from 'components/ui/Preview/ImagePreview';
import UserContext from 'contexts/UserContext';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { BiCodeCurly } from 'react-icons/bi';
import { BsCardImage } from 'react-icons/bs';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { TiArrowShuffle } from 'react-icons/ti';
import { toast } from 'react-toastify';
import { useGetPointSale, useUpdatePoint } from 'requests/PointSale/request';
import { PointSale } from 'requests/types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Block from '../../components/ui/Blocks/Block';
import Title from '../../components/ui/Text/Title';

export default function AppDesign() {
  const [updatePoint, { loading }] = useUpdatePoint();
  const [fileupload, setfileupload] = useState('');
  const { user, setUser } = useContext(UserContext);
  const { data, loading: getLoading } = useGetPointSale({ variables: { idOwner: user?.id, id: user?.idPOS } });
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      logo: data?.getPointSale?.point_sale_logo,
      button_name: data?.getPointSale?.appDesign?.button_name,
      button_link: data?.getPointSale?.appDesign?.button_link,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      logo: Yup.mixed().required('Logo Required'),
      button_name: Yup.string(),
      button_link: Yup.string(),
    }),

    onSubmit: ({ button_name, button_link, ...values }) => {
      const variables: PointSale<any> = {
        id: user?.idPOS,
        appDesign: { button_link, button_name },
      };
      if (typeof (values?.logo as any)?.name === 'string') variables.point_sale_logo = values?.logo;
      updatePoint({
        variables,
      }).then(({ errors, ...res }) => {
        if (errors) {
          toast.error(`${errors}`);
        }
        if (res?.data?.updatePointSale?.id) {
          setUser({ ...user, point_sale_logo: res?.data?.updatePointSale?.point_sale_logo } as any);
          toast.success('Information updated');
        }
      });
    },
  });

  useEffect(() => {
    if (formik?.values?.logo) {
      const url =
        typeof (formik?.values?.logo as any)?.name === 'string'
          ? URL?.createObjectURL(formik?.values?.logo as any)
          : formik?.values?.logo;
      setfileupload(url);
    } else {
      setfileupload('');
    }
  }, [formik?.values?.logo]);

  return (
    <div className="m-8">
      <p className="text-warmGray-800 text-2xl">{t('appDesign.pageTitle')}</p>
      <Block className="flex justify-between mb-4 mt-4">
        <div>
          <Title title="Logo" className="before:bg-[#B1E5FE]" />
          <p className="text-gray-500 text-sm font-PopinsMedium my-4">{t('appDesign.section1.description')}</p>
          <p className="text-red-400 text-xs">{formik?.errors?.logo}</p>
          <div className="flex items-center">
            <OnboadringUploadBtn
              className="!bg-[transparent] border:border-gray-200 hover:border-[white] hover:!bg-gray-200 !text-black"
              LeftIcon={TiArrowShuffle}
              accept=".jpg,.pdf,.png"
              placeholder={t('appDesign.section1.button').toString()}
              onChange={(event) => formik?.setFieldValue(event.target.id, event.target.files[0])}
              id="logo"
            />
          </div>
        </div>
        <div className="flex justify-end">
          {fileupload ? (
            <ImagePreview src={fileupload} width={150} />
          ) : user?.point_sale_logo ? (
            <ImagePreview src={user?.point_sale_logo} width={150} />
          ) : (
            <BsCardImage className="text-[150px]" />
          )}
        </div>
      </Block>
      <Block loading={getLoading}>
        <div>
          <div>
            <Title title={t('appDesign.section2.title')} className="before:bg-[#FFBC9A]" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-gray-500 text-sm font-PopinsMedium my-4">{t('appDesign.section2.description')}</p>
            <div className="w-1/2 grid grid-rows-2 gap-y-4 min-w-[300px]">
              <InputBtn
                className="border-2 border-gray-300"
                onFocusClassName="border-black"
                Icon={MdDriveFileRenameOutline}
                label={t('appDesign.section2.input1.label')}
                value={formik?.values?.button_name}
                id="button_name"
                name="button_name"
                onChange={formik.handleChange}
                type="text"
                placeholder={t('appDesign.section2.input1.placeholder')}
              />
              <InputBtn
                className="border-2 border-gray-300"
                onFocusClassName="border-black"
                Icon={BiCodeCurly}
                value={formik?.values?.button_link}
                id="button_link"
                name="button_link"
                onChange={formik.handleChange}
                label={t('appDesign.section2.input2.label')}
                type="text"
                placeholder={t('appDesign.section2.input1.placeholder')}
              />
            </div>
            <div className="flex justify-end">
              <CustomBtn text={t('appDesign.section2.cancelButton').toString()} />
              <CustomBtn
                onClick={() => formik?.submitForm()}
                text={t('appDesign.section2.saveButton').toString()}
                loading={loading}
                loadingColor="white"
                className="!bg-warmGray-800 hover:border-none !text-white"
              />
            </div>
          </div>
        </div>
      </Block>
    </div>
  );
}
