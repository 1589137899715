import NavButton from 'components/ui/Buttons/NavButton';
import Lanugage from 'components/ui/Language/Language';
import SocketContext from 'contexts/scoketContext';
import UserContext from 'contexts/UserContext';
import localforage from 'localforage';
import { useContext, useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { MdExitToApp } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useLogout } from 'requests/auth';
import logo from '../../../assets/logoApp.png';
import RestaurantLogo from '../../../assets/restaurant-logo.png';
import NavbarDropDown from './NavbarDropDown';

const NAVBAR_LINKS = (role: string) =>
  [
    { roles: ['owner'], path: '/overview', text: 'Overview' },
    { roles: ['owner'], path: '/myrestaurant', text: 'My Restaurant' },
    { roles: ['owner', 'manager', 'waiter'], path: '/contactus', text: 'Contact Us' },
  ].filter(({ roles }) => roles.includes(role));

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [logoutCall, { data: logoutData }] = useLogout();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const { socket } = useContext(SocketContext);
  const [xl, setXl] = useState(false);
  useEffect(() => {
    if (logoutData) {
      setUser(null);
      localforage.clear();
      localStorage.removeItem('first_cnx');
      socket.disconnect();
    }
  }, [logoutData]);
  useEffect(() => {
    if (window.innerWidth > 1200) {
      setXl(true);
    } else {
      setXl(false);
    }
    window.onresize = () => {
      setXl(window.innerWidth > 1200);
    };
  }, []);
  return (
    <div className="flex items-center border-b-[1px] border-grey-200 w-full justift-end h-[104px]">
      <div className=" flex justify-center md:w-[63px] w-[309px] py-4 h-[104px] text-center border-r border-grey-200  text-3xlfont-bold">
        <div
          className="flex p-2 justify-center items-center gap-2 cursor-pointer hover:bg-gray-100"
          onClick={() => {
            history.push('/overview');
          }}
        >
          <img src={logo} alt="logo" className="w-9" />
          <p className="text-lg md:hidden">Quick Bill</p>
        </div>
      </div>
      <div className="flex items-center px-5 justify-between w-full ">
        <div className="flex  items-center p-2  ">
          <span className="px-5 md:px-1 text-md bg-[#8080801e] rounded-l-lg h-10 flex items-center">
            <BsSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            className="bg-[#8080801e] border-0 md:w-[200px] rounded-r-lg h-10 !text-[grey] text-xl pr-2"
          />
        </div>

        {!xl ? <Lanugage /> : ''}
        {!xl ? (
          <div className="buttonNav flex items-center gap-8">
            <NavbarDropDown />
            <div className="flex items-center">
              <div className="cursor-pointer">
                <img className="h-[50px] min-w-[50px]" src={user?.point_sale_logo || RestaurantLogo} alt="" />
              </div>
            </div>
            <div
              onClick={() => logoutCall()}
              className="cursor-pointer xl:!hidden hover:bg-gray-100 w-12 h-12 flex justify-center items-center rounded-xl"
            >
              <MdExitToApp className="text-3xl" />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={`${!open ? 'flex  xl:hidden ' : 'xl:flex '}`}>
          <div className="flex xl:flex xl:flex-col xl:mt-[52px] xl:w-[300px] xl:h-[90vh] xl:absolute xl:z-[2] xl:bg-gray-200   xl:right-0 ">
            {NAVBAR_LINKS(user?.role || '').map((link) => (
              <NavButton
                className="h-[40px] flex items-center first:border-none border-l text-xl xl:flex-row xl:space-x-8 xl:mt-0 xl:text-sm xl:font-medium xl:hover:bg-gray-50 xl:p-4 xl:rounded-lg xl:transition xl:delay-50 xl:ease-in-out !px-8"
                key={link.text}
                {...link}
              />
            ))}
            <div onClick={() => logoutCall()}>
              <NavButton
                className=" !bg-red-400 hidden xl:flex xl:!text-white text-sm  xl:space-x-8 xl:mt-0 xl:text-sm xl:font-medium xl:hover:bg-gray-50 xl:p-4 xl:rounded-lg xl:transition xl:delay-50 xl:ease-in-out"
                key="Disconnect"
                path="/"
                text="Disconnect"
              />
            </div>
          </div>
        </div>

        {xl ? <Lanugage /> : ''}
        {xl ? (
          <div className="buttonNav flex items-center gap-8">
            <NavbarDropDown />
            <div className="flex items-center">
              <div className="cursor-pointer">
                <img className="h-[50px] min-w-[50px]" src={user?.point_sale_logo || RestaurantLogo} alt="" />
              </div>
            </div>
            <div
              onClick={() => logoutCall()}
              className="cursor-pointer xl:!hidden hover:bg-gray-100 w-12 h-12 flex justify-center items-center rounded-xl"
            >
              <MdExitToApp className="text-3xl" />
            </div>
          </div>
        ) : (
          ''
        )}
        <button
          data-collapse-toggle="mobile-menu"
          type="button"
          className="items-center p-2 ml-3 text-sm text-gray-500 rounded-lg hidden xl:block hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu"
          aria-expanded="false"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Navbar;
