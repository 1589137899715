import CustomBtn from 'components/ui/Buttons/CustomBtn';
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import logoApp from '../../assets/logoApp.png';

interface Props {
  QRCode: string;
  restaurantName: string | undefined;
}

export default function QRTemplateModalLayout({ QRCode, restaurantName }: Props) {
  const img = useRef(null);
  const print = useRef(null);
  const handleDownloadImage = async () => {
    const element = print.current;
    const canvas = await html2canvas(element as any);
    const data = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'image.jpeg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  return (
    <div className="flex flex-col gap-4 w-full">
      <div>
        <h1 className="text-2xl">View QR code</h1>
      </div>
      <div className="flex justify-center">
        <div
          className="flex flex-col bg-gray-200 border border-gray-400 rounded-xl p-6 gap-6 w-[532px] h-[383px]"
          ref={print}
        >
          <div className="flex items-center gap-2 ">
            <img src={logoApp} alt="logo" className="w-12" />
          </div>
          <div className="flex gap-4">
            <div className="w-[200px] h-[200px] border border-gray-400 rounded-xl bg-white">
              <img alt="" src={QRCode} ref={img} className="w-full h-full rounded-xl" />
            </div>
            <div className="flex flex-col justify-center text-2xl gap-4 w-[50%]">
              <p className="font-PopinsMedium ">View the menu and pay the bill</p>
              <p className="text-xl font-semibold"> {restaurantName}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <div onClick={handleDownloadImage}>
            <CustomBtn text="Download" />
          </div>
        }
        <ReactToPrint content={() => print.current}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <CustomBtn
                onClick={handlePrint}
                text="Print"
                className="!bg-warmGray-800 !text-white hover:border-none"
              />
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </div>
    </div>
  );
}
