import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Modal from 'components/ui/Modal/Modal';
import Title from 'components/ui/Text/Title';
import { useFormik } from 'formik';
import React from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import * as Yup from 'yup';

interface Props {
  show: boolean;
  closeFunction: () => void;
}

export default function RefundPopup({ show, closeFunction }: Props) {
  const formik = useFormik({
    initialValues: {
      montant: '',
      note: '',
    },
    validationSchema: Yup.object({
      montant: Yup.string().required('This field is required'),
      note: Yup.string().required('This field is required'),
    }),
    onSubmit: (values) => {
      console.log(values); // eslint-disable-line
    },
  });

  return (
    <Modal onClose={() => closeFunction()} open={show}>
      <div className="w-[650px] ">
        <Title title="Add refunded" className="mb-4" />
        <form onSubmit={formik.handleSubmit} className="flex-col">
          <p className="text-xs text-gray-400">Please enter the amount to be refunded</p>
          <div className="my-4">
            <InputBtn
              type="number"
              Icon={BsCurrencyDollar}
              placeholder="£ 00.00"
              label="Montant"
              className={`border-2 ${
                formik.errors?.montant && formik.touched?.montant ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              onChange={formik.handleChange}
              value={formik.values.montant}
              id="montant"
            />
            {formik.errors?.montant && formik.touched?.montant ? (
              <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                {formik.errors?.montant}
              </p>
            ) : null}
          </div>
          <p className="text-xs text-gray-400">Total payout: £60.00</p>
          <div className="my-4">
            <InputBtn
              type="text"
              Icon={MdOutlineStickyNote2}
              placeholder="Your notes"
              label="Refund notes"
              className={`border-2 ${
                formik.errors?.note && formik.touched?.note ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              onChange={formik.handleChange}
              value={formik.values.note}
              id="note"
            />
            {formik.errors?.note && formik.touched?.note ? (
              <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                {formik.errors?.note}
              </p>
            ) : null}
          </div>
          <div className="flex justify-end relative ">
            <CustomBtn
              text="Cancel"
              onClick={() => {
                closeFunction();
                formik.resetForm();
              }}
            />
            <CustomBtn
              //   loading={loading}
              text="Confirm"
              loadingColor="white"
              className="bg-warmGray-800 hover:border-[transparent] !text-white"
              type="submit"
            />
            {/* {error ? (
            <p className="bottom-0 left-0 absolute text-lg my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
              {error?.message}
            </p>
          ) : null} */}
          </div>
        </form>
      </div>
    </Modal>
  );
}
