import Title from '../Text/Title';

interface Props<T> {
  data: T[];
  search?: string;
  title?: React.ReactElement | string;
  externalComponent?: React.ReactElement | undefined;
  isGrey?: boolean;
  TitleClassname?: string;
  className?: string;
}

export default function Table({
  data,
  search,
  title,
  externalComponent,
  isGrey,
  TitleClassname,
  className,
}: Props<any>) {
  const regex = new RegExp(`${search}|${search?.toLowerCase()}`, 'gi');

  return (
    <>
      <div className={`flex ${className}`}>
        {typeof title === 'string' ? <Title className={TitleClassname} title={title} /> : title}
        <div className="ml-auto">{externalComponent}</div>
      </div>
      <div className={isGrey ? 'bg-[#F0F0F0] rounded-lg px-4' : ''}>
        <table className={isGrey ? 'bg-[#F0F0F0] rounded-lg w-full text-left mt-4' : 'w-full text-left mt-4'}>
          <thead>
            <tr>
              {Object.keys(data[0] || {})?.map((header: any) => (
                <th
                  className="w-[fit-content] h-8 font-PopinsMedium text-gray-400 font-normal"
                  scope="col"
                  key="azerty"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data?.length ? (
              data?.map((line: any) => (
                <tr
                  className={
                    isGrey ? 'border-t-[1px]  border-[#BBBBBB] my-2' : 'border-t-[1px]  border-[grey-400] my-2'
                  }
                >
                  {Object.keys(line).map((index) => (
                    <td
                      key={`${index + Math.random()}`}
                      className="w-20 h-8 font-PopinsMedium text-sm text-black font-extralight"
                    >
                      {`${line[index]}`.split(regex).map((character, i, array) => (
                        <>
                          {index === 'Id' && i === 0 ? '#' : ''}
                          {(index === 'Id' && i >= 19) || index !== 'Id' ? character : ''}
                          {array.length != i + 1 ? ( // eslint-disable-line
                            <span className="text-orange-400">{search || null}</span> // eslint-disable-line
                          ) : null}
                        </>
                      ))}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr className="flex my-4 ">Pas de resulats</tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

Table.defaultProps = {
  search: '',
  TitleClassname: '',
  title: '',
  externalComponent: undefined,
  isGrey: false,
  className: undefined,
};
