import { NotificationContext, NotificationsContext, NotificationInterface } from 'contexts/NotificationContext';
import React, { useContext, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { VscBellDot } from 'react-icons/vsc';
import OutsideClickHandler from 'react-outside-click-handler';

const Notification = ({ title, message, time, payload }: NotificationInterface) => {
  return (
    <div className="py-2 border-t-[2px] border-b-gray-500 flex">
      <div className="cursor-pointer bg-[#64BC26] w-12 h-12 flex justify-center items-center rounded-xl relative text-white">
        <BsCheckLg />
      </div>
      <div className="mx-4">
        <h3> {title || !payload?.timeToCar ? 'Pay Cash' : 'Car order'} </h3>
        <p className="text-gray-400 text-sm py-[2.2px]">{message || ''}</p>
      </div>
      <div className="ml-auto py-1 text-xs text-gray-400">
        <p>{time || payload?.timeToCar} minutes ago</p>
      </div>
    </div>
  );
};
const Notifications = () => {
  const { notifications } = useContext(NotificationsContext);
  return (
    <div className="relative">
      {notifications?.map((notification) => (
        <Notification {...{ ...notification }} />
      ))}
    </div>
  );
};
export default function NavbarDropDown() {
  const [show, setShow] = useState(false);
  const { notification, setNotification } = useContext(NotificationContext);
  return (
    <OutsideClickHandler onOutsideClick={() => setShow(false)}>
      <div className="w-12 h-12 relative ">
        <span
          className={`text-2xl cursor-pointer w-[100%] h-[100%] flex justify-center items-center hover:bg-gray-100 rounded-xl ${
            show ? 'bg-gray-100' : ''
          }`}
          onClick={() => {
            setShow(!show);
            setNotification({ ...notification, isRead: true } as any);
          }}
        >
          <VscBellDot className={`${!notification?.isRead ? 'text-red-700' : ''}`} />
        </span>

        <div
          className={`z-30 bg-white absolute translate-y-1 top-12 right-0 p-4 w-[440px] h-[528px] rounded-xl border-2 border-gray-200 overflow-hidden ${
            show ? '' : 'hidden'
          }`}
        >
          <div className="flex items-center pb-2">
            <h1>Notification</h1>
            <p className="ml-auto text-xs text-gray-500 cursor-pointers">mark all as read</p>
          </div>
          <div className="overflow-y-auto h-[94.5%]">
            <Notifications />
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}
