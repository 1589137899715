/* eslint-disable no-nested-ternary */
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { IoEyeOffOutline as EyeCover, IoEyeOutline as Eye } from 'react-icons/io5';
import classNames from 'utils/classNames';
import InputWrapper from './InputWrapper';

export type TextFieldProps = {
  type?: string;
  error?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  inputClasse?: string;
  label?: string;
  handleError?: boolean;
  textarea?: boolean;
  errorColor?: string;
} & (
  | (React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { textarea?: false })
  | (React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
      textarea: true;
    })
);

const Input: FunctionComponent<TextFieldProps> = ({
  type,
  iconLeft,
  error,
  iconRight,
  label,
  handleError = true,
  textarea = false,
  inputClasse = 'h-12 2xl:h-12',
  errorColor = 'red',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputClasses = classNames(
    'w-full	border rounded-lg  focus:outline-none p-7 py-4 ',
    'text-base font-regular font-PopinsMedium',
    textarea ? 'resize-none' : inputClasse,
    error && `border-${errorColor} border-2 focus:border-${errorColor}`,
    iconLeft && 'pl-16',
    iconRight && 'pr-16',
  );

  return (
    <InputWrapper
      error={error}
      iconRight={iconRight}
      iconLeft={iconLeft}
      errorColor={errorColor}
      label={label}
      handleError={handleError}
      classes={{ iconRight: textarea ? `top-0 ${inputClasse}` : undefined }}
    >
      {textarea ? (
        <textarea className={inputClasses} {...(rest as any)} />
      ) : (
        <input className={inputClasses} type={type === 'password' && showPassword ? 'text' : type} {...(rest as any)} />
      )}
      {type === 'password' && (
        <div
          className="absolute inset-y-0 right-5 flex items-center justify-center"
          onClick={() => setShowPassword(!showPassword)}
        >
          <button type="button" className="focus:outline-none ">
            {!error ? !showPassword ? <Eye fill="#111136" width="25px" /> : <EyeCover className="w-6" /> : null}
          </button>
        </div>
      )}
    </InputWrapper>
  );
};

export default Input;
