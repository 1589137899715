import React from 'react';

interface Props {
    pagesNumber: number;
    currentPage: number;

  }
export default function OnboardingPagination({currentPage, pagesNumber}:Props) {
  return (
    <div className="flex justify-center">
        {
            Array.from(Array(pagesNumber).keys()).map((el)=>
                el===currentPage? <div className="bg-black rounded-full h-1 w-2.5 mx-0.5"/>:<div className="bg-gray-300 rounded-full h-1 w-1 mx-0.5"/>
            )
        }
    </div>
  );
}
