/* eslint-disable no-nested-ternary */
import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import UserContext from 'contexts/UserContext';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useContext } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Link, Redirect } from 'react-router-dom';
import { useLogin } from 'requests/auth';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logoApp.png';

const LoginContainer = () => {
  const { user } = useContext(UserContext);
  const [loginCall, { error }] = useAuth(useLogin);

  const loginSchema = yup.object({
    email: yup
      .string()
      .email('Votre adresse e-mail doit être valide (exemple@domain.com)')
      .required('Veuillez renseigner votre adresse e-mail'),
    password: yup.string().required('Veuillez renseigner votre mot de passe'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginCall({ variables: values });
    },
  });
  const { t } = useTranslation();
  if (user) {
    return <Redirect to={user?.role === 'admin' ? '/caisse' : user?.first_cnx ? '/onboarding' : '/overview'} />;
  }

  return (
    <div className="z-10 absolute w-[100%] h-[100vh] bg-white flex flex-col items-center justify-center">
      <div className="m-8">
        <img src={logo} alt="logo" className="w-14" />
      </div>
      <div className="border-gray-300 border-2 bg-gray-100 drop-shadow-md z-10 w-[470px] 2xl:h-[420px] h-[470px] p-8 rounded-xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col items-center mb-12">
            <h1 className="mb-4 text-center font-PopinsMedium text-3xl">{t('login.title')}</h1>
            <p className="mb-8 font-PopinsMedium text-gray-500 relative">{t('login.subTitle')}</p>
            {error?.message && (
              <p className=" text-base my-0 font-PopinsMedium absolute top-2  text-red-500">{error?.message}</p>
            )}
            <div className="grid grid-rows-2 grid-cols-1 gap-4 mb-4 justify-center">
              <div>
                <InputBtn
                  className={`border-2 ${
                    formik.errors?.email && formik.touched?.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                  onFocusClassName="border-black"
                  placeholder={t('login.form.email.placeholder')}
                  label={t('login.form.email.label')}
                  type="email"
                  Icon={MdOutlineMail}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  id="email"
                />
                {formik.errors?.email ? (
                  <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                    {formik.errors?.email}
                  </p>
                ) : null}
              </div>
              <div>
                <InputBtn
                  className={`border-2 ${
                    formik.errors?.email && formik.touched?.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                  onFocusClassName="border-black"
                  placeholder={t('login.form.password.placeholder')}
                  label={t('login.form.password.label')}
                  type="password"
                  Icon={RiLockPasswordLine}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  id="password"
                />
                {formik.errors?.password && formik.touched?.password ? (
                  <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                    {formik.errors?.password}
                  </p>
                ) : null}
              </div>
              <div className="flex">
                <div
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => formik.setFieldValue('remember', !formik.values.remember)}
                >
                  <div className="h-3 w-3 border-[1px] rounded-sm border-gray-400 flex items-center justify-center">
                    {formik.values.remember ? <BsCheckLg className="text-gray-400" /> : ''}
                  </div>
                  <p className="ml-1 font-PopinsLight text-xs text-gray-400">{t('login.form.rememberMe')}</p>
                </div>
                <Link to="forgetpassword" className="ml-auto cursor-pointer font-PopinsLight text-xs text-gray-400">
                  {t('login.form.forgetPassword')}
                </Link>
              </div>
            </div>

            <OnboardingButton type="submit" className="w-[100%] h-[40px]">
              {t('login.form.button').toString()}
            </OnboardingButton>
          </div>
        </form>
      </div>
      <p className="mt-8 text-center font-PopinsMedium font-light">
        Do you have any questions,feedback or issues? <br />
        Please <span className="font-bold">Contact us</span>
      </p>
    </div>
  );
};

export default LoginContainer;
