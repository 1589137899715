import { ApolloProvider } from '@apollo/client';
import RootContainer from 'containers/RootContainer';
import { BrowserRouter } from 'react-router-dom';
import { client } from 'requests/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation';

const App = () => {
  let preferredLanguage: string | null = localStorage.getItem('preferred_language');
  if (!preferredLanguage) {
    localStorage.setItem('preferred_language', preferredLanguage || 'en');
    preferredLanguage = 'en';
  }
  i18n.changeLanguage(preferredLanguage);
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <RootContainer />
        </I18nextProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
