import Block from 'components/ui/Blocks/Block';
import Card from 'components/ui/Card/Card';
import Title from 'components/ui/Text/Title';

export default function Team() {
  return (
    <div className="m-8">
      <Block>
        <div>
          <div>
            <div>
              <Title title="Team" className="before:bg-blueLight mb-8" />
            </div>
          </div>
          <Card team name="Mejri seyf" email="mejri.Seyf@gmail.com" hierarchy="manager" tel={54521658} />
        </div>
      </Block>
    </div>
  );
}
