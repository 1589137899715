import CustomBtn from 'components/ui/Buttons/CustomBtn';
import DropDownBtn from 'components/ui/Buttons/DropDownBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Title from 'components/ui/Text/Title';
import { BsCurrencyDollar, BsTelephone } from 'react-icons/bs';
import { IoRestaurantOutline } from 'react-icons/io5';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { RiRoadMapLine } from 'react-icons/ri';

export default function AddRestaurantModal({ setShow }: any) {
  if (setShow) {
    //
  }
  return (
    <div className="flex flex-col  w-full">
      <form>
        <div className="flex flex-col gap-[20px]">
          <Title title="Add a new restaurant" className="before:bg-[#FFBC9A]" />
          <div className="flex gap-4">
            <div className="flex flex-col gap-4">
              <InputBtn
                name="restaurant_name"
                className=" border border-gray-300 "
                label="Restaurant name"
                placeholder="Restaurant name"
                type="text"
                Icon={IoRestaurantOutline}
                id=""
              />
              <InputBtn
                name="restaurant_name"
                className="border border-gray-300 "
                label="Restaurant address"
                placeholder="Restaurant address"
                type="text"
                Icon={RiRoadMapLine}
                id=""
              />
              <DropDownBtn label="Currency" placeholder="Pick your Currency" Icon={BsCurrencyDollar} />
            </div>
            <div className="flex flex-col gap-4">
              <InputBtn
                name="restaurant_name"
                className=" border border-gray-300 "
                label="Restaurant phone number"
                placeholder="Restaurant phone number"
                type="text"
                Icon={BsTelephone}
                id=""
              />
              <InputBtn
                name="restaurant_name"
                className=" border border-gray-300 "
                label="Restaurant city"
                placeholder="Restaurant city"
                type="text"
                Icon={MdOutlineMapsHomeWork}
                id="first_name"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <CustomBtn text="Cancel" />
            <CustomBtn text="Save" className="!bg-gray-800 !text-white hover:border-none" />
          </div>
        </div>
      </form>
    </div>
  );
}
