import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Card from 'components/ui/Card/Card';
import Modal from 'components/ui/Modal/Modal';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import { useContext, useState } from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { useGetPointSales } from 'requests/PointSale/request';
import AddRestaurantModal from './AddRestaurantModal';

export default function MyRestaurant() {
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const { data } = useGetPointSales({ variables: { idOwner: user?.id || '' } });

  return (
    <div className="m-8">
      <Block>
        <div>
          <div>
            <div className="flex justify-between">
              <Title title="All my Restaurants" className="before:bg-blueLight mb-8" />
              <CustomBtn
                text="Add new restaurant"
                Icon={GrAddCircle}
                onClick={() => {
                  setShow(true);
                }}
              />
            </div>
          </div>
          {data?.PointSales?.data?.map(
            ({ legal_business_name, registred_address, point_sale_logo, idOwner, currency }) => (
              <Card
                logo={point_sale_logo}
                name={legal_business_name}
                address={registred_address}
                city={registred_address}
                currency={currency}
                tel={idOwner?.phone_number || ''}
              />
            ),
          )}
        </div>
      </Block>
      <Modal
        className=" 3xl:w-[746px] 2xl:w-[600px]"
        onClose={() => {
          setShow(!show);
        }}
        open={show}
      >
        <AddRestaurantModal setShow={setShow} />
      </Modal>
    </div>
  );
}
