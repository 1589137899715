/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable global-require */
import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import ImagePreview from 'components/ui/Preview/ImagePreview';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import { useFormik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsCreditCard2Back } from 'react-icons/bs';
import { CgBriefcase } from 'react-icons/cg';
import { FiCalendar, FiPhoneCall } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import { MdAddAPhoto } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useGetUser, useUpdateUser } from 'requests/Users/request';
import { removeEmptyField } from 'utils/object';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { user, setUser } = useContext(UserContext);
  const [profilePhoto, setprofilePhoto] = useState<any>();
  const [adressPhoto, setadressPhoto] = useState<any>();
  const [identityPhoto, setidentityPhoto] = useState<any>();
  const [edit, setEdit] = useState(false);
  const handleClick = () => {
    setEdit((visible) => !visible);
  };
  const [updateUser, { loading }] = useUpdateUser();
  const { data, refetch } = useGetUser({ variables: { id: user?.id }, fetchPolicy: 'no-cache' });
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      first_name: data?.getSingleUser?.first_name,
      last_name: data?.getSingleUser?.last_name,
      phone_number: data?.getSingleUser?.phone_number,
      job_title: data?.getSingleUser?.job_title,
      date_of_birth: data?.getSingleUser?.date_of_birth,
      address: data?.getSingleUser?.address,
      address_pdf: '',
      password: '',
      identity_card: data?.getSingleUser?.identity_card,
      identity_card_pdf: '',
      user_photo: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (user?.id) {
        const res = await updateUser({ variables: { id: user?.id, ...removeEmptyField(values) } });
        if (res?.data?.updateUser?.id) {
          await refetch();
          toast.success('Update Succesfully');
          setUser({ ...user, user_photo: data?.getSingleUser?.user_photo || user?.user_photo });
        } else toast.error('Update Error');
      } else toast.error('User not found');
    },
  });

  useEffect(() => {
    if (formik?.values?.user_photo) {
      const url = URL?.createObjectURL(formik?.values?.user_photo as any);
      setprofilePhoto(url);
    } else {
      setprofilePhoto('');
    }
    if (formik?.values?.identity_card_pdf) {
      const url = URL?.createObjectURL(formik?.values?.identity_card_pdf as any);
      setidentityPhoto(url);
    } else {
      setidentityPhoto('');
    }
    if (formik?.values?.address_pdf) {
      const url = URL?.createObjectURL(formik?.values?.address_pdf as any);
      setadressPhoto(url);
    } else {
      setadressPhoto('');
    }
  }, [formik?.values?.user_photo, formik?.values?.identity_card_pdf, formik?.values?.address_pdf]);

  return (
    <div className="m-8">
      <Block>
        <div className="flex justify-between items-center">
          <Title title={t('profile.title')} className="before:bg-pink" />
          <CustomBtn
            text={t('profile.buttons.edit').toString()}
            onClick={handleClick}
            className={`${edit ? 'hidden' : 'block'} `}
          />
          {edit && (
            <div className="flex">
              <CustomBtn
                text={t('profile.buttons.cancel').toString()}
                onClick={handleClick}
                className={`${edit ? 'block' : 'hidden'} `}
              />
              <CustomBtn
                loading={loading}
                loadingColor="white"
                text={t('profile.buttons.save').toString()}
                onClick={() => formik?.submitForm()}
                className="!bg-gray-800 !text-white hover:border-none"
              />
            </div>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-between gap-4 mt-6">
            <div className="flex flex-col gap-4 w-full">
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.buttons.form.firstname.placeholder')}
                name="first_name"
                type="text"
                label={t('profile.form.firstname.label')}
                Icon={AiOutlineUser}
                value={formik.values.first_name}
                id="first_name"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.lastname.placeholder')}
                name="last_name"
                type="text"
                label={t('profile.form.lastname.label')}
                Icon={AiOutlineUser}
                value={formik.values.last_name}
                id="last_name"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.phone.placeholder')}
                type="text"
                label={t('profile.form.phone.label')}
                Icon={FiPhoneCall}
                value={formik.values.phone_number}
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.jobTitle.placeholder')}
                type="text"
                label={t('profile.form.jobTitle.label')}
                Icon={CgBriefcase}
                value={formik.values.job_title}
                id="job_title"
                name="job_title"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.dateOfBirth.placeholder')}
                type="date"
                label={t('profile.form.dateOfBirth.label')}
                Icon={FiCalendar}
                value={moment(formik?.values?.date_of_birth).format('DD/MM/YYYY')}
                id="date_of_birth"
                name="date_of_birth"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.address.placeholder')}
                type="text"
                label={t('profile.form.address.label')}
                Icon={IoLocationOutline}
                value={formik.values.address}
                id="address"
                name="address"
                onChange={formik.handleChange}
                disabled={!edit}
              />
            </div>
            <div className="flex flex-col gap-4 w-full">
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.password.placeholder')}
                type="password"
                label={t('profile.form.password.label')}
                Icon={RiLockPasswordLine}
                value={formik?.values?.password}
                id="password"
                name="password"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <InputBtn
                className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                onFocusClassName="border-black"
                placeholder={t('profile.form.idNumber.placeholder')}
                type="text"
                label={t('profile.form.idNumber.label')}
                Icon={BsCreditCard2Back}
                value={formik.values.identity_card}
                id="identity_card"
                name="identity_card"
                onChange={formik.handleChange}
                disabled={!edit}
              />
              <div>
                <div className="flex items-center gap-2 relative">
                  <ImagePreview
                    className=" !max-h-[3rem] rounded-lg"
                    onHoverText=""
                    alt="qsdqs"
                    width={100}
                    src={profilePhoto || data?.getSingleUser?.user_photo}
                  />
                  <OnboadringUploadBtn
                    disabled={!edit}
                    LeftIcon={MdAddAPhoto}
                    accept=".jpg,.pdf"
                    placeholder={t('profile.form.profilePicture.placeholder').toString()}
                    text={t('profile.form.profilePicture.description').toString()}
                    id="user_photo"
                    onChange={(event) => formik?.setFieldValue('user_photo', event.target.files[0])}
                    className={` previewww ${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2 relative">
                  <ImagePreview
                    className=" !max-h-[3rem] rounded-lg"
                    onHoverText=""
                    alt="qsdqs"
                    width={100}
                    src={identityPhoto || data?.getSingleUser?.identity_card_pdf}
                  />
                  <OnboadringUploadBtn
                    disabled={!edit}
                    accept=".jpg,.pdf"
                    placeholder={t('profile.form.identityCard.placeholder').toString()}
                    text={t('profile.form.identityCard.description').toString()}
                    id="identityFile"
                    onChange={(event) => formik?.setFieldValue('identity_card_pdf', event.target.files[0])}
                    className={` previewww ${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center gap-2 relative">
                  <ImagePreview
                    className=" !max-h-[3rem] rounded-lg"
                    onHoverText=""
                    alt="qsdqs"
                    width={100}
                    src={adressPhoto || data?.getSingleUser?.address_pdf}
                  />
                  <OnboadringUploadBtn
                    disabled={!edit}
                    accept=".jpg,.pdf"
                    placeholder={t('profile.form.addressDocument.placeholder').toString()}
                    text={t('profile.form.addressDocument.description').toString()}
                    id="adressFile"
                    onChange={(event) => formik?.setFieldValue('address_pdf', event.target.files[0])}
                    className={`${!edit ? 'text-gray-500 border' : 'border-gray-300 border'}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Block>
    </div>
  );
}
