/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import Loading from 'components/ui/Loading';
import { useFormik } from 'formik';
import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useResetPassword, useVerifCode } from 'requests/auth';
import * as yup from 'yup';

interface Props {
  nextPage?: () => void;
  email?: string;
  setidUser: (v: string) => void;
}

export default function ForgetPasswordcode({ nextPage, email, setidUser }: Props) {
  const refs = useRef<HTMLInputElement[]>([]);
  const [verifCode, { loading }] = useVerifCode();
  const [resetPassword] = useResetPassword();

  const loginSchema = yup.object({
    digit0: yup.number().required('This field is required'),
    digit1: yup.number().required('This field is required'),
    digit2: yup.number().required('This field is required'),
    digit3: yup.number().required('This field is required'),
  });
  const formik = useFormik({
    initialValues: {
      digit0: '',
      digit1: '',
      digit2: '',
      digit3: '',
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const { digit0, digit1, digit2, digit3 } = values;
      const code = [digit0, digit1, digit2, digit3].join('');
      if (email) {
        verifCode({ variables: { code, email } }).then(({ data, errors }) => {
          if (!errors && code === data?.verifCodeReset.code) {
            setidUser(data?.verifCodeReset.idUser);
            if (nextPage) {
              nextPage();
            }
          } else {
            toast.error((errors as any)?.message);
          }
        });
      } else {
        toast.error('No Email Found, please refresh Page');
      }
    },
  });

  const handleChange = (e: any, index: number) => {
    const d = `digit${index}`;
    const d1 = `digit${index + 1}`;
    const val = e?.target?.value;
    if (val.length === 2) {
      if (index < 3) {
        formik.setFieldValue(d1, val[1]);
        refs.current[index + 1].focus();
      }
    } else if (val.length === 1) {
      formik.setFieldValue(d, val);
    } else {
      formik.setFieldValue(d, val);
      refs.current[index > 0 ? index - 1 : index].focus();
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center">
        <h1 className="mb-4 text-center font-PopinsMedium text-3xl">Forget Password</h1>
        <p className="mb-8 font-PopinsMedium text-gray-500 relative text-center">Please enter the code</p>
        <div className="w-full flex flex-col gap-8">
          <div>
            <div className="flex flex-row gap-4 justify-center">
              {Object.keys(formik.values).map((el: string, index) => (
                <input
                  type="number"
                  className="bg-[transparent] rounded-xl border-[1px] border-gray-300 focus:border-black font-medium font-PopinsMedium focus-visible:outline-none text-sm w-[50px] h-[56px] text-center"
                  value={(formik.values as { [k: string]: string })[el]}
                  key={el}
                  ref={(element) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    element ? (refs.current[index] = element) : undefined;
                  }}
                  onChange={(e) => handleChange(e, index)}
                />
              ))}
            </div>
            {formik.errors?.digit0 || formik.errors?.digit1 || formik.errors?.digit2 || formik.errors?.digit3 ? (
              <p className="fixed text-xs my-2 font-PopinsMedium -translate-y-1/2 text-red-500 text-center w-full -translate-x-8">
                Please enter a valid code
              </p>
            ) : null}
          </div>
          {/* eslint-disable-next-line */}
          <div onClick={() => console.log(email, formik.errors)}>
            <p
              onClick={() => resetPassword({ variables: { email } })}
              className="text-center cursor-pointer text-gray-600 hover:text-black hover:text hover:underline text-underline-offset-8"
            >
              Resend code
            </p>
          </div>

          {loading ? (
            <Loading size="sm" />
          ) : (
            <OnboardingButton type="submit" className="w-[100%] h-[40px]">
              Verify
            </OnboardingButton>
          )}
        </div>
      </div>
    </form>
  );
}
