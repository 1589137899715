import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import BlockGray from 'components/ui/Blocks/BlockGray';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Title from 'components/ui/Text/Title';
import RatingList from 'containers/Rating/RatingList';
import UserContext from 'contexts/UserContext';
import moment from 'moment';
import { useContext, useRef } from 'react';
import { AiOutlineStar, AiOutlineUser } from 'react-icons/ai';
import { BiLineChart } from 'react-icons/bi';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useGetPointSales } from 'requests/PointSale/request';
import { useGetTickets } from 'requests/Tickets/request';
import { useGetWaiters } from 'requests/Users/request';
import { useTranslation } from 'react-i18next';
import ValetOverview from './ValetOverview';

export default function Overview() {
  const { user } = useContext(UserContext);

  const { data: staff } = useGetWaiters({ variables: { idPOS: user?.idPOS }, fetchPolicy: 'no-cache' });
  const { data: point } = useGetPointSales({ variables: { idOwner: user?.id || '' } });
  const { data: tickets, loading } = useGetTickets({
    fetchPolicy: 'no-cache',
    variables: { id_pointSale: user?.idPOS, status: 'CLOSED' },
    skip: !user?.idPOS,
  });
  const CurrencyFormatter = new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'AED' });
  const numRating = tickets?.Orders?.data?.length || 1;
  const sum = tickets?.Orders?.data.reduce((t, r) => t + r?.rating, 0) || 0;
  const grossRevenue = tickets?.Orders?.data.reduce((t, r) => t + Number(r?.total_amount), 0) || 0;
  const taxes = grossRevenue * 0.05;
  const tips = tickets?.Orders?.data.reduce((t, r) => t + Number(r?.tip_amount), 0) || 0;

  const { t } = useTranslation();
  const RATING_NUMBERS = [
    {
      Icon: BiLineChart,
      title: t('overview.kpis.KPI_4'),
      text: `${(sum / numRating).toFixed(2)}/5`,
      className: 'bg-pink',
    },
    {
      Icon: AiOutlineStar,
      title: t('overview.kpis.KPI_5'),
      text: `${numRating}`,
      className: 'bg-purple',
    },
    {
      Icon: AiOutlineUser,
      title: t('overview.kpis.KPI_6'),
      text: `${numRating}`,
      className: 'bg-purple',
    },
  ];

  const ref = useRef<any>();

  if (user?.role === 'valet') return <ValetOverview />;

  return (
    <div className="m-8 flex xl:flex-[1] gap-8 flex-wrap">
      <Block className="flex-[0.8] xl:flex-[1]   ">
        <div>
          <Title title={t('overview.restaurants.title')} className="before:bg-orange" />
          <div>
            <p>{t('overview.restaurants.action')}</p>
          </div>
          <div className="relative">
            <div
              style={{ scrollbarWidth: 'none' }}
              ref={ref}
              className=" scrollOverview flex items-center justify-between mt-8 scroll-smooth gap-8 overflow-x-scroll   "
            >
              {point?.PointSales?.data?.map((e) => {
                return (
                  <div className="flex flex-col justify-center w-24">
                    <div className="bg-blueLight w-16 h-16 flex justify-center items-center rounded-xl">
                      <img src={e?.point_sale_logo} width={30} height={30} alt="log" />
                    </div>
                    <div className="">{e?.name || 'No name available'}</div>
                  </div>
                );
              })}
            </div>
            <BsArrowRightCircle
              onClick={() => ref.current.scrollBy({ left: 60, behaviour: 'smooth' })}
              className="absolute hover:text-red-300 cursor-pointer -right-7 top-10 text-[25px]"
            />
            <BsArrowLeftCircle
              onClick={() => ref.current.scrollBy({ left: -60, behaviour: 'smooth' })}
              className="absolute hover:text-red-300 cursor-pointer -left-7 top-10 text-[25px]"
            />
          </div>
        </div>

        <BlockGray className="flex flex-row justify-between xl:flex-col xl:items-center xl:gap-4">
          <div className="text-center">
            <h1 className="font-PopinsMedium text-gray-600">
              {t('overview.kpis.KPI_1').substring(0, t('overview.kpis.KPI_1').indexOf('('))}
              <span className="text-[12px]">
                {t('overview.kpis.KPI_1').substring(
                  t('overview.kpis.KPI_1').indexOf('('),
                  t('overview.kpis.KPI_1').indexOf(')') + 1,
                )}
              </span>
            </h1>
            <p className="font-bold text-3xl font-PopinsMedium"> {CurrencyFormatter.format(grossRevenue)}</p>
          </div>
          <div className="text-center">
            <h1 className="font-PopinsMedium text-gray-600"> {t('overview.kpis.KPI_2')}</h1>
            <p className="font-bold text-3xl font-PopinsMedium"> {CurrencyFormatter.format(taxes)}</p>
          </div>
          <div className=" text-center">
            <h1 className="font-PopinsMedium text-gray-600"> {t('overview.kpis.KPI_3')}</h1>
            <p className="font-bold text-3xl font-PopinsMedium"> {CurrencyFormatter.format(tips)}</p>
          </div>
        </BlockGray>
        <BlockGray className="flex justify-between xl:flex-col xl:items-center xl:gap-10">
          {RATING_NUMBERS.map((items) => {
            return <RatingList {...items} />;
          })}
        </BlockGray>
        <Title title={t('overview.reviews.title')} className="before:bg-orange mt-8" />
        <List<any>
          loading={loading}
          canUpdate={false}
          canCreate={false}
          canDelete={false}
          totalPages={0}
          onPageChange={() => {}}
          currentPage={Number(/* search.page */) || 1}
          data={tickets?.Orders.data.filter((_, index) => index < 5) || []}
          headers={[
            {
              title: t('overview.reviews.table.column1').toString(),
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (_, { createdAt }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`${moment(
                  createdAt,
                ).format('ddd, MMMM Do - h:mm a')}`}</p>
              ),
            },
            {
              title: t('overview.reviews.table.column2').toString(),
              dataIndex: 'rating',
              key: 'rating',
              render: (value, { rating }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden flex items-center">
                  {[...new Array(rating)].map(() => (
                    <AiOutlineStar fill="gold" />
                  ))}
                </p>
              ),
            },
          ]}
        />
        <div className="flex justify-end">
          <Link to="/rating">
            <CustomBtn
              text={t('overview.reviews.button').toString()}
              className="!bg-warmGray-800 !text-white hover:border-none"
            />
          </Link>
        </div>
      </Block>
      <Block className="h-auto 2lx:h-[80vh] flex-[0.2] xl:flex-[1] min-w-[400px]">
        <Title title={t('overview.users.title')} className="before:bg-purple " />
        <List<any>
          canUpdate={false}
          canCreate={false}
          canDelete={false}
          totalPages={0}
          onPageChange={() => {}}
          currentPage={Number(/* search.page */) || 1}
          data={staff?.getUsers.filter((e) => e.role === 'waiter') || []}
          headers={[
            {
              title: t('overview.users.table.column1').toString(),
              dataIndex: 'first_name',
              key: 'id',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
              ),
            },
            {
              title: t('overview.users.table.column2').toString(),
              dataIndex: '',
              key: 'id',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
              ),
            },
          ]}
        />
        <div className="flex justify-end">
          <CustomBtn
            text={t('overview.users.button').toString()}
            className="!bg-warmGray-800 !text-white hover:border-none"
          />
        </div>
      </Block>
    </div>
  );
}
