import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Loading from 'components/ui/Loading';
import Modal from 'components/ui/Modal/Modal';
import Title from 'components/ui/Text/Title';
import ModalConfirmation from 'containers/Menu/ModalConfirmation';
import UserContext from 'contexts/UserContext';
import { useContext, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { RiFileAddLine } from 'react-icons/ri';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteUser, useGetWaiters, useUpdateUser } from 'requests/Users/request';
import { decodeUri } from 'utils/url';
import { useTranslation } from 'react-i18next';
import List from '../../components/crud/List';
import AddUserModal from './AddUserModal';
import ModalUpdateUser from './ModalUpdateUser';

export default function Waiters() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [modalUdate, setmodalUdate] = useState(false);
  const [confirmation, setconfirmation] = useState(false);
  const [roleStatus, setroleStatus] = useState('user');

  const location = useLocation();
  const history = useHistory();

  const {
    data: staff,
    loading,
    refetch: refetch2,
  } = useGetWaiters({ variables: { role: 'manager', idPOS: user?.idPOS }, fetchPolicy: 'no-cache' });
  const [deleteUser, { loading: deletingLoading }] = useDeleteUser();

  const { waiterId } = decodeUri(location?.search);

  const [updateUser, { error, loading: updateLoading }] = useUpdateUser();

  const handleDelete = async () => {
    if (waiterId) {
      const { data } = await deleteUser({ variables: { id: waiterId } });
      if (data?.deleteUser) {
        toast?.success('user deleted');
        setconfirmation(false);
        refetch2();
      }
    }
  };

  return (
    <div className="m-8 flex flex-col gap-4">
      <p className="text-warmGray-800 text-[34px] font-PopinsMedium">{t('myStaff.pageTitle')}</p>
      <Block className="p-4 rounded-lg !bg-white flex flex-col gap-4">
        {loading ? (
          <div className="min-h-[150px] grid place-content-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <Title className="before:bg-[#FFD88D] font-PopinsMedium text-2xl" title={t('myStaff.table1.title')} />
              <div>
                <CustomBtn
                  onClick={() => {
                    setShow(true);
                    setroleStatus('waiter');
                  }}
                  Icon={RiFileAddLine}
                  text={t('myStaff.table1.button').toString()}
                />
              </div>
            </div>
            <List<any>
              canUpdate={false}
              canCreate={false}
              canDelete={false}
              totalPages={0}
              onPageChange={() => {}}
              currentPage={Number(/* search.page */) || 1}
              data={staff?.getUsers.filter((e) => e?.role === 'waiter') || []}
              headers={[
                {
                  title: t('myStaff.table1.column1').toString(),
                  dataIndex: 'first_name',
                  key: 'first_name',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table1.column2').toString(),
                  dataIndex: 'last_name',
                  key: 'last_name',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table1.column3').toString(),
                  dataIndex: 'role',
                  key: 'role',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table1.column4').toString(),
                  dataIndex: 'email',
                  key: 'email',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table1.column5').toString(),
                  dataIndex: 'email',
                  key: 'detail',
                  render: (_, { id }) => (
                    <div className="flex gap-4">
                      <button
                        onClick={() => {
                          setmodalUdate(true);
                          history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                      >
                        <MdDriveFileRenameOutline />
                        {}
                      </button>
                      <button
                        onClick={() => {
                          setconfirmation(true);
                          history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                      >
                        <FiTrash2 />
                        {}
                      </button>
                    </div>
                  ),
                },
              ]}
            />
          </>
        )}
      </Block>
      <Block className="p-4 rounded-lg !bg-white flex flex-col gap-4">
        {loading ? (
          <div className="min-h-[150px] grid place-content-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <Title className="before:bg-[#CABDFF] font-PopinsMedium text-2xl" title={t('myStaff.table2.title')} />
              <div>
                <CustomBtn
                  onClick={() => {
                    setShow(true);
                    setroleStatus('manager');
                  }}
                  Icon={RiFileAddLine}
                  text={t('myStaff.table2.button').toString()}
                />
              </div>
            </div>{' '}
            <List<any>
              canUpdate={false}
              canCreate={false}
              canDelete={false}
              totalPages={0}
              onPageChange={() => {}}
              currentPage={Number(/* search.page */) || 1}
              data={staff?.getUsers.filter((e) => e?.role === 'manager') || []}
              headers={[
                {
                  title: t('myStaff.table2.column1').toString(),
                  dataIndex: 'first_name',
                  key: 'id',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table2.column2').toString(),
                  dataIndex: 'last_name',
                  key: 'last_name',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table2.column3').toString(),
                  dataIndex: 'role',
                  key: 'role',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table2.column4').toString(),
                  dataIndex: 'email',
                  key: 'email',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: t('myStaff.table2.column5').toString(),
                  dataIndex: 'email',
                  key: 'detail',
                  render: (_, { id }) => (
                    <div className="flex gap-4">
                      <button
                        onClick={() => {
                          setmodalUdate(true);
                          history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                      >
                        <MdDriveFileRenameOutline />
                        {}
                      </button>
                      <button
                        onClick={() => {
                          setconfirmation(true);
                          history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                      >
                        <FiTrash2 />
                        {}
                      </button>
                    </div>
                  ),
                },
              ]}
            />
          </>
        )}
      </Block>
      <Modal
        className=" 3xl:w-[746px] 2xl:w-[600px]"
        onClose={() => {
          setShow(!show);
          history.replace({ search: `` });
        }}
        open={show}
      >
        <AddUserModal
          refetch={() => {
            refetch2();
          }}
          role={roleStatus}
          setShow={setShow}
        />
      </Modal>
      <ModalConfirmation
        show={confirmation}
        setShow={setconfirmation}
        onClose={() => {
          history.replace({ search: `` });
        }}
        loading={deletingLoading}
        title="Delete ?"
        text="Are you sure you want to delete this user?"
        onConfirm={() => handleDelete()}
      />

      <ModalUpdateUser
        show={modalUdate}
        setShow={setmodalUdate}
        error={error}
        loading={updateLoading}
        onUpdate={async (values) => {
          const res = await updateUser({ variables: values });

          if (res?.data?.updateUser?.id) {
            refetch2();
            setmodalUdate(false);
            history.replace({ search: `` });
          } else {
            toast.error('Update Error');
          }
        }}
      />
    </div>
  );
}
