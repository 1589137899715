import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import React from 'react';
import { BsCheck2 } from 'react-icons/bs';

interface Props {
  setNext: () => void;
}

export default function OnboardingPage1Layout({ setNext }: Props) {
  return (
    <div className="flex flex-col items-center mb-12">
      <h1 className="mb-4 text-center font-PopinsMedium text-3xl">Welcome!</h1>
      <p className="mb-12 font-PopinsMedium text-gray-500">
        You will need all the informations below to complete the onboarding. No worries if you're missing anything.
      </p>
      <div className="flex justify-center min-h-[14rem]">
        <div className="flex">
          <div className="relative">
            <div className="flex items-center">
              <div className="flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium bg-[#FFD88D] color">
                1
              </div>
              <p className="ml-2 font-PopinsMedium">Main owner info</p>
            </div>
            <div className="ml-10 mt-6 absolute min-w-[14rem]">
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Name, job title, date of birth
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Proof of adress document
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Identity card
              </div>
            </div>
          </div>
          <div className="border-gray-300 mx-3 border-t-2 translate-y-2/4 w-[150px] h-8" />
          <div className="relative">
            <div className="flex items-center">
              <div className="flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium bg-[#B1E5FE]">
                2
              </div>
              <p className="ml-2 font-PopinsMedium">Business info</p>
            </div>
            <div className="ml-10 mt-6 absolute min-w-[14rem]">
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Business structure
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Legale business name
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Taw ID Number
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Regestred adress
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Iban to which we should wire the payments
              </div>
            </div>
          </div>
          <div className="border-gray-300 mx-3 border-t-2 translate-y-2/4 w-[150px] h-8" />
          <div className="relative">
            <div className="flex items-center">
              <div className="flex justify-center items-center rounded-full w-8 h-8 font-PopinsMedium bg-[#CABDFF]">
                3
              </div>
              <p className="ml-2 font-PopinsMedium">Point of sale (POS) info</p>
            </div>
            <div className="ml-10 mt-6 absolute min-w-[14rem]">
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Brand of the POS
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Login credentials of the POS
              </div>
              <div className="flex text-xs font-medium font-PopinsMedium">
                <p className="mt-1 text-lg -translate-y-1 mr-3">
                  <BsCheck2 />
                </p>{' '}
                Table plan (screenshots are fine)
              </div>
            </div>
          </div>
        </div>
      </div>
      <OnboardingButton onClick={setNext} className="w-[30%] h-[40px]">
        Start
      </OnboardingButton>
    </div>
  );
}
