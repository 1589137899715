import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';
import i18n from 'translation';

interface Props {
  date?: Date;
  placeholder?: string;
  setDate: (date: Date | undefined) => void;
  Icon?: IconType;
  className?: string;
}
export default function DatePickerBtn({ date, setDate, Icon, placeholder, className }: Props) {
  const focusDate = (e: any) => e?.target?.parentElement?.firstChild.firstChild.firstChild.focus();
  console.log(i18n.language);
  return (
    <div className="relative">
      <DatePicker
        placeholderText={placeholder}
        className={`h-12 bg-[transparent] border border-black px-4 rounded-xl placeholder-black ${className}`}
        selected={date || undefined}
        onChange={(pickedDate: Date) => setDate(pickedDate)}
        locale="fr"
      />
      {date ? (
        <IoClose
          onClick={() => setDate(undefined)}
          className="absolute right-2 top-4  hover:bg-gray-300 cursor-pointer rounded-full"
          width={30}
        />
      ) : (
        <FiCalendar
          onClick={(e) => focusDate(e)}
          className="absolute right-2 top-4  hover:bg-gray-300 cursor-pointer rounded-full"
          width={30}
        />
      )}
      {Icon && (
        <div className="absolute bottom-2 right-4">
          <Icon className="text-[20px]" />
        </div>
      )}
    </div>
  );
}
DatePickerBtn.defaultProps = {
  date: undefined,
  Icon: undefined,
  placeholder: 'Today',
};
