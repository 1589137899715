import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { User } from 'requests/types';

export const useGetWaiters = (options: QueryHookOptions<{ getUsers: User[] }, {}> = {}) => {
  const USERS_QUERY = gql`
    query getUsers($role: String, $idPOS: String) {
      getUsers(role: $role, idPOS: $idPOS) {
        id
        role
        email
        first_name
        last_name
        job_title
        user_photo
      }
    }
  `;

  return useLocalQuery(USERS_QUERY, options);
};

export const useGetManagers = (options: QueryHookOptions<{ getUsers: User[] }, {}> = {}) => {
  const USERS_QUERY = gql`
    query getUsers($role: String) {
      getUsers(role: $role) {
        id
        role
        email
        first_name
        last_name
        job_title
        user_photo
      }
    }
  `;

  return useLocalQuery(USERS_QUERY, options);
};

export const useUpdateUser = (options: MutationHookOptions<{ updateUser: User }> = {}) => {
  const USER_UPDATE = gql`
    mutation updateUser(
      $id: ID!
      $first_name: String
      $last_name: String
      $role: Role
      $job_title: String
      $date_of_birth: DateTime
      $phone_number: String
      $address: String
      $password: String
      $address_pdf: Upload
      $identity_card_pdf: Upload
      $user_photo: Upload
      $identity_card: String
      $first_cnx: Boolean
    ) {
      updateUser(
        id: $id
        first_name: $first_name
        last_name: $last_name
        role: $role
        job_title: $job_title
        date_of_birth: $date_of_birth
        phone_number: $phone_number
        address: $address
        password: $password
        address_pdf: $address_pdf
        identity_card_pdf: $identity_card_pdf
        identity_card: $identity_card
        user_photo: $user_photo
        first_cnx: $first_cnx
      ) {
        id
        identity_card_pdf
        address_pdf
        user_photo
        first_cnx
      }
    }
  `;
  return useLocalMutation(USER_UPDATE, options);
};
export const useAddStaff = (options: MutationHookOptions<{ addStaff: User }> = {}) => {
  const USER_UPDATE = gql`
    mutation addStaff(
      $email: String
      $first_name: String
      $last_name: String
      $role: Role
      $phone_number: String
      $idPOS: ID
    ) {
      addStaff(
        email: $email
        first_name: $first_name
        last_name: $last_name
        role: $role
        phone_number: $phone_number
        idPOS: $idPOS
      ) {
        id
        last_name
      }
    }
  `;
  return useLocalMutation(USER_UPDATE, options);
};

export const useDeleteUser = (options: MutationHookOptions<{ deleteUser: string }, { id: string }> = {}) => {
  const MUTATION = gql`
    mutation deleteUser($id: ID!) {
      deleteUser(id: $id)
    }
  `;
  return useLocalMutation(MUTATION, options);
};

export const useGetUser = (options: QueryHookOptions<{ getSingleUser: User }, {}> = {}) => {
  const USERS_QUERY = gql`
    query getSingleUser($id: ID!) {
      getSingleUser(id: $id) {
        id
        role
        email
        first_name
        last_name
        phone_number
        job_title
        date_of_birth
        address
        address_pdf
        identity_card
        identity_card_pdf
        user_photo
      }
    }
  `;

  return useLocalQuery(USERS_QUERY, options);
};
