import { BiMap } from 'react-icons/bi';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { GrCurrency, GrMapLocation } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import ImagePreview from '../Preview/ImagePreview';

interface Props {
  logo?: string;
  name?: string;
  tel?: string | number;
  address?: string;
  currency?: string;
  city?: string;
  idPOS?: string;
}

export default function CardCaisse({ logo, name, tel, address, currency, city, idPOS }: Props) {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        history.push(`/tables/${idPOS}`);
      }}
      className="overflow-hidden rounded-2xl p-2 hover:shadow-card duration-200 cursor-pointer"
    >
      <div className="bg-gray-100 border-2  flex justify-start rounded-2xl p-4 w-full gap-12 h-[200px] ">
        <div className="w-[5rem] flex justify-center">
          <ImagePreview src={logo} className="!w-[5rem] border rounded-2xl border-gray-700" alt="" />
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-semibold text-ellipsis overflow-hidden w-[212px]  whitespace-nowrap ">{name}</h1>
          <div className="flex items-center gap-4">
            <BiMap />
            {address}
          </div>
          <div className="flex items-center gap-4">
            <BsFillTelephoneFill />
            {tel}
          </div>
          <div className="flex items-center gap-4">
            <GrMapLocation />
            {city}
          </div>
          <div className="flex items-center gap-4">
            <GrCurrency />
            {currency}
          </div>
        </div>
      </div>
    </div>
  );
}

CardCaisse.defaultProps = {
  name: '',
  tel: undefined,
  address: '',
  currency: '',
  city: '',
};
