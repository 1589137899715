import { IconType } from 'react-icons/lib';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  Icon?: IconType;
  text?: any;
  link?: string;
  tabletSize?: boolean;
}

export default function SideBarMainBtn({ Icon, text, link, tabletSize }: Props) {
  const current = useLocation().pathname === link;

  return (
    <Link to={{ pathname: link }}>
      <button
        className={`animate-fadein flex justify-between outline-none items-center min-w-auto w-[-moz-available] w-[-webkit-fill-available] ${
          !tabletSize && 'px-8'
        } py-4 rounded-lg text-lg ${current && 'bg-warmGray-200'}  hover:bg-[#B1E5FE] duration-400`}
      >
        {Icon && (
          <div className={`w-[50px] flex justify-center ${tabletSize ? '' : 'animate-slide'}`}>
            <Icon className="text-[20px]" />
          </div>
        )}
        {text && (
          <p
            className={`flex-1 duration-200 font-PopinsMedium  ${
              tabletSize ? ' opacity-0 hidden' : 'animate-fadein min-w-[100px]'
            } ${Icon ? 'text-left' : 'text-center'}`}
          >
            {text}
          </p>
        )}
      </button>
    </Link>
  );
}
SideBarMainBtn.defaultProps = {
  text: undefined,
  Icon: undefined,
  link: String,
  tabletSize: undefined,
};
