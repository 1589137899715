import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import { useContext } from 'react';
import { IoIosLogIn } from 'react-icons/io';
import { IoTicket } from 'react-icons/io5';
import { VscVersions } from 'react-icons/vsc';
import { useGetPointSale } from 'requests/PointSale/request';
import { useTranslation } from 'react-i18next';

export default function PosInfo() {
  const { user } = useContext(UserContext);
  const { data, loading } = useGetPointSale({ variables: { idOwner: user?.id, id: user?.idPOS } });
  const { t } = useTranslation();
  return (
    <div className="m-8">
      <Block className="bg-white" loading={loading}>
        <div>
          <Title title={t('POS.pageTitle')} className="before:bg-[#FFBC9A]" />
          <div className="my-4 grid grid-cols-2 grid-rows-2 gap-x-6 gap-y-4">
            <InputBtn
              Icon={IoTicket}
              label={t('POS.input1.label')}
              type="text"
              placeholder={t('POS.input1.placeholder')}
              className="bg-[#F7F7F7] hover:border-none"
              value={data?.getPointSale?.pos_informations?.pos_brand}
            />
            <InputBtn
              Icon={VscVersions}
              label={t('POS.input2.label')}
              type="text"
              placeholder={t('POS.input2.placeholder')}
              className="bg-[#F7F7F7] hover:border-none"
              value={data?.getPointSale?.pos_informations?.email}
            />
            <InputBtn
              Icon={IoIosLogIn}
              label={t('POS.input3.label')}
              type="text"
              placeholder={t('POS.input3.placeholder')}
              className="bg-[#F7F7F7] hover:border-none"
              value={data?.getPointSale?.pos_informations?.table_plan}
            />
            <InputBtn
              Icon={VscVersions}
              label={t('POS.input4.label')}
              type="text"
              placeholder={t('POS.input4.placeholder')}
              className="bg-[#F7F7F7] hover:border-none"
              value={data?.getPointSale?.pos_informations?.password}
            />
          </div>
          <div className="flex justify-end">
            <CustomBtn text={t('POS.cancelButton').toString()} />
            <CustomBtn
              text={t('POS.saveButton').toString()}
              className="!bg-warmGray-800 !text-white hover:border-none"
            />
          </div>
        </div>
      </Block>
    </div>
  );
}

PosInfo.defaultProps = {
  userID: '',
};
