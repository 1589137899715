/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import Modal from 'components/ui/Modal/Modal';
import ImagePreview from 'components/ui/Preview/ImagePreview';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { MdOutlineDescription, MdOutlineFormatListBulleted } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useGetMenu, useUpdateMenu } from 'requests/Menus/request';
import * as Yup from 'yup';

interface Props {
  id: string;
  show: boolean;
  setShow: (v: boolean) => void;
}

export default function ModalUpdateMenu({ show, setShow, id }: Props) {
  const history = useHistory();
  const [updateMenu, { loading }] = useUpdateMenu({});
  const [fileUpload, setfileUpload] = useState<any>();

  const [previewImage, setpreviewImage] = useState<string>();

  const { data } = useGetMenu({ variables: { id } });

  const formik = useFormik({
    initialValues: {
      description: data?.Menu?.description,
      category: data?.Menu?.category,
      image: data?.Menu?.image,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      description: Yup.string().required('This field is required'),
      category: Yup.string().required('This field is required'),
      image: Yup.string().required('This field is required'),
    }),
    onSubmit: (values) => {
      const valuess: any = values;
      if (fileUpload) valuess.newimage = fileUpload;
      updateMenu({ variables: { ...valuess, id } }).then(({ data: response }) => {
        if (response?.updateMenu) {
          setShow(false);
          formik?.resetForm();
        }
      });
      console.log({ values }); // eslint-disable-line
    },
  });

  useEffect(() => {
    if (fileUpload) {
      const url = URL?.createObjectURL(fileUpload as any);
      setpreviewImage(url);
    } else {
      setpreviewImage('');
    }
  }, [fileUpload]);

  return (
    <div>
      <Modal
        onClose={() => {
          setShow(!show);
          history.replace({ search: `` });
        }}
        open={show}
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div>
              <h1 className="text-2xl">Update menu</h1>
            </div>
            <div className="flex flex-col gap-4 my-4">
              <InputBtn
                className={`border-2 ${
                  formik.errors?.description && formik.touched?.description ? 'border-red-500' : 'border-gray-300'
                }`}
                onFocusClassName="border-black"
                placeholder="Enter your Description"
                type="text"
                label="Description"
                Icon={MdOutlineDescription}
                onChange={formik.handleChange}
                value={formik.values.description}
                id="description"
              />
              <InputBtn
                onFocusClassName="border-black"
                type="text"
                Icon={MdOutlineFormatListBulleted}
                label="Category"
                placeholder="Choose your category"
                onChange={formik.handleChange}
                value={formik.values.category}
                id="category"
              />{' '}
              <div>
                <OnboadringUploadBtn
                  accept=".jpg,.pdf"
                  placeholder="Upload your menu"
                  text={
                    formik.values.image !== undefined
                      ? formik.values.image
                      : "The file must be JPG or PDF and don't exeed 5MO"
                  }
                  onChange={(event) => setfileUpload(event.target.files[0])}
                  id="adressFile"
                  className={formik.errors?.image && formik.touched?.image ? 'border-red-500' : ''}
                />
                {formik.errors?.image && formik.touched?.image ? (
                  <p className="fixed text-xs mx-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                    {formik.errors?.image}
                  </p>
                ) : null}
                {fileUpload ? (
                  <div className="w-full flex items-center justify-end hover:text-red-500 ">
                    <FiTrash2 />
                    <p
                      onClick={() => setfileUpload(undefined)}
                      className="hover:underline w-[fit-content] cursor-pointer text-sm  text-right"
                    >
                      cancel
                    </p>
                  </div>
                ) : null}
                <ImagePreview
                  onHoverText="Preview the menu"
                  className="p-3 "
                  width="200px"
                  height="20px"
                  src={previewImage || formik?.values?.image}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <CustomBtn onClick={() => setShow(false)} text="Cancel" />
              <CustomBtn
                loading={loading}
                loadingColor="white"
                text="Update"
                className="!bg-warmGray-800 !text-white hover:border-none"
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
