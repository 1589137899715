/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* import Table from 'components/ui/Buttons/Table'; */
import Block from 'components/ui/Blocks/Block';
import DatePickerBtnWaiter from 'components/ui/Buttons/DatePickerBtn';
import DropDownBtnWaiter, { WaiterLabel } from 'components/ui/Buttons/DropDownBtnWaiter';
import Loading from 'components/ui/Loading';
import Modal from 'components/ui/Modal/Modal';
import Search from 'components/ui/Search/Search';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import moment from 'moment';
import { useContext, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { MdDateRange } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useGetTickets } from 'requests/Tickets/request';
import { Ticket } from 'requests/Tickets/types';
import { peoples } from 'static/people';
import { useTranslation } from 'react-i18next';
import List from '../../components/crud/List';
import DetailLayout from './DetailLayout';

const random = (n: number) => Math.floor(Math.random() * n);

export default function TicketLayout() {
  const { t } = useTranslation();

  const SEARCH_INPUT = [
    {
      placeholder: t('ticket.filters.item1'),
      Icon: BsSearch,
    },
  ];
  const history = useHistory();

  const [search, setsearch] = useState('');
  const [show, setShow] = useState(false);
  const [date, setDate] = useState<Date | undefined>();
  const [selected, setSelected] = useState<WaiterLabel>({ id: 'all', fullName: t('ticket.filters.item3').toString() });
  const [currentOrder, setCurrentOrder] = useState<Ticket>();
  const { user } = useContext(UserContext);
  const { data: tickets, loading } = useGetTickets({
    fetchPolicy: 'no-cache',
    variables: { id_pointSale: user?.idPOS },
    skip: !user?.idPOS,
  });

  // const { data: tables } = useGetTable({ variables: { idPointSale: user?.idPOS || '' }, skip: !user?.idPOS });

  const filterFunction = (e: any) => {
    return search
      ? e?.id.includes(search) ||
          `${e?.waiter_id?.first_name.toLowerCase()} ${e?.waiter_id?.last_name.toLowerCase()}`.includes(
            search.toLowerCase(),
          )
      : selected && selected?.id !== 'all'
      ? e?.waiter_id?.id === selected?.id
      : true;
  };
  const dateFilterFunction = (e: any) => {
    return e && date ? new Date(e.createdAt).toDateString() === date.toDateString() : true;
  };

  const waitersList = tickets?.Orders?.data.map((e) => ({
    id: e?.waiter_id?.id,
    fullName: `${e?.waiter_id?.first_name} ${e?.waiter_id?.last_name}`,
    avatar: peoples[random(peoples?.length)]?.avatar || '',
  }));

  const filtred: WaiterLabel[] =
    [...new Set(waitersList?.map((e: WaiterLabel) => e.id))]
      .map((userid: string) => waitersList!.find((e: WaiterLabel) => e.id === userid)!)
      .filter((e) => e!) || [];
  return (
    <div className="m-8">
      <p className="text-warmGray-800 text-2xl">{t('ticket.pageTitle')}</p>

      <div className="flex flex-col gap-6">
        <Block className="p-4 mt-4 rounded-lg !bg-white flex flex-col gap-4">
          {loading ? (
            <div className="min-h-[150px] grid place-content-center">
              <Loading />
            </div>
          ) : (
            <>
              <Title className="before:bg-[#FFBC9A]" title={t('ticket.table1.title')} />
              <List<Ticket>
                canUpdate={false}
                canCreate={false}
                canDelete={false}
                totalPages={0}
                onPageChange={() => {}}
                currentPage={Number(/* search.page */) || 1}
                data={tickets?.Orders.data.filter((e) => e?.status === 'opened').filter(dateFilterFunction) || []}
                headers={[
                  {
                    title: t('ticket.table1.column1').toString(),
                    dataIndex: 'id',
                    key: 'id',
                    render: (value, { id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`#${id.slice(
                        id.length - 6,
                      )}`}</p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column2').toString(),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (_, { createdAt }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`${moment(
                        createdAt,
                      ).format('ddd, MMMM Do - h:mm a')}`}</p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column3').toString(),
                    dataIndex: 'waiter_id',
                    key: 'waiter_id',
                    render: (_, { waiter_id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        {waiter_id?.first_name || '-----'}
                      </p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column4').toString(),
                    dataIndex: 'tip_amount',
                    key: 'tip_amount',
                    render: (value, { tip_amount }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        {tip_amount || 0} $
                      </p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column5').toString(),
                    dataIndex: 'total_amount_payed',
                    key: 'total_amount_payed',
                    render: (_, { total_amount, total_amount_payed }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        {parseFloat(total_amount || '0') - parseFloat(total_amount_payed || '0')} $
                      </p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column6').toString(),
                    dataIndex: 'total_amount',
                    key: 'total_amount',
                    render: (value) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value} $</p>
                    ),
                  },
                  {
                    title: t('ticket.table1.column7').toString(),
                    dataIndex: 'id',
                    key: 'detail',
                    render: (_, order) => (
                      <button
                        onClick={() => {
                          setShow(true);
                          setCurrentOrder(order);

                          // history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none"
                      >
                        {t('ticket.table1.button')}
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Block>
        <Block className="p-4 rounded-lg !bg-white flex flex-col gap-4">
          {loading ? (
            <div className="min-h-[150px] grid place-content-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="flex justify-between">
                <Title className="before:bg-[#B1E5FE] font-PopinsMedium text-2xl" title={t('ticket.table2.title')} />
                <div className="flex">
                  {SEARCH_INPUT?.map((item) => {
                    return <Search key={item.placeholder} setsearch={setsearch} {...item} />;
                  })}
                  <div className="ml-8">
                    <DatePickerBtnWaiter
                      placeholder={t('ticket.filters.item2').toString()}
                      date={date}
                      setDate={setDate}
                      Icon={MdDateRange}
                    />
                  </div>
                  <div className="ml-8 w-full">
                    <DropDownBtnWaiter
                      selected={selected}
                      setSelected={setSelected}
                      data={[{ id: 'all', fullName: t('ticket.filters.item3').toString() }, ...filtred] || []}
                    />
                  </div>
                </div>
              </div>
              <List<Ticket>
                canUpdate={false}
                canCreate={false}
                canDelete={false}
                totalPages={0}
                onPageChange={() => {}}
                currentPage={Number(/* search.page */) || 1}
                data={
                  tickets?.Orders.data
                    .filter((e) => e?.status === 'CLOSED')
                    .filter(dateFilterFunction)
                    .filter(filterFunction) || []
                }
                headers={[
                  {
                    title: t('ticket.table2.column1').toString(),
                    dataIndex: 'id',
                    key: 'id',
                    render: (value, { id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`#${id.slice(
                        id.length - 6,
                      )}`}</p>
                    ),
                  },
                  {
                    title: t('ticket.table2.column2').toString(),
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: (_, { createdAt }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`${moment(
                        createdAt,
                      ).format('ddd, MMMM Do - h:mm a')}`}</p>
                    ),
                  },
                  {
                    title: t('ticket.table2.column3').toString(),
                    dataIndex: 'waiter_id',
                    key: 'waiter_id',
                    render: (_, { waiter_id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        {waiter_id?.first_name || '------'}
                      </p>
                    ),
                  },
                  {
                    title: t('ticket.table2.column4').toString(),
                    dataIndex: 'tip_amount',
                    key: 'tip_amount',
                    render: (value, { tip_amount }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{tip_amount || 0}</p>
                    ),
                  },

                  {
                    title: t('ticket.table2.column5').toString(),
                    dataIndex: 'total_amount',
                    key: 'total_amount',
                    render: (value) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                    ),
                  },
                  {
                    title: t('ticket.table2.column6').toString(),
                    dataIndex: 'id',
                    key: 'detail',
                    render: (_, order) => (
                      <button
                        onClick={() => {
                          setShow(true);
                          setCurrentOrder(order);
                          // console.log({ items_id });

                          // history.replace({ search: `waiterId=${id}` });
                        }} // eslint-disable-line
                        className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none"
                      >
                        {t('ticket.table2.button')}
                      </button>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Block>
        <div className="flex justify-end">
          <p>{t('ticket.contactMessage')}</p>
        </div>
      </div>
      {/* <Block>
        <Table
          TitleClassname="before:bg-[#B1E5FE]"
          search={search}
          title="Closed Tables"
          data={
            tickets?.Tables?.data
              ?.filter(dateFilterFunction)
              ?.filter(filterFunction)
              .map(formatFunction)
              .filter((e) => e?.Status === 'closed') || []
          }
        />
      </Block> */}
      <Modal
        onClose={() => {
          setShow(!show);
          history.replace({ search: `` });
        }}
        open={show}
      >
        <DetailLayout title="Order Items" Items={currentOrder} />
      </Modal>
    </div>
  );
}
