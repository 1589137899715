import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import DropDownBtn from 'components/ui/Buttons/DropDownBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Loading from 'components/ui/Loading';
import Modal from 'components/ui/Modal/Modal';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import Search from 'components/ui/Search/Search';
import { BsSearch } from 'react-icons/bs';
import { useFormik } from 'formik';
import { useContext, useRef, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BiDownload } from 'react-icons/bi';
import { CgFormatText } from 'react-icons/cg';
import { MdOutlineCreate, MdOutlinePlace, MdQrCode2 } from 'react-icons/md';
import { RiFileAddLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetPointSale } from 'requests/PointSale/request';
import { useCreateTable, useGetTable } from 'requests/Tables/request';
import { useGetWaiters } from 'requests/Users/request';
import * as Yup from 'yup';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import logoApp from '../../assets/logoApp.png';
import QRTemplateModalLayout from './QRTemplateModalLayout';

export default function QRcodes() {
  const { t } = useTranslation();
  const print = useRef(null);
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const { data: restaurant } = useGetPointSale({ variables: { id: user?.idPOS } });
  const { data: staff } = useGetWaiters({ variables: { idPOS: user?.idPOS }, fetchPolicy: 'no-cache' });
  const history = useHistory();
  const { data, loading: loadingGet, refetch } = useGetTable({ variables: { idPointSale: user?.idPOS || '' } });
  const [createTable, { error, loading }] = useCreateTable();
  const [QRCode, setQRCode] = useState('');
  const [search, setsearch] = useState('');
  const formik = useFormik({
    initialValues: {
      name: '',
      waiter: {
        value: '',
        label: '',
      },
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string().required('This field is required'),
      waiter: Yup.object({
        label: Yup.string().required('This field is required'),
        value: Yup.string().required('This field is required'),
      }),
    }),
    onSubmit: (values) => {
      if (user?.idPOS) {
        createTable({
          variables: {
            name: values.name,
            id_pointSale: user.idPOS,
            waiter_id: values.waiter.value,
            status: 'open',
            id_inPOS: user.idPOS,
          },
        }).then(() => {
          toast.success('Table created successfully');
          refetch();
          setShowAdd(false);
          formik.resetForm();
        });
      } else {
        toast.error("You don't have a POS");
      }
    },
  });

  const handleDownloadImage = async (name: string) => {
    const element = print.current;
    const canvas = await html2canvas(element as any);

    const d = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = d;
      link.download = `${name}.jpeg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(d);
    }
  };
  const handeDownloadAll = () => {
    data?.Tables.data.forEach(async ({ name, qr_code }) => {
      await setQRCode(qr_code);
      if (qr_code !== QRCode) {
        handleDownloadImage(name);
      }
    });
  };

  const filterFunction = (e: any) => {
    return e?.name?.includes(search);
  };

  const SEARCH_INPUT = [
    {
      placeholder: t('qrCodes.search'),
      Icon: BsSearch,
    },
  ];
  return (
    <div className="m-8 flex flex-col gap-4">
      <div className="flex justify-between">
        <p className="text-warmGray-800 text-2xl">{t('qrCodes.pageTitle')}</p>
        {SEARCH_INPUT?.map((item) => {
          return <Search key={item.placeholder} setsearch={setsearch} {...item} />;
        })}
      </div>
      <Block className="p-4 rounded-lg !bg-white flex flex-col gap-4">
        <div className="flex justify-between">
          <Title
            className="before:bg-[#FFBC9A] font-PopinsMedium text-2xl"
            title={`${data?.Tables?.data.filter(filterFunction).length || 0} ${
              data?.Tables?.data.filter(filterFunction).length && data?.Tables?.data.filter(filterFunction).length > 1
                ? t('qrCodes.section1.title')
                : t('qrCodes.section1.title')
            }`}
          />

          <div className="flex justify-self-stretch">
            <CustomBtn onClick={handeDownloadAll} Icon={BiDownload} text="Download All" />
            <CustomBtn onClick={() => setShowAdd(true)} Icon={RiFileAddLine} text="Add new table" />
          </div>
        </div>
        {loadingGet ? (
          <div className="min-h-[150px] grid place-content-center">
            <Loading />
          </div>
        ) : (
          <List<any>
            canUpdate={false}
            canCreate={false}
            canDelete={false}
            totalPages={0}
            onPageChange={() => {}}
            currentPage={Number(/* search.page */) || 1}
            data={data?.Tables?.data.filter(filterFunction) || []}
            headers={[
              {
                title: t('qrCodes.section1.table.column1').toString(),
                dataIndex: 'id',
                key: 'order',
                render: () => (
                  <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                    <button className="px-1 text-[25px] text-bold text-[black]">↓</button>
                    <button className="px-1 text-[25px] text-bold text-[black]">↑</button>
                  </p>
                ),
              },
              {
                title: t('qrCodes.section1.table.column2').toString(),
                dataIndex: 'name',
                key: 'tname',
                render: (value) => (
                  <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                ),
              },
              {
                title: t('qrCodes.section1.table.column3').toString(),
                dataIndex: 'id_pointSale',
                key: 'id_pointSale',
                render: (value) => (
                  <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value?.id}</p>
                ),
              },
              {
                title: t('qrCodes.section1.table.column4').toString(),
                dataIndex: 'id',
                key: 'id',
                render: (value) => (
                  <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                ),
              },
              {
                title: t('qrCodes.section1.table.column5').toString(),
                dataIndex: 'qr_code',
                key: 'QRCode',
                render: (value, { qr_code, name }) => (
                  <div className="flex gap-4 justify-center">
                    <button
                      onClick={() => {
                        setShow(true);
                        setQRCode(qr_code);
                      }} // eslint-disable-line
                      className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                    >
                      <AiOutlineEye />
                      {}
                    </button>
                    <button
                      // eslint-disable-line
                      className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                      onClick={async () => {
                        await setQRCode(qr_code);
                        handleDownloadImage(name);
                      }}
                    >
                      <BiDownload />
                    </button>
                  </div>
                ),
              },
            ]}
          />
        )}
      </Block>

      <Block className="!bg-white">
        <div className="flex justify-between ">
          <div className="flex flex-col gap-4">
            <div>
              <Title title="Card Template" className="before:bg-[#FFD88D] font-PopinsMedium text-2xl" />
              <p className="text-gray-500">
                {t('qrCodes.section2.description.item1')}
                <strong>{t('qrCodes.section2.description.item2')}</strong>
              </p>
            </div>
            <div>
              <CustomBtn text={t('qrCodes.section2.button').toString()} Icon={MdOutlineCreate} />
            </div>
          </div>
          <div className="flex flex-col w-[330px] bg-gray-200 border border-gray-400 rounded-xl p-6 gap-4">
            <div className="flex items-center gap-2 ">
              <img src={logoApp} alt="logo" className="w-9" />
            </div>
            <div className="flex gap-4">
              <div className="w-[100px] h-[100px] border border-gray-400 rounded-xl bg-white flex items-center justify-center">
                <MdQrCode2 className="text-[8rem]" />
              </div>
              <div className="w-[50%] flex flex-col justify-center ">
                <p className="font-PopinsMedium ">{t('qrCodes.section2.preview')}</p>
                <p className="text-sm font-semibold"> {restaurant?.getPointSale?.name}</p>
              </div>
            </div>
          </div>
        </div>
      </Block>
      <Modal
        className="!h-[608px] !w-[746px]"
        onClose={() => {
          setShow(!show);
          history.replace({ search: `` });
        }}
        open={show}
      >
        <QRTemplateModalLayout QRCode={QRCode} restaurantName={restaurant?.getPointSale.name} />
      </Modal>

      <Modal
        onClose={() => {
          setShowAdd(!showAdd);
          history.replace({ search: `` });
        }}
        open={showAdd}
      >
        <div>
          <Title title="Add new table" className="before:bg-[#FFBC9A] mb-4" />
          <form onSubmit={formik.handleSubmit}>
            <div className="my-4 w-[500px] flex flex-col gap-4">
              <div>
                <InputBtn
                  type="text"
                  Icon={CgFormatText}
                  placeholder="Enter the name of the table (table number)"
                  label="Table name"
                  className={`border-2 ${
                    formik.errors?.name && formik.touched?.name ? 'border-red-500' : 'border-gray-300'
                  }`}
                  onFocusClassName="border-black"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.errors?.name}
                  id="name"
                />
              </div>
              <DropDownBtn
                label="Affect Waiter"
                Icon={MdOutlinePlace}
                className="hover:border-2 hover:border-gray-400 bg-gray-200"
                onFocusClassName="!border-black border-2"
                placeholder="Table's Waiter"
                onChange={(el) => formik.setFieldValue('waiter', el)}
                options={staff?.getUsers
                  .filter((e) => e.role === 'waiter')
                  .map((e) => ({ label: `${e.first_name} ${e.last_name}`, value: e?.id }))}
                value={formik.values.waiter}
              />
            </div>
            <div className="flex justify-end relative ">
              <CustomBtn
                text="Cancel"
                onClick={() => {
                  setShowAdd(false);
                  formik.resetForm();
                }}
              />
              <CustomBtn
                loading={loading}
                text="Add"
                loadingColor="white"
                className="!bg-warmGray-800 hover:border-none !text-white"
                type="submit"
              />
              {error ? (
                <p className="bottom-0 left-0 absolute text-lg my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
                  {error?.message}
                </p>
              ) : null}
            </div>
          </form>
        </div>
      </Modal>
      {/*  this is for qr code download */}
      <div
        className="flex flex-col bg-gray-200 border border-gray-400 rounded-xl p-6 gap-4 w-[450px] hidden"
        ref={print}
      >
        <div className="flex p-2 justify-center items-center gap-2 ">
          <img src={logoApp} alt="logo" className="w-9" />
          <p className="text-lg">Quick Bill</p>
        </div>
        <div className="flex gap-4">
          <div className="w-1/3 p-4 border border-gray-400 rounded-xl bg-white">
            <img alt="" src={QRCode} />
          </div>
          <div className="w-full flex justify-center text-xl text-center items-center">
            <p className="font-PopinsMedium ">
              View the menu and pay the bill
              <br /> <span className="text-lg font-semibold"> {restaurant?.getPointSale.name}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
