import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboadringUploadBtn from 'components/ui/Buttons/OnboadringUploadBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import OnboardingSteps from 'components/ui/Pagination/OnboardingSteps';
import { useFormik } from 'formik';
import React from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsCreditCard2Back } from 'react-icons/bs';
import { CgBriefcase } from 'react-icons/cg';
import { FiCalendar, FiPhoneCall } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import * as Yup from 'yup';

interface Form {
  name: string;
  phone: string;
  job: string;
  birth: string;
  adress: string;
  adress_pdf: any;
  identity_card: string;
  identity_card_pdf: any;
}
interface Props {
  setNext: (obj: any) => void;
  setPageNumber: (x: number) => void;
  form: Form;
}
// eslint-disable-next-line
export default function OnboardingPage2Layout({ setNext, form, setPageNumber }: Props) {
  const formik = useFormik({
    initialValues: {
      name: form.name,
      phone: form.phone,
      job: form.job,
      birth: form.birth,
      adress: form.adress,
      adress_pdf: form.adress_pdf,
      identity_card: form.identity_card,
      identity_card_pdf: form.identity_card_pdf,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required('This field is required'),
      phone: Yup.string().required('This field is required'),
      job: Yup.string().required('This field is required'),
      birth: Yup.string().required('This field is required'),
      adress: Yup.string().required('This field is required'),
      adress_pdf: Yup.mixed().required('This field is required'),
      identity_card: Yup.string().required('This field is required'),
      identity_card_pdf: Yup.mixed().required('This field is required'),
    }),
    onSubmit: (values) => {
      setNext(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center mb-8">
        <OnboardingSteps setPageNumber={setPageNumber} pageNumber={0} title="Main owner info" />
        <div className="  grid grid-rows-4 grid-cols-2 2xl:w-[80%] w-[70%] gap-x-8 gap-y-4 mb-6 md:flex md:flex-col md:h-[40vh] md:overflow-y-scroll md:overflow-x-hidden ">
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.name && formik.touched?.name ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your name"
              type="text"
              label="Name"
              Icon={AiOutlineUser}
              onChange={formik.handleChange}
              value={formik.values.name}
              id="name"
              error={formik?.touched.name ? formik.errors?.name : ''}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.phone && formik.touched?.phone ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your phone number"
              type="phone"
              label="Phone number"
              Icon={FiPhoneCall}
              onChange={(el) => formik.setFieldValue('phone', el)}
              value={formik.values.phone}
              id="phone"
              error={formik?.touched.phone ? formik.errors?.phone : ''}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.birth && formik.touched?.birth ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your date of birth"
              type="date"
              label="Date Of Birth"
              Icon={FiCalendar}
              onChange={(e: string) => formik.setFieldValue('birth', e)}
              value={formik.values.birth}
              id="birth"
              error={formik?.touched.birth ? formik.errors?.birth : ''}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${formik.errors?.job && formik.touched?.job ? 'border-red-500' : 'border-gray-300'}`}
              onFocusClassName="border-black"
              placeholder="Enter your job title"
              type="text"
              label="Job title"
              Icon={CgBriefcase}
              onChange={formik.handleChange}
              value={formik.values.job}
              id="job"
              error={formik?.touched.job ? formik.errors?.job : ''}
            />
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.adress && formik.touched?.adress ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your address"
              type="text"
              label="Adress"
              Icon={IoLocationOutline}
              onChange={formik.handleChange}
              value={formik.values.adress}
              id="adress"
              error={formik?.touched.adress ? formik.errors?.adress : ''}
            />
          </div>
          <div>
            <OnboadringUploadBtn
              accept=".jpg,.pdf"
              placeholder="Upload of address document"
              text={
                formik.values.adress_pdf?.name !== undefined
                  ? formik.values.adress_pdf.name
                  : "The file must be JPG or PDF and don't exeed 5MO"
              }
              onChange={(event) => formik.setFieldValue(event.target.id, event.target.files[0])}
              id="adress_pdf"
              className={formik.errors?.adress_pdf && formik.touched?.adress_pdf ? 'border-red-500' : 'border-gray-400'}
            />
            {formik.errors?.adress_pdf && formik.touched?.adress_pdf ? (
              <p className="fixed text-xs my-1 font-PopinsMedium -translate-y-1/2 text-red-500">
                {formik.errors?.adress_pdf}
              </p>
            ) : null}
          </div>
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.identity_card && formik.touched?.identity_card ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your identity card number"
              type="number"
              label="Identity Card Number"
              Icon={BsCreditCard2Back}
              onChange={formik.handleChange}
              value={formik.values.identity_card}
              id="identity_card"
              error={formik?.touched.identity_card ? formik.errors?.identity_card : ''}
            />
          </div>
          <div>
            <OnboadringUploadBtn
              accept=".jpg,.pdf"
              placeholder="Upload your identity card number"
              text={
                formik.values.identity_card_pdf?.name !== undefined
                  ? formik.values.identity_card_pdf.name
                  : "The file must be JPG or PDF and don't exeed 5MO"
              }
              onChange={(event) => formik.setFieldValue(event.target.id, event.target.files[0])}
              id="identity_card_pdf"
              className={
                formik.errors?.identity_card_pdf && formik.touched?.identity_card_pdf
                  ? 'border-red-500'
                  : 'border-gray-400'
              }
            />
            {formik.errors?.identity_card_pdf && formik.touched?.identity_card_pdf ? (
              <p className="fixed text-xs my-1 font-PopinsMedium -translate-y-1/2  text-red-500">
                {formik.errors?.identity_card_pdf}
              </p>
            ) : null}
          </div>
        </div>
        <OnboardingButton type="submit" className="w-[30%] h-[40px]">
          Next
        </OnboardingButton>
      </div>
    </form>
  );
}
