import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import Loading from 'components/ui/Loading';
import MenuGuides from 'components/ui/MenusGuide/MenuGuides';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineFontSize } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import { FaArrowsAlt } from 'react-icons/fa';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineDocument } from 'react-icons/hi';
import { MdOutlineMargin } from 'react-icons/md';
import { RiFileAddLine } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeleteMenu, useGetMenus, useUpdateMenu } from 'requests/Menus/request';
import { Menu } from 'requests/Menus/types';
import { decodeUri } from 'utils/url';
import { useTranslation } from 'react-i18next';
import ModalConfirmation from './ModalConfirmation';
import ModalCreateMenu from './ModalCreateMenu';
import ModalUpdateMenu from './ModalUpdateMenu';

interface SwitchProps {
  label?: string;
  isVisible: boolean;
  onChange?: (v: boolean) => void;
}
export function Switcher({ isVisible, onChange, label }: SwitchProps) {
  const [toggle, setToggle] = useState(isVisible);
  const toggleClass = ' transform translate-x-[100%]';
  const toggleClassbg = 'bg-[#ea1601]';

  useEffect(() => {
    setToggle(isVisible);
  }, [isVisible]);

  return (
    //   Switch Container
    <div className="flex items-center gap-2">
      {label ? <p>{label}</p> : null}
      <div
        className={`transition ease-in-out duration-700 md:w-14 md:h-7 w-12 h-6 flex items-center  rounded-full p-1 cursor-pointer  ${
          toggle ? 'bg-[#64bc26]' : toggleClassbg
        }`}
        onClick={() => {
          if (onChange) onChange(!toggle);
          setToggle(!toggle);
        }}
      >
        <div
          className={`transition ease-in-out duration-700 bg-white md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform${
            toggle ? null : toggleClass
          }`}
        />
      </div>
    </div>
  );
}

export default function MenuGuide() {
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [MenuToUpdate, setMenuToUpdate] = useState('');
  const [updateModal, setupdateModal] = useState(false);
  const [preview] = useState(false);
  const [deleteConfiramtion, setdeleteConfiramtion] = useState(false);
  const { data, loading, refetch } = useGetMenus({ variables: { pointSale_id: user?.idPOS } });
  const [updateMenu] = useUpdateMenu({});
  const [deleteMenu, { loading: loadingDelete, called }] = useDeleteMenu({});
  const history = useHistory();
  const location = useLocation();

  const ORIGNAL_MENU = data?.Menus?.data.slice()?.sort((a, b) => a?.order - b?.order) || [];

  const handleOrderChange = async (id: string, id2: string) => {
    const orderById = (ID: string) => ORIGNAL_MENU.find((e) => e?.id === ID)?.order;
    if (id && id2) {
      await updateMenu({ variables: { id, order: orderById(id2) } });
      await updateMenu({ variables: { id: id2, order: orderById(id) } });
      refetch();
    }
  };

  const handleDelete = () => {
    const { MenuId: id } = decodeUri(location?.search);
    if (id) {
      deleteMenu({ variables: { id } }).then(({ data: response }) => {
        if (response?.removeMenu) {
          setdeleteConfiramtion(false);
          history.replace({ search: '' });
          refetch();
        }
      });
    } else {
      alert('Erreur Suppression'); // eslint-disable-line
      setdeleteConfiramtion(false);
    }
  };
  const { t } = useTranslation();
  const MENU_GUIDES = [
    {
      Icon: VscFilePdf,
      title: t('menus.section2.card1.title'),
      text: t('menus.section2.card1.text'),
      className: 'bg-[#B1E5FE]',
    },
    {
      Icon: MdOutlineMargin,
      title: t('menus.section2.card2.title'),
      text: t('menus.section2.card2.text'),
      className: 'bg-[#FFBC9A]',
    },
    {
      Icon: FaArrowsAlt,
      title: t('menus.section2.card3.title'),
      text: t('menus.section2.card3.text'),
      className: 'bg-[#CABDFF]',
    },
    {
      Icon: AiOutlineFontSize,
      title: t('menus.section2.card4.title'),
      text: t('menus.section2.card4.text'),
      className: 'bg-[#FFD88D]',
    },
    {
      Icon: HiOutlineDocument,
      title: t('menus.section2.card5.title'),
      text: t('menus.section2.card5.text'),
      className: 'bg-[#B1E5FE]',
    },
  ];

  return (
    <div className="m-8 flex flex-col gap-4">
      <>
        <p className="text-warmGray-800 text-[34px] font-PopinsMedium">{t('menus.pageTitle')}</p>
        <Block className="p-4 pt-4 rounded-lg !bg-white flex flex-col gap-4">
          {loading ? (
            <div className="min-h-[150px] grid place-content-center">
              <Loading />
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center">
                <Title className="before:bg-[#CABDFF] font-PopinsMedium text-2xl" title={t('menus.section1.title')} />
                <div>
                  <CustomBtn
                    onClick={() => setShow(true)}
                    Icon={RiFileAddLine}
                    text={t('menus.section1.button').toString()}
                    className="px-8"
                  />
                </div>
              </div>
              <List<Menu>
                canUpdate={false}
                canCreate={false}
                canDelete={false}
                totalPages={0}
                onPageChange={() => {}}
                currentPage={Number(/* search.page */) || 1}
                data={data?.Menus?.data?.slice().sort((a, b) => a?.order - b?.order) || []}
                headers={[
                  {
                    title: t('menus.section1.table.column1').toString(),
                    dataIndex: 'id',
                    key: 'order',
                    render: (_, { order, id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        <button
                          disabled={order === (data?.Menus?.data as any)?.length}
                          onClick={() => {
                            const NextID = ORIGNAL_MENU[ORIGNAL_MENU.findIndex((e) => e?.id === id) + 1].id;
                            // handleOrder(1, order);
                            handleOrderChange(id, NextID);
                          }}
                          className={`px-1 text-[25px] text-bold ${order === 1 ? 'animate-bounce' : ''} ${
                            order === (data?.Menus?.data as any)?.length ? 'disabled text-[#bbb8b8]' : 'text-[black]'
                          }`}
                        >
                          ↓
                        </button>
                        <button
                          disabled={order === 1}
                          className={`px-1 text-[25px] text-bold ${
                            order === (data?.Menus?.data as any)?.length ? 'animate-bounce' : ''
                          } ${order === 1 ? 'text-[#bbb8b8] ' : 'text-[black]'}`}
                          onClick={() => {
                            const BeforeID = ORIGNAL_MENU[ORIGNAL_MENU.findIndex((e) => e?.id === id) - 1]?.id;
                            // handleOrder(-1, order);
                            handleOrderChange(id, BeforeID);
                          }}
                        >
                          ↑
                        </button>
                      </p>
                    ),
                  },
                  {
                    title: t('menus.section1.table.column2').toString(),
                    dataIndex: 'description',
                    key: 'description',
                    render: (value, { order }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        {order} {value}
                      </p>
                    ),
                  },
                  {
                    title: t('menus.section1.table.column3').toString(),
                    dataIndex: 'category',
                    key: 'category',
                    render: (value) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                    ),
                  },
                  {
                    title: t('menus.section1.table.column4').toString(),
                    dataIndex: 'visible',
                    key: 'visibility',
                    render: (_, { visible, id }) => (
                      <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                        <Switcher
                          onChange={(status) =>
                            updateMenu({ variables: { id, visible: status } }).then(() => {
                              refetch();
                            })
                          }
                          isVisible={visible}
                        />
                      </p>
                    ),
                  },
                  {
                    title: t('menus.section1.table.column5').toString(),
                    dataIndex: 'id',
                    key: 'id',
                    render: (_, { id }) => (
                      <div className="flex gap-4">
                        <button
                          onClick={() => {
                            setupdateModal(true);
                            setMenuToUpdate(id);
                            history.replace({ search: `waiterId=${id}` });
                          }} // eslint-disable-line
                          className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold p-4 rounded-xl focus:outline-none"
                        >
                          <AiOutlineEye />
                          {}
                        </button>
                        <button
                          onClick={() => {
                            setdeleteConfiramtion(true);
                            history.replace({ search: `MenuId=${id}` });
                          }} // eslint-disable-line
                          className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-xl focus:outline-none"
                        >
                          <FiTrash2 />
                          {}
                        </button>
                      </div>
                    ),
                  },
                ]}
              />
            </>
          )}
        </Block>
      </>
      <Block className="!bg-white">
        {loading ? (
          <div className="min-h-[150px] grid place-content-center">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6">
              <Title title={t('menus.section2.title')} className="before:bg-[#FFD88D] font-PopinsMedium text-2xl" />
              <p className="text-gray-500">{t('menus.section2.description')}</p>

              <div className="flex gap-16">
                {MENU_GUIDES.map((items) => {
                  return <MenuGuides key={items.title} {...items} />;
                })}
              </div>
            </div>
          </>
        )}
      </Block>
      <ModalCreateMenu setShow={setShow} show={show} refetch={refetch} />
      <ModalUpdateMenu id={MenuToUpdate} setShow={setupdateModal} show={updateModal} />
      <ModalConfirmation
        onConfirm={handleDelete}
        setShow={setdeleteConfiramtion}
        show={deleteConfiramtion}
        loading={loadingDelete}
        called={called}
        title="Delete ?"
        text="Are you sure you want to delete this element ?"
      />
      {preview && (
        <div className="fixed !p-[10%]  bg-[#000000bf] flex justify-center items-center h-full w-full top-0 right-0 z-50">
          <CgClose
            // onClick={() => setopen(false)}
            className="absolute h-20 top-8 right-8 cursor-pointer hover:h-8"
          />
          <img
            src="https://source.unsplash.com/user/c_v_r"
            alt="dssd"
            className=" p-10 4xl:p-48 3xl:p-48 xl:p-48 lg:p-0  md:p-0  lg:w-full w-auto h-auto"
          />
        </div>
      )}
    </div>
  );
}
