import List from 'components/crud/List';
import Block from 'components/ui/Blocks/Block';
import DatePickerBtn from 'components/ui/Buttons/DatePickerBtn';
import Search from 'components/ui/Search/Search';
import Title from 'components/ui/Text/Title';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { Payment } from 'requests/types';
import { useTranslation } from 'react-i18next';
import UserContext from 'contexts/UserContext';
import RefundPopup from './RefundPopup';

const SEARCH_INPUT = [
  {
    placeholder: 'Search num table',
    Icon: BsSearch,
  },
];

export default function PaymentLayout() {
  const { user } = useContext(UserContext);
  const [search, setsearch] = useState('');
  const history = useHistory();
  const [payments, setPayments] = useState([]);
  const [date, setDate] = useState<Date | undefined>();
  const [show, setShow] = useState(false);
  if (search && show) {
    // sds
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payments/${user?.idPOS}`)
      .then((res) => res.json())
      .then(setPayments);
  }, []);
  const { t } = useTranslation();
  t('hellp');
  return (
    <div className="m-8">
      <div className="flex justify-between">
        <p className="text-warmGray-800 text-[34px] font-PopinsMedium">{t('payment.pageTitle')}</p>
        <div className="flex">
          {SEARCH_INPUT.map((item) => {
            return <Search key={item.placeholder} setsearch={setsearch} {...item} />;
          })}
          <div className="ml-8">
            <DatePickerBtn date={date} placeholder="Date de déb" className="!w-[216px]" setDate={setDate} />
          </div>
          <div className="ml-8">
            <DatePickerBtn date={date} placeholder="Date de fin" className="!w-[216px]" setDate={setDate} />
          </div>
        </div>
      </div>
      <Block className="mt-4 !bg-white">
        <Title className="before:bg-[#FFBC9A]" title={t('payment.table.title').toString()} />
        <List<Payment>
          canUpdate={false}
          canCreate={false}
          canDelete={false}
          totalPages={0}
          onPageChange={() => {}}
          currentPage={Number(/* search.page */) || 1}
          data={payments}
          headers={[
            {
              title: t('payment.table.column1').toString(),
              dataIndex: 'id_table',
              key: 'id_table',
              render: (value, { id_table }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`#${id_table.slice(
                  id_table.length - 6,
                )}`}</p>
              ),
            },
            {
              title: t('payment.table.column2').toString(),
              dataIndex: 'id_ticket',
              key: 'id_ticket',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
              ),
            },
            {
              title: t('payment.table.column3').toString(),
              dataIndex: 'method',
              key: 'method',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
              ),
            },
            {
              title: t('payment.table.column4').toString(),
              dataIndex: 'createdAt',
              key: 'date',
              render: (_, { createdAt }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{`${moment(
                  createdAt,
                ).format('ddd, MMMM Do - h:mm a')}`}</p>
              ),
            },
            {
              title: t('payment.table.column5').toString(),
              dataIndex: 'time',
              key: 'time',
              render: (_, { time }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{time}</p>
              ),
            },
            {
              title: t('payment.table.column6').toString(),
              dataIndex: 'tip',
              key: 'tips',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value / 100} AED</p>
              ),
            },
            {
              title: t('payment.table.column7').toString(),
              dataIndex: 'total_payed',
              key: 'subtotal',
              render: (value) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value / 100} AED</p>
              ),
            },
            {
              title: t('payment.table.column8').toString(),
              dataIndex: 'total_payed',
              key: 'total',
              render: (_, { tip, total_payed }) => (
                <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                  {(Number(tip) + Number(total_payed)) / 100} AED
                </p>
              ),
            },
            {
              title: t('payment.table.column9').toString(),
              dataIndex: 'status',
              key: 'status',
              render: (value) => (
                <div
                  className={`max-w-[fit-content] overflow-ellipsis whitespace-nowrap overflow-hidden flex justify-center px-4 py-2 rounded-2xl ${
                    // eslint-disable-next-line no-nested-ternary
                    value === 'PAID'
                      ? 'bg-[#64BC2620] text-[#64BC26]'
                      : value === 'FAILED'
                      ? 'bg-[#EA160120] text-[#EA1601]'
                      : 'bg-[#FAD20220] text-[#FAD202]'
                  }`}
                >
                  {
                    // eslint-disable-next-line no-nested-ternary
                    value === 'PAID'
                      ? t('payment.table.status.paid')
                      : value === 'FAILED'
                      ? t('payment.table.status.failed')
                      : t('payment.table.status.refunded')
                  }
                </div>
              ),
            },
            {
              title: t('payment.table.column10').toString(),
              dataIndex: 'status',
              key: 'refunded',
              render: (_, { id }) => (
                <button
                  onClick={() => {
                    setShow(true);
                    history.replace({ search: `waiterId=${id}` });
                  }} // eslint-disable-line
                  className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold text-xl p-4 rounded-xl focus:outline-none"
                >
                  <AiOutlineReload />
                </button>
              ),
            },
          ]}
        />
      </Block>
      <RefundPopup show={show} closeFunction={() => setShow(false)} />
    </div>
  );
}
