import svg from 'assets/svg/notFound.svg';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="h-[100%] flex items-center justify-center">
      <div className="flex flex-col items-center gap-2">
        <img src={svg} alt="" className="w-[60%]" />
        <p className="font-semibold text-2xl">Oops! Page not found !</p>
        <p className="text-center">
          This page doesn't exist or was removed! <br />
          We suggest you back to overview
        </p>
        <Link to={{ pathname: '/overview' }}>
          <CustomBtn text="Back to overview" className="!bg-gray-800 !text-white hover:border-none" />
        </Link>
      </div>
    </div>
  );
}
