import { AiFillFlag } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import { BsFillPersonFill, BsFillTelephoneFill } from 'react-icons/bs';
import { GrCurrency, GrMapLocation } from 'react-icons/gr';
import { HiOutlineMail } from 'react-icons/hi';
import ImagePreview from '../Preview/ImagePreview';

interface Props {
  logo?: string;
  name?: string;
  email?: string;
  tel?: string | number;
  hierarchy?: string;
  address?: string;
  currency?: string;
  city?: string;
  team?: Boolean;
}

export default function Card({ logo, name, email, tel, hierarchy, address, currency, city, team }: Props) {
  return (
    <div>
      {team ? (
        <div className="bg-gray-100 flex justify-start rounded-2xl p-4 w-1/3 gap-6">
          <div className="w-1/4 flex justify-center">
            <BsFillPersonFill className="text-[5rem] border rounded-2xl border-gray-700" />
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-semibold">{name}</h1>
            <div className="flex items-center gap-4">
              <HiOutlineMail />
              {email}
            </div>
            <div className="flex items-center gap-4">
              <BsFillTelephoneFill />
              {tel}
            </div>
            <div className="flex items-center gap-4">
              <AiFillFlag />
              {hierarchy}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100 flex justify-start rounded-2xl p-4 w-1/3 gap-6">
          <div className="w-[5rem] flex justify-center">
            <ImagePreview src={logo} className="!w-[5rem] border rounded-2xl border-gray-700" alt="" />
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-semibold">{name}</h1>
            <div className="flex items-center gap-4">
              <BiMap />
              {address}
            </div>
            <div className="flex items-center gap-4">
              <BsFillTelephoneFill />
              {tel}
            </div>
            <div className="flex items-center gap-4">
              <GrMapLocation />
              {city}
            </div>
            <div className="flex items-center gap-4">
              <GrCurrency />
              {currency}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Card.defaultProps = {
  name: '',
  email: '',
  tel: undefined,
  hierarchy: '',
  address: '',
  currency: '',
  city: '',
  team: undefined,
};
