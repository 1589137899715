import { createContext } from "react";

export interface NotificationInterface {
    title: string;
    message: string;
    time: string;
    type: 'success' | 'error';
    isRead: boolean;
    payload: any;
}
interface Notifications {
    notifications: NotificationInterface[] | null;
    setNotifications: (notifications: Notifications) => void;
}
interface Notification {
    notification: NotificationInterface | null;
    setNotification: (notification: Notification) => void;
}
export const NotificationsContext = createContext<Notifications>
    ({ notifications: null, setNotifications: () => { } });

export const NotificationContext = createContext<Notification>({
    notification: null,
    setNotification: () => { },
});

