/* eslint-disable react/require-default-props */
import Pagination, { PaginationProps } from 'components/data-display/Pagination';
import Table, { TableItem, TableProps } from 'components/data-display/Table';
import useTableControls from 'hooks/useTableControls';
import { Link, useHistory } from 'react-router-dom';

interface ListProps<T extends TableItem> extends TableProps<T>, PaginationProps {
  canDelete?: boolean;
  canUpdate?: boolean;
  canCreate?: boolean;
  loading?: boolean;
  onRowClick?: (row: T, index: number) => void;
}

const List = <T extends TableItem>({
  data,
  headers: headersProp,
  totalPages,
  currentPage,
  onPageChange,
  onRowClick,
  canDelete,
  canCreate,
  canUpdate,
  loading,
}: ListProps<T>) => {
  const router = useHistory();
  const headers = useTableControls(headersProp, data, {
    onEdit: canUpdate ? ({ id }: any) => router.push(`update/${id}`) : undefined,
    onDelete: canDelete ? ({ id }: any) => router.push(`delete/${id}`) : undefined,
    onMultipleDelete: canDelete ? (ids: any) => router.push(`delete/${ids.join(',')}`) : undefined,
  });
  return (
    <div className="p-0 flex-1 flex flex-col items-center">
      {canCreate && (
        <Link
          className="self-end my-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none "
          to="add"
        >
          Add
        </Link>
      )}

      <Table onRowClick={onRowClick} className="flex-1" data={data} headers={headers} />

      {!loading && totalPages !== 0 && (
        <Pagination className="pt-4" totalPages={totalPages} currentPage={currentPage} onPageChange={onPageChange} />
      )}
    </div>
  );
};

export default List;
