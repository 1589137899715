import React, { RefObject, useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
  Icon: IconType;
  label: string;
  placeholder: string;
  type: string;
  value?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  min?: number;
  max?: number;
  className?: string | undefined;
  onFocusClassName?: string | undefined;
  id?: string | undefined;
  name?: string;
  error?: string;
  disabled?: boolean;
}

export default function InputBtn({
  Icon,
  label,
  placeholder,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  min,
  max,
  className,
  onFocusClassName,
  id,
  name,
  error,
  disabled = false,
}: Props) {
  const [focus, setFocus] = useState(false);
  const [pswType, setPswType] = useState('password');
  const [val, setVal] = useState<DayValue>(
    value !== '' && value !== undefined && type === 'date'
      ? {
          year: parseInt(value?.split('/')[2], 10),
          month: parseInt(value?.split('/')[1], 10),
          day: parseInt(value?.split('/')[0], 10),
        }
      : null,
  );
  const blur = (e: any) => {
    if (onBlur !== undefined) {
      onBlur(e);
    }
    setFocus(false);
  };
  const focusInput = (e: any) => {
    if (onFocus !== undefined) {
      onFocus(e);
    }
    setFocus(true);
  };
  useEffect(() => {
    if (onChange !== undefined) {
      onChange(val ? `${val.day}/${val.month}/${val.year}` : '');
    }
  }, [val]);

  interface REF {
    ref: RefObject<HTMLElement>;
  }

  const phone =
    type === 'phone' ? (
      <>
        <PhoneInput
          inputClass="2xl:-translate-y-2 -translate-y-4 !bg-[transparent] !border-none font-medium font-PopinsMedium focus-visible:outline-none flex-1 text-sm !h-[48px] 2xl:!h-[24px]  w-full "
          buttonClass="!border-none !bg-[transparent] h-[48px] 2xl:!h-[24px] 2xl:!-mt-3 !-mt-5"
          containerClass="h-[48px] 2xl:!h-[24px]"
          dropdownClass="2xl:!-mt-3 !-mt-5 font-medium font-PopinsMedium"
          placeholder={placeholder}
          value={value}
          onChange={(el) => (onChange ? onChange(el) : undefined)}
          onBlur={blur}
          onFocus={focusInput}
        />
        <p
          style={{ bottom: '-25px' }}
          className="!-bottom-[25px] left-0   absolute text-xs my-2 font-PopinsMedium  text-red-500"
        >
          {error}
        </p>
      </>
    ) : (
      <>
        <input
          className="bg-[transparent]  2xl:!-mt-5 !-mt-8 font-medium font-PopinsMedium focus-visible:outline-none flex-1 w-full text-sm"
          placeholder={placeholder}
          type={type === 'password' ? pswType : type}
          value={value}
          onChange={onChange}
          onBlur={blur}
          onFocus={focusInput}
          min={min}
          max={max}
          id={id}
          name={name}
          disabled={disabled}
          autoComplete="off"
          aria-autocomplete="none"
        />
        {error ? (
          <p
            style={{ bottom: '-25px' }}
            className="!-bottom-[25px] left-0   absolute text-xs my-2 font-PopinsMedium  text-red-500"
          >
            {error}
          </p>
        ) : null}
      </>
    );
  return (
    <div className={type === 'date' ? 'w-full my-0' : 'w-full'}>
      <div
        className={`h-[80px] flex 2xl:py-0 py-2 px-6 2xl:h-[56px] rounded-xl ${className} ${
          focus && onFocusClassName
        } relative`}
      >
        <div className="flex items-center mr-6 text-gray-400">
          <Icon onClick={() => (type === 'date' ? document.getElementById('dateInput')?.focus() : null)} />
        </div>
        <div className="flex flex-col w-full 2xl:h-[56px] h-[80px]">
          <label className="font-PopinsMedium text-xs py-2" htmlFor={label}>
            {label}
          </label>
          {type === 'date' ? (
            <DatePicker
              value={val}
              onChange={setVal}
              colorPrimary="#CABDFF"
              calendarClassName="text-[9px] font-PopinsMedium 2xl:text-[7px]"
              renderInput={({ ref }: REF) => (
                <div className="relative">
                  <input
                    name={name}
                    readOnly
                    className={` h-[48px] bg-[transparent] 2xl:-translate-y-2 -translate-y-4 2xl:h-[23px] font-medium font-PopinsMedium focus-visible:outline-none flex-1 text-sm w-full ${
                      value !== '' ? '' : 'text-gray-400'
                    }`}
                    placeholder={placeholder}
                    value={value !== '' ? value : ''}
                    onChange={onChange}
                    onBlur={blur}
                    onFocus={focusInput}
                    id="dateInput"
                    ref={ref as RefObject<HTMLInputElement>}
                  />
                  {error ? (
                    <p
                      style={{ bottom: '-25px' }}
                      className="!-bottom-[17px] -left-[58px]   absolute text-xs my-2 font-PopinsMedium  text-red-500"
                    >
                      {error}
                    </p>
                  ) : null}
                </div>
              )}
            />
          ) : (
            phone
          )}
        </div>
        {type === 'password' ? (
          <div className="flex items-center cursor-pointer absolute top-7 2xl:top-4 right-6">
            {pswType === 'password' ? (
              <AiOutlineEyeInvisible onClick={() => setPswType('text')} />
            ) : (
              <AiOutlineEye onClick={() => setPswType('password')} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputBtn.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  min: 0,
  max: 99999999999999999,
  className: undefined,
  onFocusClassName: undefined,
  id: undefined,
  disabled: false,
};
