import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Title from 'components/ui/Text/Title';
import UserContext from 'contexts/UserContext';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { BiUser } from 'react-icons/bi';
import { MdOutlineMail, MdOutlinePhoneInTalk } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useAddStaff } from 'requests/Users/request';
import * as yup from 'yup';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function AddUserModal({ role, setShow, refetch }: any) {
  const [addStaff, { error, loading }] = useAddStaff();
  const { user } = useContext(UserContext);
  const loginSchema = yup.object({
    email: yup
      .string()
      .email('Your E-mail should be valide (example@domain.com)')
      .required('Veuillez renseigner votre adresse e-mail'),
    first_name: yup.string().required('Please enter your firstname'),
    last_name: yup.string().required('Please enter your lastname'),
    phone_number: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    role: yup.string().required('Please enter your role'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      role,
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      addStaff({ variables: { ...values, idPOS: user?.idPOS } }).then(({ data }) => {
        if (data?.addStaff?.id) {
          refetch();
          setShow(false);
          toast.success(`${data?.addStaff?.last_name} added`, { autoClose: 2000 });
        }
      });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-[20px]">
        <Title title="Add a new user" className="before:bg-[#FFBC9A]" />
        <div className="flex items-stretch justify-center gap-4 ">
          <InputBtn
            name="first_name"
            className=" border border-gray-300 "
            label="Name"
            placeholder="Name"
            type="text"
            Icon={BiUser}
            id="first_name"
            onChange={formik.handleChange}
            error={formik?.errors?.first_name}
            value={formik?.values?.first_name}
          />
          <InputBtn
            name="last_name"
            className=" border border-gray-300 "
            label="Lastname"
            placeholder="Lastname"
            type="text"
            id="last_name"
            Icon={BiUser}
            onChange={formik.handleChange}
            error={formik?.errors?.last_name}
            value={formik?.values?.last_name}
          />
        </div>

        <InputBtn
          name="email"
          id="email"
          className=" border border-gray-300 "
          label="Mail address"
          placeholder="Mail address"
          type="text"
          Icon={MdOutlineMail}
          onChange={formik.handleChange}
          error={formik?.errors?.email}
          value={formik?.values?.email}
        />
        <InputBtn
          name="phone_number"
          id="phone_number"
          className=" border border-gray-300"
          label="Phone number"
          placeholder="Your Phone number"
          type="phone"
          Icon={MdOutlinePhoneInTalk}
          onChange={(el) => (el.length ? formik.setFieldValue('phone_number', el) : null)}
          error={formik?.errors?.phone_number}
          value={formik?.values?.phone_number}
        />
        <InputBtn
          id="role"
          className=" border border-gray-300"
          label="Role"
          placeholder="Your Role"
          type="text"
          Icon={MdOutlinePhoneInTalk}
          value={role}
        />
        <div className="flex justify-end relative ">
          <CustomBtn type="submit" onClick={() => setShow(false)} text="Cancel" />
          <CustomBtn
            loading={loading}
            text="Add"
            loadingColor="white"
            className="!bg-warmGray-800 !text-white hover:border-none !text-white"
          />
          {error ? (
            <p className="bottom-0 left-0 absolute text-lg my-2 font-PopinsMedium -translate-y-1/2 text-red-500">
              {error?.message}
            </p>
          ) : null}
        </div>
      </div>
    </form>
  );
}
