import { MutationHookOptions, MutationTuple } from '@apollo/client';
import UserContext from 'contexts/UserContext';
import localforage from 'localforage';
import { useContext, useEffect } from 'react';
import { setAuthorizationBearer } from 'requests/client';
import { Token, User } from 'requests/types';
import { graphQLResult } from 'utils/graphql';

function useAuth<Arguments, Result extends { [key: string]: { user: User; token: Token } }>(
  fn: (options?: MutationHookOptions<Result, Arguments>) => MutationTuple<Result, Arguments>,
  stayConnected: boolean = true,
): MutationTuple<Result, Arguments> {
  const { setUser } = useContext(UserContext);
  const [call, state] = fn();

  function persistUser(data: { user: User; token: Token }) {
    const result = { ...data };
    if (!stayConnected) {
      // @ts-ignore
      delete result.token.refreshToken;
    }
    localforage.setItem('auth', JSON.stringify(result));
  }

  useEffect(() => {
    if (state.data) {
      const result = graphQLResult(state.data);
      setAuthorizationBearer(result.token.accessToken);
      persistUser(result);
      setUser({ ...result.user, idPOS: (result as any)?.idPOS, point_sale_logo: (result as any)?.point_sale_logo });
    }
    // eslint-disable-next-line
  }, [state.data]);

  return [call, { ...state, error: state.error }];
}

export default useAuth;
