import { MutationHookOptions, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocalMutation, useLocalQuery } from 'hooks/apollo';
import { Table } from 'requests/types';

export const useCreateTable = (options: MutationHookOptions<{ Table: Table }> = {}) => {
  const CREATE_TABLE = gql`
    mutation createTable($id_pointSale: ID!, $waiter_id: ID, $status: String, $id_inPOS: String, $name: String) {
      createTable(
        id_pointSale: $id_pointSale
        waiter_id: $waiter_id
        status: $status
        id_inPOS: $id_inPOS
        name: $name
      ) {
        id
        qr_code
      }
    }
  `;
  return useLocalMutation(CREATE_TABLE, options);
};
export const useGetTable = (options: QueryHookOptions<{ Tables: { data: Table[] } }, { idPointSale: string }> = {}) => {
  const GET_TABLES = gql`
    query Tables($idPointSale: ID) {
      Tables(idPointSale: $idPointSale) {
        data {
          id
          id_pointSale {
            id
          }
          waiter_id {
            id
          }
          id_inPOS
          name
          status
          qr_code
        }
      }
    }
  `;

  return useLocalQuery(GET_TABLES, options);
};
