/* eslint-disable @typescript-eslint/naming-convention */
import { FetchResult } from '@apollo/client';
import localforage from 'localforage';
import moment from 'moment';
import { LoginData, RefreshArguments, refreshMutation } from 'requests/auth';
import { client, setAuthorizationBearer } from 'requests/client';
import { Token, User } from 'requests/types';

export default async function startup(): Promise<User | null> {
  try {
    const authString = await localforage.getItem<string | null>('auth');
    let nextData:
      | FetchResult<
          {
            refresh: LoginData;
          },
          Record<string, any>,
          Record<string, any>
        >
      | undefined;
    let accessToken = null;
    if (!authString) {
      return null;
    }
    const {
      user,
      token,
      idPOS,
      point_sale_logo,
    }: { user: User; token: Token; idPOS: string; point_sale_logo: string } = JSON.parse(authString);
    if (token.refreshToken) {
      nextData = await client.mutate<{ refresh: LoginData; idPOS: string }, RefreshArguments>({
        mutation: refreshMutation,
        variables: { refreshToken: token.refreshToken },
      });
      if (nextData.data) {
        accessToken = nextData.data.refresh.token.accessToken;
        const localToken = {
          ...nextData.data.refresh,
          idPOS: nextData.data.refresh.idPOS,
          point_sale_logo: nextData.data.refresh.point_sale_logo,
        };
        localforage.setItem('auth', JSON.stringify(localToken));
      }
    } else if (moment(token.expiresIn, 'x').diff(moment(), 'minutes') > 0) {
      accessToken = token.accessToken;
    }

    if (accessToken) {
      setAuthorizationBearer(accessToken);
    }
    return { ...user, idPOS, point_sale_logo };
  } catch (e) {
    return null;
  }
}
