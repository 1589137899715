import i18n from 'i18next';
import EN from './en.json';
import FR from './fr.json';

i18n.init({
    lng: 'en',
    resources: {
        en: {
            translation: EN,
        },
        fr: {
            translation: FR,
        },
    },
});

export default i18n;