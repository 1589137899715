import { useState } from 'react';
import { IconType } from 'react-icons';
import OutsideClickHandler from 'react-outside-click-handler';

interface Props {
  Icon: IconType;
  label: string;
  placeholder: string;
  width?: string;
  className?: string;
  value?: { value: string; label: string };
  options?: { value: string; label: string }[];
  onChange?: (el: { value: string; label: string }) => void;
  onFocusClassName?: string;
}

export default function DropDownBtn({
  Icon,
  label,
  placeholder,
  width,
  className,
  value,
  options,
  onChange,
  onFocusClassName,
}: Props) {
  const [open, setOpen] = useState(false);
  const change = (el: { value: string; label: string }) => {
    if (onChange) {
      onChange(el);
    }
    setOpen(false);
  };
  if (width) {
    //
  }
  return (
    <div className="w-full">
      <div
        className={`h-[80px] flex 2xl:py-0 py-2 px-6 2xl:h-[56px] rounded-xl ${className} ${
          open ? onFocusClassName : ''
        }`}
      >
        <div className="flex items-center mr-6">
          <Icon />
        </div>
        <div className="flex flex-col w-full 2xl:h-[56px] h-[80px]">
          <label className="font-PopinsMedium text-xs py-2" htmlFor={label}>
            {label}
          </label>
          <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <div className="2xl:!-mt-3 !-mt-4">
              <button
                onClick={() => setOpen(!open)}
                className={`h-[48px] bg-[transparent] 2xl:h-[23px] font-medium font-PopinsMedium focus-visible:outline-none text-sm w-full text-left ${
                  value?.value === '' ? 'text-gray-400' : ''
                }`}
              >
                {value?.label === '' ? placeholder : value?.label}
              </button>
              {open && (
                <div
                  id="dropdown"
                  className="relative z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
                >
                  <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                    {options?.map((el) => (
                      <li>
                        <div
                          className={`${
                            value?.value === el?.value && value?.value !== '' ? 'bg-gray-100' : ''
                          } block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer`}
                          onClick={() => change(el)}
                          key={`${el.value}`}
                        >
                          {el.label}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
}

DropDownBtn.defaultProps = {
  width: '100%',
  className: '',
  value: '',
  options: [''],
  onFocusClassName: '',
};
