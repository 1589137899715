import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import { AiOutlineUser } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo2.png';

export default function ContactUs() {
  return (
    <div>
      <div className="z-10 absolute w-[100%] h-[100vh] bg-white flex flex-col items-center py-8">
        <div className="mb-2">
          <img src={logo} alt="logo" className="w-[6rem]" />
        </div>
        <div className="border-gray-300 border-2 bg-gray-100 drop-shadow-md z-10  w-[75%] h-[75%] p-3 rounded-xl overflow-y-auto flex flex-col justify-center">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-2xl">Contact us</h1>
            <p className="text-gray-600">Any question or remarks ? Just write us a message.</p>
          </div>
          <div className="flex gap-8 mt-6">
            <div className="flex flex-col gap-4 w-full">
              <InputBtn
                label="Name"
                placeholder="User name"
                Icon={AiOutlineUser}
                type="text"
                className="border border-gray-300"
              />
              <InputBtn
                label="Mail address"
                placeholder="Mail address"
                Icon={FiMail}
                type="text"
                className="border border-gray-300"
              />
              <InputBtn
                label="Phone number"
                placeholder="Phone number"
                Icon={BsTelephone}
                type="text"
                className="border border-gray-300"
              />
            </div>
            <div className="w-full">
              <textarea
                placeholder="Enter your message"
                className="w-full h-full border border-gray-300 rounded-2xl bg-[transparent] p-4 outline-none
                        resize-none"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <CustomBtn text="Send message" className="!bg-gray-800 !text-white hover:border-none" />
          </div>
        </div>
        <div className="flex flex-col items-center mt-6">
          <p className="font-bold">Take me back to</p>
          <Link to={{ pathname: '/login' }}>Sign in</Link>
        </div>
      </div>
    </div>
  );
}
