import Block from 'components/ui/Blocks/Block';
import BlockGray from 'components/ui/Blocks/BlockGray';
import CustomBtn from 'components/ui/Buttons/CustomBtn';
import InputBtn from 'components/ui/Buttons/InputBtn';
import Title from 'components/ui/Text/Title';
import RatingList from 'containers/Rating/RatingList';
import { AiOutlineStar, AiOutlineUser } from 'react-icons/ai';
import { BiLineChart } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';

const CONTACT_LIST = [
  {
    Icon: BiLineChart,
    title: 'Average rating',
    text: '4/5',
    className: 'bg-blueLight',
  },
  {
    Icon: AiOutlineStar,
    title: 'Number of rating',
    text: '9',
    className: 'bg-purple',
  },
  {
    Icon: AiOutlineStar,
    title: 'Number of rating',
    text: '9',
    className: 'bg-orange',
  },
];

export default function OurContact() {
  return (
    <div className="m-8 flex flex-col gap-8">
      <Block>
        <Title title="Our Contact" className="before:bg-pink" />
        <div>
          <BlockGray className="flex justify-between">
            {CONTACT_LIST.map((items) => {
              return <RatingList {...items} />;
            })}
          </BlockGray>
        </div>
      </Block>
      <Block>
        <Title title="Contact us" className="before:bg-purple" />
        <div className="flex gap-8 mt-6">
          <div className="flex flex-col gap-4 w-full">
            <InputBtn
              label="Name"
              placeholder="User name"
              Icon={AiOutlineUser}
              type="text"
              className="border border-gray-300"
            />
            <InputBtn
              label="Mail address"
              placeholder="Mail address"
              Icon={FiMail}
              type="text"
              className="border border-gray-300"
            />
            <InputBtn
              label="Phone number"
              placeholder="Phone number"
              Icon={BsTelephone}
              type="text"
              className="border border-gray-300"
            />
          </div>
          <div className="w-full">
            <textarea
              placeholder="Enter your message"
              className="w-full h-full border border-gray-300 rounded-2xl bg-[transparent] p-4 outline-none
                        resize-none"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <CustomBtn text="Send message" className="!bg-gray-800 !text-white hover:border-none" />
        </div>
      </Block>
    </div>
  );
}
