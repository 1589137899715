import SideBar from 'components/layout/Sidebar/SideBar';
import React from 'react';
import Navbar from './navbar';

interface Props {
  children: JSX.Element;
}

export default function Layout({ children }: Props) {
  return (
    <div className="flex flex-col flex-1 ">
      <Navbar />
      <div className="flex flex-1">
        <SideBar />
        <div
          style={{ height: 'calc(100vh - 104px)' }}
          className="bg-[#F0F0F0] w-full h-[calc(100vh-104px)]  overflow-y-scroll flex-1   "
        >
          {children}
        </div>
      </div>
    </div>
  );
}
