import { MutationHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useLocalMutation } from 'hooks/apollo';
import { Token, User } from './types';

export const loginMutation = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        email
        role
        first_name
        last_name
        first_cnx
        user_photo
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      idPOS
      point_sale_logo
    }
  }
`;

export interface LoginArguments {
  email: string;
  password: string;
}
export interface LoginData {
  user: User;
  token: Token;
  idPOS?: string;
  point_sale_logo?: string;
}

export const useLogin = (options: MutationHookOptions<{ login: LoginData }, LoginArguments> = {}) =>
  useLocalMutation(loginMutation, options);

export const refreshMutation = gql`
  mutation Refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      user {
        id
        email
        role
        first_name
        last_name
        first_cnx
        phone_number
        identity_card
        job_title
        address
        user_photo
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      idPOS
      point_sale_logo
    }
  }
`;

export interface RefreshArguments {
  refreshToken: string;
}

export const useRefresh = (options: MutationHookOptions<LoginData, RefreshArguments> = {}) =>
  useLocalMutation<LoginData, RefreshArguments>(refreshMutation, options);

export const resetMutation = gql`
  mutation Reset($password: String!, $token: String!) {
    reset(password: $password, token: $token) {
      user {
        id
        email
        role
        first_name
        last_name
        first_cnx
        user_photo
      }
      token {
        tokenType
        accessToken
        refreshToken
        expiresIn
      }
      idPOS
      point_sale_logo
    }
  }
`;

export interface ResetArguments {
  password: string;
  token: string;
}
export interface ResetData {
  user: User;
  token: Token;
}

export const useReset = (options: MutationHookOptions<{ reset: ResetData }, ResetArguments> = {}) =>
  useLocalMutation(resetMutation, options);
export const logoutMutation = gql`
  mutation logout {
    logout
  }
`;
export interface LogoutData {
  logout: string;
}
export const useLogout = (options: MutationHookOptions<{ logout: LogoutData }, ResetArguments> = {}) => {
  const res: any = useLocalMutation(logoutMutation, options);

  return res;
};

export const updateUserMutation = gql`
  mutation updateUser($id: ID!, $fullName: String, $email: String, $address: String, $telephone: String) {
    updateUser(id: $id, fullName: $fullName, email: $email, address: $address, telephone: $telephone) {
      id
      role
    }
  }
`;

export const useUpdateUser = (options: MutationHookOptions<{ updateUser: User }, {}> = {}) =>
  useLocalMutation(updateUserMutation, options);

export const resetPasswordMutation = gql`
  mutation resetPassword($email: String, $token: String) {
    resetPassword(email: $email, token: $token) {
      id
    }
  }
`;

export const useResetPassword = (
  options: MutationHookOptions<{ resetPassword: { id: string } }, { email?: string; token?: string }> = {},
) => useLocalMutation(resetPasswordMutation, options);

export const verifTokenMutation = gql`
  mutation verifToken($token: String) {
    verifToken(token: $token) {
      id
      first_name
      last_name
    }
  }
`;

export const useVerifToken = (
  options: MutationHookOptions<
    { verifToken: { id: string; first_name: string; last_name: string } },
    { token: string }
  > = {},
) => useLocalMutation(verifTokenMutation, options);

export const verifCodeMutation = gql`
  mutation verifCodeReset($code: String, $email: String) {
    verifCodeReset(code: $code, email: $email) {
      code
      idUser
    }
  }
`;

export const useVerifCode = (
  options: MutationHookOptions<
    { verifCodeReset: { code: string; idUser: string } },
    { email: string; code: string }
  > = {},
) => useLocalMutation(verifCodeMutation, options);
