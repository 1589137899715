import List from 'components/crud/List';
import Table from 'components/ui/Table/Table';
import moment from 'moment';
import { Ticket } from 'requests/Tickets/types';
/* import { useLocation } from 'react-router-dom';
import { useGetWaiters } from 'requests/Users/request';
import { decodeUri } from 'utils/url'; */

interface Props {
  Items: Ticket | undefined;
  title: string;
}

export default function DetailLayout({ Items, title }: Props) {
  // const { data: tickets } = useGetTickets({ variables: {} });
  return (
    <div>
      <Table
        className="flex-col"
        data={[{}]}
        title={
          <div className="flex-col">
            <h1 className="text-3xl">{title}</h1>
            <p>Date :{moment(Items?.createdAt).format('DD/MM/YYYY')}</p>
            <p>Time :{moment(Items?.createdAt).format('h:mm')}</p>
            {/* <p>Ticket {Items?.id}, 13:47 12/08/2022</p> */}
          </div>
        }
        externalComponent={
          <>
            <List<any>
              canUpdate={false}
              canCreate={false}
              canDelete={false}
              totalPages={0}
              onPageChange={() => {}}
              currentPage={Number(/* search.page */) || 1}
              data={Items?.items_id || []}
              headers={[
                {
                  title: 'Name',
                  dataIndex: 'label',
                  key: 'createdAt',
                  render: (_, { label }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{label}</p>
                  ),
                },
                {
                  title: 'Price',
                  dataIndex: 'price',
                  key: 'tip_amount',
                  render: (value) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">{value}</p>
                  ),
                },
                {
                  title: 'Quantity',
                  dataIndex: 'quantity',
                  key: 'total_amount_payed',
                  render: (_, { quantity }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                      {parseFloat(quantity)}
                    </p>
                  ),
                },
                {
                  title: 'Total',
                  dataIndex: 'image',
                  key: 'total_amount',
                  render: (value, { quantity, price }) => (
                    <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                      {Number(quantity) * Number(price)} $
                    </p>
                  ),
                },
                {
                  title: 'Item',
                  dataIndex: 'image',
                  key: 'total_amount',
                  render: (value, { image }) => (
                    <img className="rounded-2xl h-[100px]" src={image} alt="photitem" width={100} height={100} />
                  ),
                },
                // {
                //   title: 'Payment status',
                //   dataIndex: '',
                //   key: 'waiter_id',
                //   render: (_, { waiter_id }) => (
                //     <p className="max-w-xs overflow-ellipsis whitespace-nowrap overflow-hidden ">
                //       {waiter_id?.first_name}
                //     </p>
                //   ),
                // },
              ]}
            />
          </>
        }
      />
    </div>
  );
}
