import i18n from 'translation';

const LANUGAGES = [
  { lang: 'fr', icon: '🇫🇷' },
  { lang: 'en', icon: '🇬🇧' },
];
export default function Lanugage() {
  return (
    <div>
      <select
        className="bg-[#8080801e] border-0  rounded-r-lg h-10 !text-[grey] text-xl pr-2"
        name=""
        id=""
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
          localStorage.setItem('preferred_language', e.target.value);
        }}
      >
        {LANUGAGES.map(({ lang, icon }) => (
          <option value={lang} key={lang}>
            {icon}
          </option>
        ))}
      </select>
    </div>
  );
}
