import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'utils/classNames';

export type ButtonProps = {} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FunctionComponent<ButtonProps> = ({ children, className, ...rest }) => {
  return (
    <button
      className={classNames('text-white font-bold py-2 px-4 rounded', className || 'bg-blue-500 hover:bg-blue-700')}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
