import React, { useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { HiOutlineUpload } from 'react-icons/hi';

interface Props {
  accept?: string | undefined;
  capture?: boolean | undefined;
  required?: boolean | undefined;

  multiple?: boolean | undefined;
  placeholder?: string;
  text?: string;
  id?: string | undefined;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  className?: string;
  LeftIcon?: IconType;
  disabled?: boolean;
}

export default function OnboadringUploadBtn({
  accept,
  capture,
  multiple,
  placeholder,
  text,
  id,
  onChange,
  onBlur,
  className,
  required,
  LeftIcon = HiOutlineUpload,
  disabled,
}: Props) {
  const input = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState('');
  const clickInput = () => input?.current?.click();
  const change = (event: any) => {
    if (onChange !== undefined) {
      onChange(event);
    }
    const arry = event.target.value.split('\\');
    setVal(arry[arry.length - 1]);
  };
  return (
    <div className="my-0 w-full">
      <div
        onClick={disabled ? undefined : clickInput}
        className={`border-dashed border-2 rounded-xl flex h-[66px] 2xl:h-[43px] items-center px-[1.4rem] cursor-pointer ${className}`}
      >
        <LeftIcon />
        <p className="ml-2 font-PopinsMedium text-xs font-semibold">{placeholder}</p>
      </div>
      <p className="font-PopinsMedium text-[0.5rem] mt-[2px] text-gray-400">{val !== '' ? val : text}</p>
      <input
        disabled={disabled}
        required={required}
        className="hidden"
        accept={accept}
        capture={capture}
        multiple={multiple}
        onChange={(event) => change(event)}
        onBlur={onBlur}
        type="file"
        id={id}
        ref={input}
      />
    </div>
  );
}
OnboadringUploadBtn.defaultProps = {
  accept: undefined,
  capture: undefined,
  multiple: undefined,
  placeholder: '',
  text: '',
  id: undefined,
  onChange: () => {},
  onBlur: () => {},
  className: '',
};
