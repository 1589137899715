import Block from 'components/ui/Blocks/Block';
import { NotificationsContext } from 'contexts/NotificationContext';
import { useContext } from 'react';

export default function ValetOverview() {
  const { notifications } = useContext(NotificationsContext);

  return (
    <div className="m-8 w-[80vw] flex gap-8">
      <Block className="flex-[0.8] w-[400px] ">
        <h1 className="font-PopinsMedium text-gray-600 text-[36px]">
          Car orders<span className="text-[12px]">(all orders)</span>
        </h1>
        {notifications?.length === 0 ? (
          <span className="text-[12px]">(all orders)</span>
        ) : (
          <div className="flex flex-col gap-6">
            {notifications?.length !== 0 &&
              notifications?.map((notification: any) => (
                <div className="flex items-center gap-[20px] border-[4px] border-gray-400 rounded p-10 shadow-md bg-gray-100">
                  <img src={notification.logo} alt="" className="w-[100px] rounded-full" />
                  <p>{notification?.title}</p>
                  <p>{notification?.message}</p>
                  <div className="flex flex-col border-l-2 border-r-2 border-gray-400 px-10">
                    <h5 className="text-[20px] font-bold ">tickets </h5>
                    <ul>
                      {notification?.tickets?.length !== 0 &&
                        notification?.tickets.map((ticket: any) => (
                          <li>
                            <img
                              alt={`Barcoded value ${ticket}`}
                              src={`http://bwipjs-api.metafloor.com/?bcid=code128&text=${ticket}&includetext`}
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                  <p>wants car in {notification?.timeToCar} minutes </p>
                </div>
              ))}
          </div>
        )}
      </Block>
    </div>
  );
}
