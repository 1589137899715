import InputBtn from 'components/ui/Buttons/InputBtn';
import OnboardingButton from 'components/ui/Buttons/OnboardingButton';
import { useFormik } from 'formik';
import React from 'react';
import { MdOutlineMail } from 'react-icons/md';
import { useResetPassword } from 'requests/auth';
import * as yup from 'yup';

interface Props {
  nextPage?: (mail: string) => void;
}

export default function ForgetPasswordmail({ nextPage }: Props) {
  const [resetPassword] = useResetPassword();

  const loginSchema = yup.object({
    email: yup
      .string()
      .email('Votre adresse e-mail doit être valide (exemple@domain.com)')
      .required('Veuillez renseigner votre adresse e-mail'),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: ({ email }) => {
      if (email) {
        resetPassword({ variables: { email } }).then(({ data: response }) => {
          if (!response?.resetPassword?.id) formik?.setErrors({ email: "Aucun utilisateur avec cet email'" });
          else if (nextPage) {
            nextPage(email);
          }
        });
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col items-center">
        <h1 className="mb-4 text-center font-PopinsMedium text-3xl">Forget Password</h1>
        <p className="mb-8 font-PopinsMedium text-gray-500 relative text-center">
          Please enter your email address to recieve a verfication code.
        </p>
        <div className=" flex flex-col gap-8">
          <div>
            <InputBtn
              className={`border-2 ${
                formik.errors?.email && formik.touched?.email ? 'border-red-500' : 'border-gray-300'
              }`}
              onFocusClassName="border-black"
              placeholder="Enter your email adress"
              type="email"
              label="email Adress"
              Icon={MdOutlineMail}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              id="email"
            />
          </div>
          <OnboardingButton type="submit" className="w-[100%] h-[40px]">
            Send
          </OnboardingButton>
        </div>
      </div>
    </form>
  );
}
