import SideBarMainBtn from 'components/ui/Buttons/SideBarMainBtn';
import UserContext from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineAntDesign, AiOutlineStar } from 'react-icons/ai';
import { BsMenuUp } from 'react-icons/bs';
import { FaExpandArrowsAlt, FaPercentage } from 'react-icons/fa';
import { FiArrowLeftCircle, FiArrowRightCircle, FiSettings, FiUsers } from 'react-icons/fi';
import { HiOutlineTicket } from 'react-icons/hi';
import { MdOutlineQrCode2, MdPayment } from 'react-icons/md';
import { SiGooglemaps } from 'react-icons/si';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './sideBar.module.scss';

export default function SideBar() {
  const { t } = useTranslation();
  const MAIN_BUTTONS = (role: string) =>
    [
      {
        text: t('sidebar.section1.item1'),
        Icon: HiOutlineTicket,
        roles: ['owner', 'manager', 'waiter'],
        link: '/ticket',
      },
      {
        text: t('sidebar.section1.item2'),
        Icon: MdPayment,
        roles: ['owner', 'manager', 'waiter'],
        link: '/payment',
      },
      {
        text: t('sidebar.section1.item3'),
        Icon: FaPercentage,
        roles: ['owner', 'manager'],
        link: '/accounting',
      },
      {
        text: t('sidebar.section1.item4'),
        Icon: AiOutlineStar,
        roles: ['owner', 'manager'],
        link: '/rating',
      },
    ].filter(({ roles }) => roles.includes(role));

  const SETTINGS_BUTTONS = (role: string) =>
    [
      {
        text: <span>{t('sidebar.section2.item1')}</span>,
        Icon: BsMenuUp,
        roles: ['owner', 'manager'],
        link: '/restaurant-details',
      },
      {
        text: t('sidebar.section2.item2'),
        Icon: FiUsers,
        roles: ['owner', 'manager'],
        link: '/my-staff',
      },
      {
        text: t('sidebar.section2.item3'),
        Icon: SiGooglemaps,
        roles: ['owner'],
        link: '/POS',
      },
      {
        text: t('sidebar.section2.item4'),
        Icon: FaExpandArrowsAlt,
        roles: ['owner', 'manager', 'waiter'],
        link: '/menu',
      },
      {
        text: t('sidebar.section2.item5'),
        Icon: AiOutlineAntDesign,
        roles: ['owner', 'manager', 'waiter'],
        link: '/app-design',
      },
      {
        text: t('sidebar.section2.item6'),
        Icon: MdOutlineQrCode2,
        roles: ['owner', 'manager', 'waiter'],
        link: '/qr-codes',
      },
    ].filter(({ roles }) => roles.includes(role));
  const [tabletSize, settabletSize] = useState(false);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const handelREsize = ({ target }: any) => {
    const windowWidth = target?.innerWidth;
    if (windowWidth <= 767) settabletSize(true);
    else settabletSize(false);
  };
  useEffect(() => {
    window.addEventListener('resize', handelREsize);
    return () => {
      window.removeEventListener('resize', handelREsize);
    };
  }, []);
  return (
    <div
      className={`${classes.container} relative flex flex-col duration-500 ${
        tabletSize ? 'w-[4rem] justify-between' : 'w-[309px]'
      } border-r-[1px]  border-grey-200 h-auto `}
    >
      <div className="flex flex-col gap-2 px-3 py-[23px]">
        {MAIN_BUTTONS(user?.role || 'waiter').map((btnProps) => (
          <SideBarMainBtn key={btnProps.text} tabletSize={tabletSize} {...btnProps} />
        ))}
      </div>
      <div className={`flex items-center border-grey-200 ${tabletSize && 'justify-center'}`}>
        <FiSettings className={`  text-[20px] ${!tabletSize && 'hidden'} `} />
        <h2 className={`text-3xl pl-14 text-warmGray-400 ${tabletSize && 'hidden'} `}>{t('sidebar.settings')}</h2>
      </div>
      <div className="flex flex-col gap-2 px-3 py-[22px]">
        {SETTINGS_BUTTONS(user?.role || 'waiter').map((btnProps) => (
          <SideBarMainBtn key={btnProps.link} tabletSize={tabletSize} {...btnProps} />
        ))}
      </div>

      <div
        className={`border-t-[1px] flex items-center justify-center border-b-[1px] h-14 py-2 border-grey-200 ${
          tabletSize && 'justify-center'
        }`}
      >
        <div
          className="flex items-center justify-center cursor-pointer hover:bg-[gray-200] hover:text-warmGray-400 hover:scale-90 hover:duration-300 transition-all"
          onClick={() => {
            history.push('/profile');
          }}
        >
          <div className="rounded-full w-12 h-12 overflow-hidden border-2 ">
            <img className=" rounded-full" src={user?.user_photo} alt="" />
          </div>
          {!tabletSize && (
            <div className="flex flex-col text-md p-5 justify-center ">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => settabletSize(!tabletSize)}
        className="absolute z-10 bottom-4 right-[-14px] transition-all hover:duration-400 scale-75 hover:scale-100 cursor-pointer"
      >
        {!tabletSize ? (
          <FiArrowLeftCircle className="text-3xl opacity-50 hover:opacity-100 cursor-pointer" />
        ) : (
          <FiArrowRightCircle className="text-3xl opacity-50 hover:opacity-100 cursor-pointer" />
        )}
      </div>
    </div>
  );
}
